import React, { useState } from 'react';
import { User, Mail, Phone, Globe, Calendar, Users, Smartphone, Languages, DollarSign } from 'lucide-react';
import { AffiliateAppData, SubdomainAppData } from '../../utils/service';
import { COLORS, currencies } from '../../utils/constants';

interface AffiliateSettingManagementProps {
  app: SubdomainAppData;
  affiliateData: AffiliateAppData;
  updateAffiliateAppData: (appId: string, affiliateAppData: AffiliateAppData) => Promise<void>;
}

const AffiliateSettingManagement: React.FC<AffiliateSettingManagementProps> = ({ app, affiliateData, updateAffiliateAppData }) => {
  const [currency, setCurrency] = useState(affiliateData.currency || 'USD');

  const handleCurrencyChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newCurrency = event.target.value;
    setCurrency(newCurrency);
    affiliateData.currency = newCurrency;
    try {
      await updateAffiliateAppData(app.app_id, affiliateData);
    } catch (error) {
      console.error('Error updating currency:', error);
    }
  };

  return (
    <div className="container mt-4">
      <div className="card shadow-sm" style={{ borderColor: '#e0e0e0', borderRadius: '10px' }}>
        <div className="card-body p-4">          
          <div className="row g-3">
            <div className="col-md-6">
              <div className="d-flex align-items-center mb-3">
                <User size={24} className="me-3" color={app.brand_color || COLORS.primary} />
                <div>
                  <small className="text-muted d-block">Name</small>
                  <strong>{`${affiliateData.first_name} ${affiliateData.last_name}`}</strong>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center mb-3">
                <Mail size={24} className="me-3" color={app.brand_color || COLORS.primary} />
                <div>
                  <small className="text-muted d-block">Email</small>
                  <strong>{affiliateData.email}</strong>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center mb-3">
                <Phone size={24} className="me-3" color={app.brand_color || COLORS.primary} />
                <div>
                  <small className="text-muted d-block">Phone</small>
                  <strong>{affiliateData.phone || 'N/A'}</strong>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center mb-3">
                <Globe size={24} className="me-3" color={app.brand_color || COLORS.primary} />
                <div>
                  <small className="text-muted d-block">Country</small>
                  <strong>{affiliateData.country || 'N/A'}</strong>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center mb-3">
                <Calendar size={24} className="me-3" color={app.brand_color || COLORS.primary} />
                <div>
                  <small className="text-muted d-block">Birthdate</small>
                  <strong>{affiliateData.birthdate || 'N/A'}</strong>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center mb-3">
                <Users size={24} className="me-3" color={app.brand_color || COLORS.primary} />
                <div>
                  <small className="text-muted d-block">Gender</small>
                  <strong>{affiliateData.gender || 'N/A'}</strong>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center mb-3">
                <Smartphone size={24} className="me-3" color={app.brand_color || COLORS.primary} />
                <div>
                  <small className="text-muted d-block">Device Type</small>
                  <strong>{affiliateData.device_type || 'N/A'}</strong>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center mb-3">
                <Languages size={24} className="me-3" color={app.brand_color || COLORS.primary} />
                <div>
                  <small className="text-muted d-block">Languages</small>
                  <strong>{affiliateData.languages || 'N/A'}</strong>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center mb-3">
                <DollarSign size={24} className="me-3" color={app.brand_color || COLORS.primary} />
                <div className="flex-grow-1">
                  <small className="text-muted d-block">Currency</small>
                  <select 
                    className="form-select" 
                    value={currency} 
                    onChange={handleCurrencyChange}
                    aria-label="Currency select"
                  >
                    {currencies.map((curr) => (
                      <option key={curr.code} value={curr.code}>
                        {curr.name} ({curr.code})
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AffiliateSettingManagement;
