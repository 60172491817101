import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { supabase } from "../../utils/supabaseClient";

const AuthHandler: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const hashParams = new URLSearchParams(location.hash.slice(1));
    const accessToken = hashParams.get('access_token');

    if (accessToken) {
      const setSession = async () => {
        const { data, error } = await supabase.auth.setSession({
          access_token: accessToken,
          refresh_token: '',
        });

        if (error) {
          console.error('Error setting session:', error);
          navigate('/login');
        } else if (data.session) {
          // Get the intended destination from localStorage
          const intendedDestination = localStorage.getItem('intendedDestination') || '/dashboard/home';          
          // Clear the stored destination
          localStorage.removeItem('intendedDestination');
          // Navigate to the intended destination
          navigate(intendedDestination);
        }
      };

      setSession();
    }
  }, [location, navigate]);

  return null;
}

export default AuthHandler;
