import React, { createContext, useContext, useState, useEffect } from 'react';
import { App, Campaign, ConversionSumByDateObj } from '../../utils/service';
import ApiService from '../../utils/service';
import { useAuth } from '../../contexts/AuthContext';

interface AppDataContextType {
  app: App | undefined;
  appRevenue: number | undefined;
  appAffiliates: number | undefined;
  appReferrals: number | undefined;
  appARR: number | undefined;
  campaigns: Campaign[];
  isLoading: boolean;
  createUpdateApp: (newApp: any, appId?: string) => Promise<void>;
  createCampaign: (newCampaign: any) => Promise<void>;
  refreshCampaigns: () => Promise<void>;
  refreshApps: () => Promise<void>;
  token?: string;
}

const AppDataContext = createContext<AppDataContextType | undefined>(undefined);

export const AppDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [app, setApp] = useState<App | undefined>(undefined);
  const [appRevenue, setAppRevnue] = useState<number | undefined>(undefined); 
  const [appAffiliates, setAppAffiliates] = useState<number | undefined>(undefined); 
  const [appReferrals, setAppReferrals] = useState<number | undefined>(undefined); 
  const [appARR, setAppARR] = useState<number | undefined>(undefined); 
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState<string | undefined>(undefined);
  const { session } = useAuth();
  const apiService = new ApiService();

  useEffect(() => {
    const fetchAppData = async () => {
      if (session) {
        setToken(session.access_token);
      }
    };

    fetchAppData();
  }, [session]);

  useEffect(() => {
    const fetchAppData = async () => {
      if (token) {
        try {
          setIsLoading(true);
          const appsList = await apiService.getApps(token);
          if (appsList.length > 0) {
            const updatedApp = appsList[0];
            setApp(updatedApp);
            // campaigns
            const updatedCampaigns = await apiService.getCampaigns(updatedApp.app_id, token);
            if (updatedCampaigns && updatedCampaigns.length > 0) {
              setCampaigns(updatedCampaigns)
            }
            // stats
            setStats(updatedApp.app_id, token);
          }
        } catch (error) {
          console.error('Error fetching apps:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchAppData();
  }, [token]);

  const setStats = (appId: string, token: string) => {
    apiService.getAppCounts(appId, "revenue", token).then(resp => {
      setAppRevnue(resp.num);
    })
    apiService.getAppCounts(appId, "referrals", token).then(resp => {
      setAppReferrals(resp.num);
    })
    apiService.getAppCounts(appId, "affiliates", token).then(resp => {
      setAppAffiliates(resp.num);
    })
    apiService.getAppCounts(appId, "arr", token).then(resp => {
      setAppARR(resp.num);
    })
  }

  const createUpdateApp = async (appInput: any, appId?: string) => {
    if (session) {
      try {
        if (appId) {
          await apiService.updateApp(appId, appInput, session.access_token);
        } else {
          await apiService.createApp(appInput, session.access_token);
        }
        const updatedApps = await apiService.getApps(session.access_token);
        if (updatedApps.length > 0) {
          const updatedApp = updatedApps[0];
          setApp(updatedApp);
          const updatedCampaigns = await apiService.getCampaigns(updatedApp.app_id, session.access_token);
          if (updatedCampaigns && updatedCampaigns.length > 0) {
            setCampaigns(updatedCampaigns)
          }
        }
      } catch (error) {
        throw error
      }
    }
  };

  const createCampaign = async (campaignInput: any) => {
    if (session && app) {
      try {
        await apiService.createCampaign(app.app_id, campaignInput, session.access_token);
        refreshCampaigns();
      } catch (error) {
        console.error('Error creating campaign:', error);
      }
    }
  };

  const refreshCampaigns = async () => {
    if (session && app) {
      try {
        const updatedCampaigns = await apiService.getCampaigns(app.app_id, session.access_token);
          if (updatedCampaigns && updatedCampaigns.length > 0) {
            setCampaigns(updatedCampaigns)
          }
      } catch (error) {
        console.error('Error refreshing campaigns:', error);
      }
    }
  };

  const refreshApps = async () => {
    if (session) {
      try {
        const appsList = await apiService.getApps(session.access_token);
        if (appsList.length > 0) {
          const updatedApp = appsList[0];
          setApp(updatedApp);
        }
      } catch (error) {
        console.error('Error refreshing apps:', error);
      }
    }
  };

  return (
    <AppDataContext.Provider value={{ 
      app, appRevenue, appAffiliates, appReferrals, appARR,
      campaigns, isLoading, createUpdateApp, createCampaign, 
      refreshCampaigns, refreshApps,
      token 
      }}>
      {children}
    </AppDataContext.Provider>
  );
};

export const useAppData = () => {
  const context = useContext(AppDataContext);
  if (context === undefined) {
    throw new Error('useAppData must be used within an AppDataProvider');
  }
  return context;
};
