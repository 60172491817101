import { User } from '@supabase/supabase-js';
import React, { useState } from 'react';
import { Modal, Form, Button, Alert } from 'react-bootstrap';
import { NavigateFunction } from 'react-router-dom';

interface LoginModalProps {
  showLoginModal: boolean;
  setShowLoginModal: (show: boolean) => void;
  signIn: (email: string) => Promise<{ error: Error | null }>;
  verifyOtp: (email: string, token: string) => Promise<{ error: Error | null; data: { user: User | null } | null }>;
  affiliateAppData: any;
  navigate: NavigateFunction;
  STYLES: Record<string, React.CSSProperties>;
  loginEmail?: string;
}

const LoginModal: React.FC<LoginModalProps> = ({
  showLoginModal,
  setShowLoginModal,
  signIn,
  verifyOtp,
  affiliateAppData,
  navigate,
  STYLES,
  loginEmail,
}) => {
  const [localEmail, setLocalEmail] = useState(loginEmail || '');
  const [localCode, setLocalCode] = useState('');
  const [localLoading, setLocalLoading] = useState(false);
  const [localMessage, setLocalMessage] = useState('');
  const [localShowCodeInput, setLocalShowCodeInput] = useState(false);

  const handleSendCode = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLocalLoading(true);
    setLocalMessage('');

    try {
      const { error } = await signIn(localEmail || loginEmail || "");

      if (error) {
        setLocalMessage(error.message);
      } else {
        setLocalMessage('Check your email for the login code!');
        setLocalShowCodeInput(true);
      }
    } catch (error) {
      setLocalMessage('An error occurred. Please try again.');
    } finally {
      setLocalLoading(false);
    }
  };

  const handleVerifyCode = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLocalLoading(true);
    setLocalMessage('');

    try {
      const { error, data } = await verifyOtp(localEmail || loginEmail || "", localCode);

      if (error) {
        setLocalMessage(error.message);
      } else if (data?.user) {
        setLocalMessage('Login successful!');
        if (!affiliateAppData) {
          setShowLoginModal(false);
        } else {
          navigate('/dashboard/home');
        }
      }
    } catch (error) {
      setLocalMessage('An error occurred. Please try again.');
    } finally {
      setLocalLoading(false);
    }
  };

  return (
    <Modal show={showLoginModal} onHide={() => setShowLoginModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Log in to Your Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!localShowCodeInput ? (
          <Form onSubmit={handleSendCode}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                value={loginEmail !== undefined ? loginEmail : localEmail}
                onChange={(e) => setLocalEmail(e.target.value)}
                required
                style={STYLES.formControl}
                disabled={loginEmail !== undefined}
              />
            </Form.Group>
            <Button className="w-100 login-button" style={STYLES.brandButton} type="submit" disabled={localLoading}>
              {localLoading ? 'Sending...' : 'Send Login Code'}
            </Button>
          </Form>
        ) : (
          <Form onSubmit={handleVerifyCode}>
            <Form.Group className="mb-3" controlId="formBasicCode">
              <Form.Label>Enter the code sent to your email</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter code"
                value={localCode}
                onChange={(e) => setLocalCode(e.target.value)}
                required
                style={STYLES.formControl}
              />
            </Form.Group>
            <Button className="w-100 login-button" style={STYLES.brandButton} type="submit" disabled={localLoading}>
              {localLoading ? 'Verifying...' : 'Verify Code'}
            </Button>
          </Form>
        )}
        {localMessage && <Alert variant={localMessage.includes('successful') ? 'success' : 'info'} className="mt-3">{localMessage}</Alert>}
      </Modal.Body>
    </Modal>
  );
};

export default LoginModal;