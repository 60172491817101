import { AffiliateAppData, App } from "./service";

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const formatCurrencyForApp = (app: App, amount: number | null) => {
  if (amount === null) {
    return null
  }
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: app.currency }).format((app.currency_exchange_rate || 1) * amount);
};

export const formatCurrency = (affiliateAppData: AffiliateAppData, amount: number) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: affiliateAppData.currency || 'USD' }).format((affiliateAppData.currency_exchange_rate || 1) * amount);
};
