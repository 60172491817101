import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const AffiliatePrivateRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { session } = useAuth();
  const location = useLocation();
  localStorage.setItem('intendedDestination', location.pathname + location.search);
  return session ? <>{children}</> : <Navigate to="/" />;
};

export default AffiliatePrivateRoute;
