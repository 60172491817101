import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ArrowLeft, Copy, Check, DollarSign, Users, Download, TrendingUp, User, Mail, Calendar, Package, Circle, ChevronLeft, ChevronRight } from 'lucide-react';
import ApiService, { Affiliate, App, Campaign, ConversionUser, PaginationData } from '../../utils/service';
import { getCampaignName } from '../../utils/campaign';
import { COLORS } from '../../utils/constants';
import { capitalizeFirstLetter, formatCurrencyForApp } from '../../utils/strings';

interface AffiliateDetailsProp {
  app: App;
  token: string | undefined;
  campaigns: Campaign[];
}

interface AffiliateCounts {
  users: number | null;
  revenue: number | null;
  arr: number | null;
  referrals: number | null;
  commission: number | null;
  unpaid_commission: number | null;
  pending_commission: number | null;
}

const apiService = new ApiService();

const AffiliateDetails: React.FC<AffiliateDetailsProp> = ({ app, token, campaigns }) => {
  const appId = app.app_id
  const { affiliate_id } = useParams<{ affiliate_id: string }>();
  const [affiliateData, setAffiliateData] = useState<Affiliate | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [copySuccess, setCopySuccess] = useState(false);
  const [users, setUsers] = useState<ConversionUser[]>([]);
  const [pagination, setPagination] = useState<PaginationData>({
    current_page: 1,
    page_size: 10,
    total_count: 0,
    total_pages: 0,
    has_next_page: false,
    has_prev_page: false,
  });
  const [counts, setCounts] = useState<AffiliateCounts>({
    users: null,
    revenue: null,
    arr: null,
    referrals: null,
    commission: null,
    unpaid_commission: null,
    pending_commission: null,
  });

  useEffect(() => {
    const fetchAffiliateData = async () => {
      setLoading(true);
      try {
        const data = await apiService.getAffiliateDetails(appId, affiliate_id || "", token || "");
        setAffiliateData(data);
        loadUsers(pagination.current_page, pagination.page_size);
        fetchAffiliateCounts();
      } catch (error) {
        console.error('Failed to fetch affiliate details:', error);
        setError('Failed to load affiliate details. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchAffiliateData();
  }, [affiliate_id, appId, token]);

  const fetchAffiliateCounts = async () => {
    const countTypes = ['users', 'revenue', 'arr', 'referrals', 'commission', 'unpaid_commission', 'pending_commission'];
    const newCounts = await Promise.all(
      countTypes.map(async (countType) => {
        try {
          const response = await apiService.getAffiliateCounts(appId, affiliate_id || "", countType, token || "");
          return { [countType]: response.num };
        } catch (error) {
          console.error(`Failed to fetch ${countType} count:`, error);
          return { [countType]: null };
        }
      })
    );

    setCounts(Object.assign({}, ...newCounts));
  };

  const loadUsers = async (page: number, pageSize: number) => {
    setLoading(true);
    try {
      const response = await apiService.getReferrals(appId, page, pageSize, token || "", undefined, affiliate_id);
      setUsers(response.conversion_users);
      setPagination(response.pagination);
    } catch (error) {
      console.error('Failed to fetch referrals:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (newPage: number) => {
    loadUsers(newPage, pagination.page_size);
  };

  const handleCopyCode = async () => {
    if (affiliateData?.code) {
      try {
        await navigator.clipboard.writeText(affiliateData.code);
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 500);
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
    }
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="alert alert-danger" role="alert">
        {error}
      </div>
    );
  }

  if (!affiliateData) {
    return <div className="alert alert-info" role="alert">No data available</div>;
  }

  const InfoItem: React.FC<{ label: string; value: string }> = ({ label, value }) => (
    <div className="mb-3">
      <h5 className="mb-0">{value}</h5>
      <small className="text-muted">{label}</small>
    </div>
  );

  const CommissionItem: React.FC<{ label: string; value: string | null }> = ({ label, value }) => (
    <div className="col-md-6 mb-3">
      <h4 className="mb-0">{value !== null ? value : 'Loading...'}</h4>
      <small className="text-muted">{label}</small>
    </div>
  );

  const MetricCard: React.FC<{ title: string; value: string | number | null; icon: React.ReactNode; isMonetary: boolean }> = ({ title, value, icon, isMonetary }) => (
    <div className="card h-100">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-start">
          <h6 className="card-title">{title}</h6>
          {icon}
        </div>
        <p className="card-text fs-4 fw-bold mt-2">
          {value !== null ? (
            value
          ) : (
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          )}
        </p>
      </div>
    </div>
  );

  const formatDate = (dateString: string): string => {
    return new Date(dateString).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
  };

  const getUserIdentifier = (user: ConversionUser): string => {
    return user.email ? user.email.split('@')[0] + '...' : user.external_user_id;
  };

  const getPlanType = (user: ConversionUser): string => {
    if (!user.subscription_product || !user.subscription_duration_type) return 'No Subscription';
    return capitalizeFirstLetter(user.subscription_duration_type);
  };

  const getStatusColor = (status?: string): string => {
    switch (status) {
      case 'Active':
        return 'success';
      case 'In Free Trial':
        return 'info';
      case 'Cancelled':
      case 'Expired':
        return 'danger';
      default:
        return 'secondary';
    }
  };

  return (
    <div className="container-fluid mt-4">
      <Link to="/dashboard/affiliates" className="btn btn-outline-primary mb-4">
        <ArrowLeft className="me-2" /> Back to affiliates
      </Link>

      <div className="row mb-4">
        <div className="col-md-6">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h5 className="card-title mb-0">Affiliate Information</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <InfoItem label="First Name" value={affiliateData.first_name} />
                  <InfoItem label="Email" value={affiliateData.email || 'N/A'} />
                  <div className="mb-3">
                    <h5 className="mb-0 d-flex align-items-center">
                      {affiliateData.code || 'N/A'}
                      <button 
                        className={`btn btn-sm btn-outline-secondary ms-2 ${copySuccess ? 'btn-success' : ''}`} 
                        onClick={handleCopyCode}
                      >
                        {copySuccess ? <Check size={14} /> : <Copy size={14} />}
                      </button>
                    </h5>
                    <small className="text-muted">Promo Code</small>
                  </div>
                  {affiliateData.country && <InfoItem label="Country" value={affiliateData.country} />}
                </div>
                <div className="col-md-6">
                  <InfoItem label="Last Name" value={affiliateData.last_name} />
                  <InfoItem label="Campaign" value={getCampaignName(campaigns, affiliateData.parent_campaign_id)} />
                  {affiliateData.phone && <InfoItem label="Phone" value={affiliateData.phone} />}
                  {affiliateData.status && <InfoItem label="Status" value={affiliateData.status} />}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title mb-0">{`${affiliateData.first_name} ${affiliateData.last_name}'s Commission`}</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <CommissionItem label="Paid Commission" value={formatCurrencyForApp(app, counts.commission)} />
                <CommissionItem label="Total Earned" value={formatCurrencyForApp(app, counts.commission)}/>
                <CommissionItem label="Unpaid" value={formatCurrencyForApp(app, counts.unpaid_commission)}/>
                <CommissionItem label="Pending" value={formatCurrencyForApp(app, counts.pending_commission)}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-3 mb-3">
          <MetricCard 
            title="Revenue Generated" 
            value={formatCurrencyForApp(app, counts.revenue)} 
            icon={<DollarSign className="text-muted" />}
            isMonetary={true}
          />
        </div>
        <div className="col-md-3 mb-3">
          <MetricCard 
            title="Number of Referrals" 
            value={counts.referrals} 
            icon={<Users className="text-muted" />}
            isMonetary={false}
          />
        </div>
        <div className="col-md-3 mb-3">
          <MetricCard 
            title="Users (New App Installs)" 
            value={counts.users} 
            icon={<Download className="text-muted" />}
            isMonetary={false}
          />
        </div>
        <div className="col-md-3 mb-3">
          <MetricCard 
            title="New ARR" 
            value={formatCurrencyForApp(app, counts.arr)} 
            icon={<TrendingUp className="text-muted" />}
            isMonetary={true}
          />
        </div>
      </div>

      <div className="card">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5 className="card-title mb-0">All Referred Users</h5>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-hover align-middle mb-0">
              <thead className="bg-light">
                <tr>
                  <th className="py-3"><User size={16} className="me-1" /> User ID / Email</th>
                  <th className="py-3"><Calendar size={16} className="me-1" /> Referral Date</th>
                  <th className="py-3"><Package size={16} className="me-1" /> Plan Type</th>
                  <th className="py-3"><Circle size={16} className="me-1" /> Plan Status</th>
                  <th className="py-3"><DollarSign size={16} className="me-1" /> Total Revenue</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.external_user_id}>
                    <td>{getUserIdentifier(user)}</td>
                    <td>{formatDate(user.created_at)}</td>
                    <td>{getPlanType(user)}</td>
                    <td>
                      <div className={`d-flex align-items-center text-${getStatusColor(user.subscription_status)}`}>
                        <Circle size={10} className="me-2" fill="currentColor" />
                        <span>{user.subscription_status || "N/A"}</span>
                      </div>
                      {user.subscription_status === 'Active' && user.subscription_product && user.subscription_value && (
                        <small className="text-muted d-block mt-1">${user.subscription_value.toFixed(2)} | {user.subscription_product}</small>
                      )}
                    </td>
                    <td>{formatCurrencyForApp(app, user.revenue)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <nav aria-label="Page navigation" className="mt-4">
        <ul className="pagination justify-content-center">
          <li className={`page-item ${!pagination.has_prev_page ? 'disabled' : ''}`}>
            <button className="page-link d-flex align-items-center" onClick={() => handlePageChange(pagination.current_page - 1)} disabled={!pagination.has_prev_page}>
              <ChevronLeft size={16} className="me-1" />
              Previous
            </button>
          </li>
          <li className="page-item active">
            <span className="page-link">{pagination.current_page}</span>
          </li>
          <li className={`page-item ${!pagination.has_next_page ? 'disabled' : ''}`}>
            <button className="page-link d-flex align-items-center" onClick={() => handlePageChange(pagination.current_page + 1)} disabled={!pagination.has_next_page}>
              Next
              <ChevronRight size={16} className="ms-1" />
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default AffiliateDetails;