import React, { useState, useEffect } from 'react';
import { User, Mail, Users, Calendar, Package, DollarSign, ChevronLeft, ChevronRight, Circle, UserPlus } from 'lucide-react';
import { COLORS } from '../../utils/constants';
import ApiService, { App, Campaign, ConversionUser, PaginationData } from '../../utils/service';
import { useNavigate, Link } from 'react-router-dom';
import { capitalizeFirstLetter, formatCurrencyForApp } from '../../utils/strings';

interface ReferralViewProps {
  campaigns: Campaign[];
  app: App;
  token: string | undefined;
}

const apiService = new ApiService();

const ReferralView: React.FC<ReferralViewProps> = ({ campaigns, app, token }) => {
  const appId = app.app_id;
  const [users, setUsers] = useState<ConversionUser[]>([]);
  const [selectedCampaignId, setSelectedCampaignId] = useState<string | undefined>(undefined);
  const [pagination, setPagination] = useState<PaginationData>({
    current_page: 1,
    page_size: 10,
    total_count: 0,
    total_pages: 0,
    has_next_page: false,
    has_prev_page: false,
  });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    loadUsers(pagination.current_page, pagination.page_size, selectedCampaignId);
  }, [selectedCampaignId, appId]);

  const loadUsers = async (page: number, pageSize: number, campaignId: string | undefined) => {
    setLoading(true);
    try {
      const response = await apiService.getReferrals(appId, page, pageSize, token || "", campaignId);
      setUsers(response.conversion_users);
      setPagination(response.pagination);
    } catch (error) {
      console.error('Failed to fetch users:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (newPage: number) => {
    loadUsers(newPage, pagination.page_size, selectedCampaignId);
  };

  const handleCampaignChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCampaignId(event.target.value === 'all' ? undefined : event.target.value);
    setPagination(prevState => ({ ...prevState, current_page: 1 }));
  };

  const formatDate = (dateString: string): string => {
    return new Date(dateString).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
  };

  const getUserIdentifier = (user: ConversionUser): string => {
    return user.email ? user.email.split('@')[0] + '...' : user.external_user_id;
  };

  const getPlanType = (user: ConversionUser): string => {
    if (!user.subscription_product || !user.subscription_duration_type) return 'No Subscription';
    return capitalizeFirstLetter(user.subscription_duration_type);
  };

  const getStatusColor = (status?: string): string => {
    switch (status) {
      case 'Active':
        return 'success';
      case 'In Free Trial':
        return 'info';
      case 'Cancelled':
      case 'Expired':
        return 'danger';
      default:
        return 'secondary';
    }
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div className="d-flex align-items-center">
          <UserPlus size={24} className="me-2" color={COLORS.primary} />
          <h4 style={{ color: COLORS.text, fontWeight: 'bold', }}>Referrals</h4>
        </div>
        <div className="d-flex align-items-center">
          <select 
            className="form-select" 
            value={selectedCampaignId || 'all'} 
            onChange={handleCampaignChange}
          >
            <option value="all">All Campaigns</option>
            {campaigns.map((campaign) => (
              <option key={campaign.campaign_id} value={campaign.campaign_id}>
                {campaign.campaign_name}
              </option>
            ))}
          </select>
        </div>
      </div>
      {loading ? (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="card shadow-sm" style={{ borderColor: COLORS.border, borderRadius: '10px' }}>
          <div className="card-body p-0">
            <div className="table-responsive">
              <table className="table table-hover align-middle mb-0">
                <thead className="bg-light">
                  <tr>
                    <th className="py-3"><User size={16} className="me-1" /> User ID / Email</th>
                    <th className="py-3"><Users size={16} className="me-1" /> Referred By</th>
                    <th className="py-3"><Calendar size={16} className="me-1" /> Referral Date</th>
                    <th className="py-3"><Package size={16} className="me-1" /> Plan Type</th>
                    <th className="py-3"><Circle size={16} className="me-1" /> Plan Status</th>
                    <th className="py-3"><DollarSign size={16} className="me-1" /> Total Revenue</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user) => (
                    <tr key={user.external_user_id}>
                      <td>{getUserIdentifier(user)}</td>
                      <td>
                        <Link to={`/dashboard/affiliates/${user.affiliate_user_id}`} className="text-decoration-none">
                          {`${user.affiliate_first_name || ''} ${user.affiliate_last_name || ''}`}
                        </Link>
                      </td>
                      <td>{formatDate(user.created_at)}</td>
                      <td>{getPlanType(user)}</td>
                      <td>
                      <div className={`d-flex align-items-center text-${getStatusColor(user.subscription_status)}`}>
                        <Circle size={10} className="me-2" fill="currentColor" />
                        <span>{user.subscription_status || "N/A"}</span>
                      </div>
                      {user.subscription_status === 'Active' && user.subscription_product && user.subscription_value && (
                        <small className="text-muted d-block mt-1">${user.subscription_value.toFixed(2)} | {user.subscription_product}</small>
                      )}
                    </td>
                      <td>{formatCurrencyForApp(app, user.revenue)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      <nav aria-label="Page navigation" className="mt-4">
        <ul className="pagination justify-content-center">
          <li className={`page-item ${!pagination.has_prev_page ? 'disabled' : ''}`}>
            <button className="page-link d-flex align-items-center" onClick={() => handlePageChange(pagination.current_page - 1)} disabled={!pagination.has_prev_page}>
              <ChevronLeft size={16} className="me-1" />
              Previous
            </button>
          </li>
          <li className="page-item active">
            <span className="page-link">{pagination.current_page}</span>
          </li>
          <li className={`page-item ${!pagination.has_next_page ? 'disabled' : ''}`}>
            <button className="page-link d-flex align-items-center" onClick={() => handlePageChange(pagination.current_page + 1)} disabled={!pagination.has_next_page}>
              Next
              <ChevronRight size={16} className="ms-1" />
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default ReferralView;