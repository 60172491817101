import React from 'react';
import { AffiliateAppData, Campaign, SubdomainAppData } from '../../utils/service';
import { AlertCircle, DollarSign, Gift } from 'lucide-react';
import { COLORS } from '../../utils/constants';
import { formatCurrency } from '../../utils/strings';

interface AffiliateProgramBannerProps {
  app: SubdomainAppData;
  affiliateAppData: AffiliateAppData,
  campaignData: Campaign;
}

const AffiliateProgramBanner: React.FC<AffiliateProgramBannerProps> = ({ app, affiliateAppData, campaignData }) => {
  const getCommissionDescription = () => {
    if (campaignData.commission_type === 'tiered' && campaignData.tiered_commissions) {
      const { type, tiers } = campaignData.tiered_commissions;
      return (
        <div>
          <small>Tiered {type}-based commission:</small>
          <ul className="list-unstyled mb-0">
            {tiers.map((tier, index) => (
              <li key={index} className="small">
                {tier.min}-{tier.max !== undefined ? tier.max : '∞'}: {tier.commission_type === `${tier.value}percentage` ? '%' : formatCurrency(affiliateAppData, tier.value)}
              </li>
            ))}
          </ul>
        </div>
      );
    } else {
      return `${campaignData.commission_type === 'percentage' ? `${campaignData.commission_value}%` : formatCurrency(affiliateAppData, campaignData.commission_value)} per sale`;
    }
  };

  const getReferralBonusDescription = () => {
    if (campaignData.referral_bonus) {
      const { threshold, amount, threshold_type } = campaignData.referral_bonus;
      return `${formatCurrency(affiliateAppData, amount)} bonus for ${threshold} ${threshold_type}`;
    }
    return 'No bonus offered';
  };

  const renderStatusBanner = () => {
    if (affiliateAppData.status === undefined) {
      return (
        <div className="alert alert-warning mb-3" role="alert">
          Pending Approval
        </div>
      );
    } else if (affiliateAppData.status === 'rejected') {
      return (
        <div className="alert alert-danger mb-3" role="alert">
          You have been removed from the Affiliate Program. Please contact {app.app_name} Team for more info.
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {renderStatusBanner()}
      <div className="card mb-3">
        <div className="card-header py-2" style={{ backgroundColor: app.brand_color || COLORS.primary }}>
          <h6 className="mb-0" style={{ color: app.brand_text_color || COLORS.text }}>
            <AlertCircle className="me-2" size={16} />
            Affiliate Program Overview
          </h6>
        </div>
        <div className="card-body py-2">
          <div className="row align-items-center">
            <div className="col-md-6 border-end">
              <div className="d-flex align-items-center">
                <DollarSign className="me-1" size={16} />
                <strong className="small">Commission</strong>
              </div>
              <div className="">{getCommissionDescription()}</div>
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center">
                <Gift className="me-1" size={16} />
                <strong className="small">Bonus</strong>
              </div>
              <div className="small">{getReferralBonusDescription()}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AffiliateProgramBanner;