import React, { useState, useEffect } from 'react';
import { User, Trash2, PlusCircle, X, CheckCircle, ExternalLink } from 'lucide-react';
import ApiService, { AffiliateSocial, AddAffiliateSocialsRequest, GenericBoolResponse, SubdomainAppData } from '../../utils/service';
import { COLORS, socialMediaOptions } from '../../utils/constants';

interface AffiliateSocialManagementProps {
  app: SubdomainAppData;
  token: string | undefined;
}

const apiService = new ApiService()

const AffiliateSocialManagement: React.FC<AffiliateSocialManagementProps> = ({ 
  app, 
  token, 
}) => {
  const [socials, setSocials] = useState<AffiliateSocial[]>([]);
  const [newSocial, setNewSocial] = useState({ social_type: '', handler: '' });
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchSocials();
  }, []);

  const fetchSocials = async () => {
    try {
      const fetchedSocials = await apiService.getAffiliateSocials(app.app_id, token || "");
      setSocials(fetchedSocials);
    } catch (error) {
      console.error('Error fetching socials:', error);
      setError('Failed to fetch social media accounts. Please try again.');
    }
  };

  const handleAddSocial = async () => {
    if (!newSocial.social_type || !newSocial.handler) {
      setError('Please select a platform and enter a handler.');
      return;
    }

    try {
      const addSocialsRequest: AddAffiliateSocialsRequest = {
        socials: [newSocial]
      };
      const response = await apiService.addAffiliateSocials(app.app_id, addSocialsRequest, token || "");
      if (response.status) {
        setNewSocial({ social_type: '', handler: '' });
        fetchSocials();
        setError(null);
      } else {
        setError('Failed to add social media account. Please try again.');
      }
    } catch (error) {
      console.error('Error adding social:', error);
      setError('Failed to add social media account. Please try again.');
    }
  };

  const handleDeleteSocial = async (socialId: string) => {
    try {
      const response = await apiService.deleteAffiliateSocial(app.app_id, socialId, token || "");
      if (response.status) {
        fetchSocials();
      } else {
        setError('Failed to delete social media account. Please try again.');
      }
    } catch (error) {
      console.error('Error deleting social:', error);
      setError('Failed to delete social media account. Please try again.');
    }
  };

  const renderSocialPreview = (social: AffiliateSocial) => {
    switch (social.social_type.toLowerCase()) {
      case 'instagram':
        return (
          <a href={`https://instagram.com/${social.handler}`} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-secondary">
            View Profile <ExternalLink size={14} />
          </a>
        );
      case 'tiktok':
        return (
          <a href={`https://tiktok.com/@${social.handler}`} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-secondary">
            View Profile <ExternalLink size={14} />
          </a>
        );
      case 'twitter':
        return (
          <a href={`https://twitter.com/${social.handler}`} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-secondary">
            View Profile <ExternalLink size={14} />
          </a>
        );
      default:
        return null;
    }
  };

  return (
    <div className="container mt-4">
      <div className="card shadow-sm" style={{ borderColor: '#e0e0e0', borderRadius: '10px' }}>
        <div className="card-body p-4">
          <div className="mb-4">
            <div className="d-flex mb-2">
              <select
                className="form-select me-2"
                value={newSocial.social_type}
                onChange={(e) => setNewSocial({ ...newSocial, social_type: e.target.value })}
              >
                <option value="">Select Platform</option>
                {socialMediaOptions.map((platform) => (
                  <option key={platform} value={platform}>{platform}</option>
                ))}
              </select>
              <input
                type="text"
                className="form-control me-2"
                placeholder="Enter handler"
                value={newSocial.handler}
                onChange={(e) => setNewSocial({ ...newSocial, handler: e.target.value })}
              />
              <button 
                className="btn btn-primary" 
                onClick={handleAddSocial}
                style={{ backgroundColor: app.brand_color || COLORS.primary, borderColor: app.brand_color || COLORS.primary }}
              >
                <PlusCircle size={24} />
              </button>
            </div>
          </div>
          {socials.map((social) => (
            <div key={social.affiliate_social_id} className="d-flex align-items-center mb-3">
              <CheckCircle size={24} className="me-3" color={app.brand_color || COLORS.primary} />
              <div className="flex-grow-1">
                <small className="text-muted d-block">{social.social_type}</small>
                <strong>{social.handler}</strong>
              </div>
              <div className="me-3">
                {renderSocialPreview(social)}
              </div>
              <button 
                className="btn btn-danger btn-sm" 
                onClick={() => handleDeleteSocial(social.affiliate_social_id)}
              >
                <X size={18} />
              </button>
            </div>
          ))}
        </div>
      </div>
      {error && <div className="alert alert-danger mt-3">{error}</div>}
    </div>
  );
};

export default AffiliateSocialManagement;