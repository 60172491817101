import React, { useState, useEffect } from 'react';
import { CodeIcon, Download, Edit, FileText, Image, Copy, Plus } from 'lucide-react';
import ApiService, { AffiliateAppData, Asset, SubdomainAppData, UpdateAffiliateCodeRequest, GenericBoolResponse } from '../../utils/service';
import { Modal, Button, Form } from 'react-bootstrap';
import { COLORS } from '../../utils/constants';

interface AffiliateAssetManagementProps {
  app: SubdomainAppData;
  affiliateAppData: AffiliateAppData;
  token: string | undefined;
}

const apiService = new ApiService();

const AffiliateAssetManagement: React.FC<AffiliateAssetManagementProps> = ({ app, affiliateAppData, token }) => {
  const [assets, setAssets] = useState<Asset[]>([]);
  const [showCodeModal, setShowCodeModal] = useState(false);
  const [editedCode, setEditedCode] = useState(affiliateAppData.code || '');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [error, setError] = useState<string | null>(null);
  const [copiedText, setCopiedText] = useState<string | null>(null);
  const [isCodeUpdating, setIsCodeUpdating] = useState(false);

  useEffect(() => {
    fetchAssets();
  }, [app, token, currentPage]);

  const fetchAssets = async () => {
    if (!token) return;
    try {
      const response = await apiService.getAssets(app.app_id, currentPage, 12, token);
      setAssets(response.assets);
      setTotalPages(response.pagination.total_pages);
    } catch (error) {
      console.error("Error fetching assets:", error);
    }
  };

  const handleDownload = async (assetId: string, fileName: string) => {
    if (!token) return;
    try {
      const blob = await apiService.downloadAsset(app.app_id, assetId, token);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading asset:", error);
    }
  };

  const handleCodeUpdate = async () => {
    if (!token) return;
    setIsCodeUpdating(true);
    setError(null);
    try {
      const updateRequest: UpdateAffiliateCodeRequest = {
        code: editedCode,
        platform: '',
        prev_code: affiliateAppData.code || undefined
      };
      const response: GenericBoolResponse = await apiService.updateCode(app.app_id, updateRequest, token);
      if (response.status) {
        affiliateAppData.code = editedCode;
        setShowCodeModal(false);
      } else {
        setError('This referral code is already in use by another affiliate. Please try a different code.');
      }
    } catch (error) {
      console.error("Error updating affiliate code:", error);
      setError("An error occurred while updating the referral code. Please try again.");
    } finally {
      setIsCodeUpdating(false);
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopiedText(text);
    setTimeout(() => setCopiedText(null), 2000);
  };

  const renderCodeAsset = () => (
    <div className="col-md-4 col-lg-3 mb-3">
      <div className="card h-100 shadow-sm" style={{ borderColor: COLORS.border, borderRadius: '10px' }}>
        <div className="card-body d-flex flex-column">
          <div className="d-flex align-items-center mb-2">
            <CodeIcon size={24} />
            <h6 className="card-title mb-0 ms-2" style={{ color: COLORS.text, fontWeight: 'bold', fontSize: '1rem' }}>Referral Code</h6>
          </div>
          {affiliateAppData.code ? (
            <>
              <p className="card-text small text-muted mb-3">{affiliateAppData.code}</p>
              <div className="mt-auto d-flex justify-content-between">
                <button 
                  className="btn btn-outline-primary btn-sm"
                  onClick={() => copyToClipboard(affiliateAppData.code || '')}
                >
                  <Copy size={16} />
                  {copiedText === affiliateAppData.code ? 'Copied!' : 'Copy'}
                </button>
                <button 
                  className="btn btn-outline-secondary btn-sm"
                  onClick={() => setShowCodeModal(true)}
                >
                  <Edit size={16} />
                </button>
              </div>
            </>
          ) : (
            <div className="mt-auto">
              <button 
                className="btn btn-primary btn-sm w-100"
                onClick={() => setShowCodeModal(true)}
                style={{ backgroundColor: app.brand_color || COLORS.primary, borderColor: app.brand_color || COLORS.primary, color: app.brand_text_color || COLORS.text}}
              >
                <Plus size={16} className="me-2" />
                Create Referral Code
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  const renderAssetCard = (asset: Asset) => (
    <div key={asset.app_resource_id} className="col-md-4 col-lg-3 mb-3">
      <div className="card h-100 shadow-sm" style={{ borderColor: COLORS.border, borderRadius: '10px' }}>
        <div className="card-body d-flex flex-column">
          <div className="d-flex align-items-center mb-2">
            {asset.file_content_type.startsWith('image/') ? (
              <Image size={24} />
            ) : (
              <FileText size={24} />
            )}
            <h6 className="card-title mb-0 ms-2" style={{ color: COLORS.text, fontWeight: 'bold', fontSize: '1rem' }}>{asset.file_name}</h6>
          </div>
          <p className="card-text small text-muted mb-3">{asset.file_description}</p>
          <div className="mt-auto d-flex justify-content-between">
            <button 
              className="btn btn-outline-primary btn-sm"
              onClick={() => handleDownload(asset.app_resource_id, asset.file_name)}
            >
              <Download size={16} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="container mt-4">      
      <div className="row">
        {renderCodeAsset()}
        {assets.map(renderAssetCard)}
      </div>

      {assets.length === 0 && (
        <div className="text-center mt-5">
          <h6>No assets uploaded</h6>
          <p>Upload assets to share with affiliates</p>
        </div>
      )}

      {totalPages > 1 && (
        <nav className="mt-4">
          <ul className="pagination justify-content-center">
            {[...Array(totalPages)].map((_, index) => (
              <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                <button className="page-link" onClick={() => setCurrentPage(index + 1)}>
                  {index + 1}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      )}

      <Modal show={showCodeModal} onHide={() => setShowCodeModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{affiliateAppData.code ? 'Edit' : 'Create'} Referral Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Referral Code</Form.Label>
              <Form.Control 
                type="text" 
                value={editedCode} 
                onChange={(e) => setEditedCode(e.target.value.toUpperCase())}
                maxLength={6}
                placeholder="Enter 6-character code"
              />
            </Form.Group>
          </Form>
          {error && <p className="text-danger mt-2">{error}</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCodeModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" 
            style={{ backgroundColor: app.brand_color || COLORS.primary, borderColor: app.brand_color || COLORS.primary, color: app.brand_text_color || COLORS.text}}
            onClick={handleCodeUpdate} disabled={isCodeUpdating}>
            {isCodeUpdating ? 'Processing...' : (affiliateAppData.code ? 'Update Code' : 'Create Code')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AffiliateAssetManagement;