import React, { createContext, useContext, useState, useEffect } from 'react';
import { AffiliateAppData, SubdomainAppData } from '../../utils/service';
import ApiService from '../../utils/service';
import { useAuth } from '../../contexts/AuthContext';
import { getSubdomain } from '../../utils/subdomain';
import { BASE_WEBURL } from '../../utils/constants';

interface AppDataContextType {
  app: SubdomainAppData | undefined;
  affiliateAppData: AffiliateAppData | undefined;
  isLoading: boolean;
  setAppAffiliateData: (appId: string, affiliateData: any, addAffiliateSocialsRequest: any) => Promise<void>;
  token?: string;
  userCount: number;
  referralCount: number;
  commission: number;
  unpaidCommission: number;
  updateAffiliateAppData: (appId: string, affiliateAppData: AffiliateAppData) => Promise<void>;
}

const AffiliateAppDataContext = createContext<AppDataContextType | undefined>(undefined);

export const AffiliateAppDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [app, setApp] = useState<SubdomainAppData | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState<string | undefined>(undefined);
  const { session } = useAuth();
  const [affiliateAppData, setAffiliateAppData] = useState<AffiliateAppData | undefined>(undefined);
  const [userCount, setUserCount] = useState<number>(0);
  const [referralCount, setReferralCount] = useState<number>(0);
  const [commission, setCommission] = useState<number>(0);
  const [unpaidCommission, setUnpaidCommission] = useState<number>(0);
  const apiService = new ApiService();

  useEffect(() => {
    const updateSessionToken = async () => {
      if (session) {
        setToken(session.access_token);
      }
    };

    updateSessionToken();
  }, [session]);

  const fetchAppData = async () => {
    try {
      setIsLoading(true);
      const app = await apiService.getAppForSubdomain(getSubdomain() || "");
      if (!app.default_campaign_id) {
        gotoHomePage();
        return;
      }
      setApp(app)
      if (token) {
        const affiliateAppData = await apiService.getAffiliateAppData(app.app_id, token)
        setAffiliateAppData(affiliateAppData)
        // Fetch counts using getAffiliateAppCounts
        const userCountData = await apiService.getAffiliateAppCounts(app.app_id, "users", token);
        const referralCountData = await apiService.getAffiliateAppCounts(app.app_id, "referrals", token);
        const commissionData = await apiService.getAffiliateAppCounts(app.app_id, "commission", token);
        const unpaidCommissionData = await apiService.getAffiliateAppCounts(app.app_id, "unpaid_commission", token);

        setUserCount(userCountData.num);
        setReferralCount(referralCountData.num);
        setCommission(commissionData.num);
        setUnpaidCommission(unpaidCommissionData.num);
      }
    } catch (error) {
      console.error('Error fetching apps:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const gotoHomePage = () => {
    // take user back to home page out from subdomain
    const mainDomain = process.env.REACT_APP_MAIN_DOMAIN || BASE_WEBURL;
    window.location.href = mainDomain;
  }

  useEffect(() => {
    fetchAppData();
  }, [token]);

  const setAppAffiliateData = async (appId: string, affiliateData: any, addAffiliateSocialsRequest: any) => {
    await apiService.addAffiliate(affiliateData, token || "")
    // fail safe call to set socials
    apiService.addAffiliateSocials(appId, addAffiliateSocialsRequest, token || "")
    fetchAppData()
  }

  const updateAffiliateAppData = async (appId: string, affiliateAppData: AffiliateAppData) => {
    await apiService.updateAffiliateAppData(appId, affiliateAppData, token || "")
    fetchAppData()
  };

  return (
    <AffiliateAppDataContext.Provider value={{ 
      app, affiliateAppData, isLoading, setAppAffiliateData, token,
      userCount, referralCount, commission, unpaidCommission,
      updateAffiliateAppData,
    }}>
      {children}
    </AffiliateAppDataContext.Provider>
  );
};

export const useAppData = () => {
  const context = useContext(AffiliateAppDataContext);
  if (context === undefined) {
    throw new Error('useAppData must be used within an AppDataProvider');
  }
  return context;
};
