import React, { useState, useEffect, CSSProperties, ChangeEvent } from 'react';
import { Container, Form, Button, Card, Alert, Modal, Row, Col, Image, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useAppData } from '../contexts/AppDataContext';
import { AddAffiliateSocial, AffiliateAppData, SubdomainAppData, AddAffiliateSocialsRequest } from '../../utils/service';
import BubbleSelect from '../../components/BubbleSelectComponent';
import { COLORS, currencies, socialMediaOptions } from '../../utils/constants';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import LoginModal from '../components/LoginModal';
import { Session } from '@supabase/supabase-js';
import { PlusCircle, X } from 'lucide-react';

const createStyles = (brandColor: string, brandTextColor: string): Record<string, CSSProperties> => ({
  loginCard: {
    width: '100%',
    maxWidth: '750px',
    border: 'none',
    borderRadius: '12px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  brandButton: {
    backgroundColor: brandColor,
    borderColor: brandColor,
  },
  formControl: {
    borderColor: COLORS.border,
  },
  bubbleContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
  },
  bubble: {
    padding: '8px 16px',
    borderRadius: '20px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    fontSize: '14px',
  },
  bubbleSelected: {
    backgroundColor: brandColor,
    color: 'white',
  },
  bubbleUnselected: {
    backgroundColor: '#f0f0f0',
    color: '#333',
  },
  brandLogo: {
    maxWidth: '90px',
    marginRight: '1rem',
  },
  brandName: {
    color: brandTextColor,
    margin: 0,
  },
});

const createResponsiveCSS = (brandColor: string) => `
  .responsive-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .responsive-brand-panel {
    width: 100%;
    background-color: ${brandColor};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }

  .responsive-form-panel {
    width: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 768px) {
    .responsive-container {
      flex-direction: row;
    }

    .responsive-brand-panel {
      width: 30%;
      min-height: 100vh;
    }

    .responsive-form-panel {
      width: 70%;
      padding: 2rem;
    }
      
    .login-card {
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1) !important;
    }
  }

  .login-button:hover, .login-button:focus, .login-button:active {
    background-color: ${brandColor} !important;
    border-color: ${brandColor} !important;
    opacity: 0.9;
  }
  
  .form-control:focus {
    border-color: ${brandColor} !important;
    box-shadow: 0 0 0 0.2rem ${brandColor}40 !important;
  }
`;

const AffiliateDataForm: React.FC<{ app: SubdomainAppData, onSubmit: (data: AffiliateAppData, validSocials: AddAffiliateSocial[]) => void, styles: Record<string, CSSProperties>, session: Session | null, setEmail: any }> = ({ app, onSubmit, styles, session, setEmail }) => {
  const [formData, setFormData] = useState<AffiliateAppData>({
    affiliate_user_id: '',
    app_id: app.app_id,
    first_name: '',
    last_name: '',
    email: session?.user.email || '',
    phone: '',
    country: '',
    birthdate: '',
    gender: '',
    device_type: '',
    languages: '',
    currency: '',
    parent_campaign_id: app.default_campaign_id,
  });

  const [socialMedia, setSocialMedia] = useState<AddAffiliateSocial[]>([
    { social_type: '', handler: '' }
  ]);

  const [formErrors, setFormErrors] = useState<{
    birthdate?: string;
    currency?: string;
  }>({});

  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
  const [selectedDeviceType, setSelectedDeviceType] = useState<string[]>([]);
  const [selectedGender, setSelectedGender] = useState<string[]>([]);

  const languageOptions = ['English', 'Spanish', 'French', 'German', 'Chinese', 'Japanese'];
  const deviceOptions = ['iOS', 'Android'];
  const genderOptions = ['Male', 'Female', 'Other'];
  const countryOptions = ['United States', 'Canada', 'United Kingdom', 'Australia', 'Germany', 'France', 'Japan', 'China', 'India', 'Brazil'];

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
    }
    setFormData(prev => ({ ...prev, [name]: value }));
    setFormErrors(prev => ({ ...prev, [name]: undefined }));
  };

  const handleDateChange = (date: Date | null) => {
    setFormData(prev => ({ ...prev, birthdate: date ? date.toISOString().split('T')[0] : '' }));
    setFormErrors(prev => ({ ...prev, birthdate: undefined }));
  };

  const handleSocialMediaChange = (index: number, field: 'social_type' | 'handler', value: string) => {
    const updatedSocialMedia = [...socialMedia];
    updatedSocialMedia[index] = { ...updatedSocialMedia[index], [field]: value };
    setSocialMedia(updatedSocialMedia);
  };

  const addSocialMediaField = () => {
    setSocialMedia([...socialMedia, { social_type: '', handler: '' }]);
  };

  const removeSocialMediaField = (index: number) => {
    setSocialMedia(socialMedia.filter((_, i) => i !== index));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const submissionData = {
      ...formData,
      languages: selectedLanguages.join(','),
      device_type: selectedDeviceType[0] || '',
      gender: selectedGender[0] || '',
    };

    const validSocials = socialMedia.filter(sm => sm.social_type && sm.handler);
    
    const errors: typeof formErrors = {};
    
    if (!submissionData.birthdate) {
      errors.birthdate = 'Birthdate is required';
    }
    if (!submissionData.currency) {
      errors.currency = 'Currency is required';
    }
    
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    
    onSubmit(submissionData, validSocials);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>First Name *</Form.Label>
            <Form.Control type="text" name="first_name" value={formData.first_name} onChange={handleChange} required style={styles.formControl} />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Last Name *</Form.Label>
            <Form.Control type="text" name="last_name" value={formData.last_name} onChange={handleChange} required style={styles.formControl} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Email *</Form.Label>
            <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} required style={styles.formControl} disabled={session !== null} />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Phone</Form.Label>
            <Form.Control type="tel" name="phone" value={formData.phone} onChange={handleChange} style={styles.formControl} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Form.Group className="mb-3">
            <Form.Label>Country</Form.Label>
            <Form.Select name="country" value={formData.country} onChange={handleChange} style={styles.formControl}>
              <option value="">Select a country</option>
              {countryOptions.map(country => (
                <option key={country} value={country}>{country}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group className="mb-3">
            <Form.Label>Currency *</Form.Label>
            <Form.Select 
              name="currency" 
              value={formData.currency} 
              onChange={handleChange} 
              style={styles.formControl}
              isInvalid={!!formErrors.currency}
              required
            >
              <option value="">Select a currency</option>
              {currencies.map(currency => (
                <option key={currency.code} value={currency.code}>{currency.name}</option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">{formErrors.currency}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group className="mb-3">
            <Form.Label>Birthdate *</Form.Label>
            <DatePicker
              selected={formData.birthdate ? new Date(formData.birthdate) : null}
              onChange={handleDateChange}
              dateFormat="MM/dd/yyyy"
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={100}
              placeholderText="Select your birthdate"
              className={`form-control ${formErrors.birthdate ? 'is-invalid' : ''}`}
              wrapperClassName="w-100"
              customInput={<Form.Control style={styles.formControl} required />}
            />
            {formErrors.birthdate && (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                {formErrors.birthdate}
              </div>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Form.Group className="mb-3">
            <Form.Label>Gender</Form.Label>
            <BubbleSelect
              options={genderOptions}
              selectedOptions={selectedGender}
              onChange={(selected) => setSelectedGender(selected)}
              multiple={false}
              styles={styles}
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group className="mb-3">
            <Form.Label>Device Type</Form.Label>
            <BubbleSelect
              options={deviceOptions}
              selectedOptions={selectedDeviceType}
              onChange={(selected) => setSelectedDeviceType(selected)}
              multiple={false}
              styles={styles}
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group className="mb-3">
            <Form.Label>Languages</Form.Label>
            <BubbleSelect
              options={languageOptions}
              selectedOptions={selectedLanguages}
              onChange={(selected) => setSelectedLanguages(selected)}
              multiple={true}
              styles={styles}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Form.Group className="mb-3">
            <Form.Label>Social Handles</Form.Label>
            {socialMedia.map((social, index) => (
              <Row key={index} className="mb-2">
                <Col md={5}>
                  <Form.Select
                    value={social.social_type}
                    onChange={(e) => handleSocialMediaChange(index, 'social_type', e.target.value)}
                    style={styles.formControl}
                  >
                    <option value="">Select Platform</option>
                    {socialMediaOptions.map(platform => (
                      <option key={platform} value={platform}>{platform}</option>
                    ))}
                  </Form.Select>
                </Col>
                <Col md={5}>
                  <Form.Control
                    type="text"
                    placeholder="Username"
                    value={social.handler}
                    onChange={(e) => handleSocialMediaChange(index, 'handler', e.target.value)}
                    style={styles.formControl}
                  />
                </Col>
                <Col md={2} className="d-flex align-items-center">
                  {index === socialMedia.length - 1 ? (
                    <PlusCircle
                      onClick={addSocialMediaField}
                      style={{ cursor: 'pointer', color: styles.brandButton.backgroundColor }}
                    />
                  ) : (
                    <X
                      onClick={() => removeSocialMediaField(index)}
                      style={{ cursor: 'pointer', color: 'red' }}
                    />
                  )}
                </Col>
              </Row>
            ))}
          </Form.Group>
        </Col>
      </Row>
      <Button type="submit" className="w-100 login-button" style={styles.brandButton}>Enroll</Button>
    </Form>
  );
};

function AffiliateOnboardPage() {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [email, setEmail] = useState(undefined);
  const navigate = useNavigate();
  const { session, signIn, verifyOtp, signOut } = useAuth();
  const { affiliateAppData, setAppAffiliateData, app } = useAppData();

  const brandColor = app?.brand_color || COLORS.primary;
  const brandTextColor = app?.brand_text_color || COLORS.text;
  const STYLES = createStyles(brandColor, brandTextColor);
  const responsiveCSS = createResponsiveCSS(brandColor);

  useEffect(() => {
    if (session && affiliateAppData) {
      const intendedDestination = localStorage.getItem('intendedDestination') || '/dashboard/home';
      localStorage.removeItem('intendedDestination');
      navigate(intendedDestination);
    }
  }, [session, affiliateAppData, navigate]);

  const handleAffiliateDataSubmit = async (data: AffiliateAppData, validSocials: AddAffiliateSocial[]) => {
    try {
      if (session) {
        data.affiliate_user_id = session.user.id;
        const addAffiliateSocialsRequest: AddAffiliateSocialsRequest = {
          socials: validSocials,
        };
        await setAppAffiliateData(data.app_id, data, addAffiliateSocialsRequest);
        navigate('/dashboard/home');
      } else {
        setShowLoginModal(true);
      }
    } catch (error) {
      console.log('Error with onboarding. Please try again.');
    }
  };

  if (!app) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <>
      <style>{responsiveCSS}</style>
      <Container fluid className="p-0 responsive-container">
        <div className="responsive-brand-panel">
          <div className="d-flex flex-column align-items-center justify-content-center h-100">
            {app?.brand_url && <Image src={app.brand_url} alt="Brand Logo" style={STYLES.brandLogo} className="mb-3" />}
            <h2 style={STYLES.brandName}>{app?.app_name}</h2>
          </div>
        </div>
        <div className="responsive-form-panel">
          <Card style={STYLES.loginCard}>
            <Card.Body className="p-4">
              <h2 className="text-center">Affiliate Onboarding</h2>
              <div className="text-center mb-3">
                {
                  !session ? (
                    <Button variant="link" onClick={() => setShowLoginModal(true)}>
                      Already have an account? Log in
                    </Button>  
                  ) : (
                    <Button variant="link" onClick={() => signOut()}>
                     Click to Logout
                    </Button> 
                  )
                }
              </div>
              {(!session || !affiliateAppData) && (
                <AffiliateDataForm 
                  app={app} 
                  onSubmit={handleAffiliateDataSubmit} 
                  styles={STYLES} 
                  session={session}
                  setEmail={setEmail}
                />
              )}
            </Card.Body>
          </Card>
        </div>
        <LoginModal
          showLoginModal={showLoginModal}
          setShowLoginModal={setShowLoginModal}
          signIn={signIn}
          verifyOtp={verifyOtp}
          affiliateAppData={affiliateAppData}
          navigate={navigate}
          STYLES={STYLES}
          loginEmail={email}
        />
      </Container>
    </>
  );
}

export default AffiliateOnboardPage;
