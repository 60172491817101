import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './brand/screens/LoginPage';
import DashboardPage from './brand/screens/DashboardPage';
import AuthHandler from './brand/components/AuthHandler';
import PrivateRoute from './brand/components/PrivateRoute';
import { AuthProvider } from './contexts/AuthContext';
import { AppDataProvider } from './brand/contexts/AppDataContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { getSubdomain } from './utils/subdomain';
import { AffiliateAppDataProvider } from './affiliate/contexts/AppDataContext';
import AffiliateOnboard from './affiliate/screens/Onboard';
import AffiliatePrivateRoute from './affiliate/components/PrivateRoute';
import AffiliateDashboard from './affiliate/screens/Dashboard';
import ComingSoonPage from './screens/Landing';

function App() {
  const subdomain = getSubdomain()
  if (subdomain !== undefined) {
    // affilate portal
    return (
      <AuthProvider>
        <AffiliateAppDataProvider>
          <Router>
            <Routes>
              {/* Onboarding screen if affiliateAppData is not set */}
              {/* Login screen if affiliateAppData is set / also option to go to login from onboarding */}
              {/* Dashboard screen if everything is set */}
              <Route path="/" element={<AffiliateOnboard />} />
              <Route 
                path="/dashboard/*" 
                element={
                <AffiliatePrivateRoute>
                  <Routes>
                    <Route path="home" element={<AffiliateDashboard selectedMenuItem='home' />} />
                    <Route path="assets" element={<AffiliateDashboard selectedMenuItem='assets' />} />
                    <Route path="payouts" element={<AffiliateDashboard selectedMenuItem='payouts' />} />
                    <Route path="socials" element={<AffiliateDashboard selectedMenuItem='socials' />} />
                    <Route path="settings" element={<AffiliateDashboard selectedMenuItem='settings' />} />
                    <Route path="help" element={<AffiliateDashboard selectedMenuItem='help' />} />
                  </Routes>
                </AffiliatePrivateRoute>
              } />
              <Route path="*" element={<ComingSoonPage />} />
            </Routes>
          </Router>
        </AffiliateAppDataProvider>
      </AuthProvider>
    )
  }

  // brand portal
  return (
    <AuthProvider>
      <AppDataProvider>
        <Router>
          <AuthHandler />
          <Routes>
            <Route path="/" element={<ComingSoonPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route 
              path="/dashboard/*" 
              element={
                <PrivateRoute>
                  <Routes>
                    <Route path="home" element={<DashboardPage selectedMenuItem="home" />} />
                    <Route path="programs" element={<DashboardPage selectedMenuItem="programs" />} />
                    <Route path="analytics" element={<DashboardPage selectedMenuItem="analytics" />} />
                    <Route path="affiliates" element={<DashboardPage selectedMenuItem="affiliates" />} />
                    <Route path="affiliates/:affiliate_id" element={<DashboardPage selectedMenuItem="affiliates" selectedSubMenuItem="details" />} />
                    <Route path="payouts" element={<DashboardPage selectedMenuItem="payouts" />} />
                    <Route path="referrals" element={<DashboardPage selectedMenuItem="referrals" />} />
                    <Route path="integrations" element={<DashboardPage selectedMenuItem="integrations" />} />
                    <Route path="assets" element={<DashboardPage selectedMenuItem="assets" />} />
                    <Route path="email" element={<DashboardPage selectedMenuItem="email" />} />
                    <Route path="settings" element={<DashboardPage selectedMenuItem="settings" />} />
                    <Route path="help" element={<DashboardPage selectedMenuItem="help" />} />
                    <Route path="postman" element={<DashboardPage selectedMenuItem="postman" />} />
                  </Routes>
                </PrivateRoute>
              } 
            />
            <Route path="*" element={<ComingSoonPage />} />
          </Routes>
        </Router>
      </AppDataProvider>
    </AuthProvider>
  );
}

export default App;