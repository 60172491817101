import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Form, Alert, Button } from 'react-bootstrap';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { DollarSign, Users, UserPlus, BarChart2, LucideIcon, Flag, UserPlusIcon, UserCheck, FileText } from 'lucide-react';
import ApiService, { App, Campaign, ConversionSumByDateObj, TopAffiliate } from '../../utils/service';
import { COLORS } from '../../utils/constants';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate } from 'react-router-dom';
import { formatCurrencyForApp } from '../../utils/strings';

interface HomeComponentProps {
  app: App;
  appRevenue: number | undefined;
  appAffiliates: number | undefined;
  appReferrals: number | undefined;
  appARR: number | undefined;
  campaigns: Campaign[];
  token: string | undefined;
}

interface MetricCardProps {
  title: string;
  value: string | number | null;
  icon: LucideIcon;
}

const apiService = new ApiService();

const HomeComponent: React.FC<HomeComponentProps> = ({ app, appRevenue, appAffiliates, appReferrals, appARR, campaigns, token }) => {
  const maxEndDate = new Date();
  maxEndDate.setDate(maxEndDate.getDate() - 1);

  const [appGraphs, setAppGraphs] = useState<ConversionSumByDateObj[]>([]);
  const [startDate, setStartDate] = useState(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));
  const [endDate, setEndDate] = useState(maxEndDate);
  const [isGraphLoading, setIsGraphLoading] = useState(false);
  const [dateRangeError, setDateRangeError] = useState<string | null>(null);
  const [topAffiliates, setTopAffiliates] = useState<TopAffiliate[]>([]);
  const [isTopAffiliatesLoading, setIsTopAffiliatesLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (isValidDateRange()) {
      fetchGraphData();
    }
  }, [startDate, endDate]);

  useEffect(() => {
    fetchTopAffiliates();
  }, []);

  const isValidDateRange = () => {
    const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    if (diffDays > 60) {
      setDateRangeError("Date range cannot exceed 60 days.");
      return false;
    }
    if (endDate < startDate) {
      setDateRangeError("End date must be after start date.");
      return false;
    }
    setDateRangeError(null);
    return true;
  };

  const fetchGraphData = async () => {
    setIsGraphLoading(true);
    try {
      const formattedStartDate = startDate.toISOString().split('T')[0];
      const formattedEndDate = endDate.toISOString().split('T')[0];
      const data = await apiService.getAppGraphs(
        app.app_id, 
        formattedStartDate, 
        formattedEndDate, 
        token || "", 
      );
      // convert to target currency
      const convertedData = data.map(d => {
        d.conversion_sum = Number((d.conversion_sum * (app.currency_exchange_rate || 1)).toFixed(2));
        return d;
      })
      setAppGraphs(convertedData);
    } catch (error) {
      console.error('Error fetching graph data:', error);
    } finally {
      setIsGraphLoading(false);
    }
  };

  const fetchTopAffiliates = async () => {
    if (!token) return;
    setIsTopAffiliatesLoading(true);
    try {
      const data = await apiService.getTopAffiliates(app.app_id, token);
      setTopAffiliates(data);
    } catch (error) {
      console.error('Error fetching top affiliates:', error);
    } finally {
      setIsTopAffiliatesLoading(false);
    }
  };

  const MetricCard: React.FC<MetricCardProps> = ({ title, value, icon: Icon }) => (
    <Card className="mb-4">
      <Card.Body>
        <div className="d-flex align-items-center">
          <Icon size={48} className="me-3" color={COLORS.primary} />
          <div>
            <Card.Text>{title}</Card.Text>
            <h2 className="mb-0">
              {value === undefined ? (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                value
              )}
            </h2>
          </div>
        </div>
      </Card.Body>
    </Card>
  );

  return (
    <Container fluid className="mt-4">
      <Row>
        <Col md={3}>
          <MetricCard 
            title="Total Affiliation Revenue" 
            value={appRevenue !== undefined ? formatCurrencyForApp(app, appRevenue) : null} 
            icon={DollarSign} 
          />
        </Col>
        <Col md={3}>
          <MetricCard 
            title="Affiliates" 
            value={appAffiliates !== undefined ? appAffiliates.toLocaleString() : null} 
            icon={Users} 
          />
        </Col>
        <Col md={3}>
          <MetricCard 
            title="Referrals" 
            value={appReferrals !== undefined ? appReferrals.toLocaleString() : null} 
            icon={UserPlus} 
          />
        </Col>
        <Col md={3}>
          <MetricCard 
            title="Projected ARR" 
            value={appARR !== undefined ? formatCurrencyForApp(app, appARR) : null} 
            icon={BarChart2} 
          />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md={8}>
          <Card style={{ height: '500px' }}>
            <Card.Body>
              <Row className="mb-3">
                <Col md={8}>
                  <h6 className="">Revenue</h6>
                </Col>
                <Col md={4} className="d-flex align-items-center">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => date && setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={endDate}
                    className="form-control"
                  />
                  <span className="mx-2">-</span>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => date && setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    maxDate={new Date()}
                    className="form-control"
                  />
                </Col>
              </Row>
              {dateRangeError && <Alert variant="danger">{dateRangeError}</Alert>}
              {isGraphLoading ? (
                <div className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart data={appGraphs}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="conversion_date" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="conversion_sum" name="Revenue" fill={COLORS.primary} />
                  </BarChart>
                </ResponsiveContainer>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card style={{ height: '500px' }}>
            <Card.Body>
              <h6 className="mb-4">Top Affiliates</h6>
              {isTopAffiliatesLoading ? (
                <div className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <table className="table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Referrals</th>
                      <th>Revenue</th>
                    </tr>
                  </thead>
                  <tbody>
                    {topAffiliates.length > 0 ? (
                      topAffiliates.map((affiliate, index) => (
                        <tr key={index}>
                          <td>
                            <Link
                              to={`/dashboard/affiliates/${affiliate.affiliate_user_id}`}
                              className="text-decoration-none"
                              style={{ color: COLORS.primary, cursor: 'pointer' }}
                            >
                              {`${affiliate.affiliate_first_name} ${affiliate.affiliate_last_name}`}
                            </Link>
                          </td>
                          <td>{affiliate.referral_count}</td>
                          <td>{formatCurrencyForApp(app, affiliate.revenue)}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={3} className="text-center">No data available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md={3}>
          <Card className="mb-4">
            <Card.Body className="d-flex flex-column align-items-center">
              <FileText size={40} className="mb-4" color={COLORS.primary} />
              <Button onClick={() => navigate('/dashboard/payouts')} style={{ borderColor: COLORS.primary, backgroundColor: COLORS.primary }} variant="primary">Review Payouts</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="mb-4">
            <Card.Body className="d-flex flex-column align-items-center">
              <UserCheck size={40} className="mb-4" color={COLORS.primary} />
              <Button onClick={() => navigate('/dashboard/affiliates')} style={{ borderColor: COLORS.primary, backgroundColor: COLORS.primary }} variant="primary">Review Pending Applications</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="mb-4">
            <Card.Body className="d-flex flex-column align-items-center">
              <UserPlusIcon size={40} className="mb-4" color={COLORS.primary} />
              <Button onClick={() => navigate('/dashboard/affiliates')} style={{ borderColor: COLORS.primary, backgroundColor: COLORS.primary }} variant="primary">Add Affiliate</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="mb-4">
            <Card.Body className="d-flex flex-column align-items-center">
              <Flag size={40} className="mb-4" color={COLORS.primary} />
              <Button onClick={() => navigate('/dashboard/programs')} style={{ borderColor: COLORS.primary, backgroundColor: COLORS.primary }} variant="primary">Create New Program</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default HomeComponent;