import React from 'react';

const ComingSoonPage: React.FC = () => {
  return (
    <div className="d-flex align-items-center justify-content-center min-vh-100 bg-light">
      <div className="text-center">
        <h1 className="display-4 fw-light mb-4 text-secondary" style={{
          letterSpacing: '0.5em'
        }}>
          COMING SOON
        </h1>
        <p className="lead text-muted mb-4">
            Still Cooking..
        </p>
        <div className="mx-auto" style={{ width: '4rem', height: '2px', backgroundColor: '#6c757d' }}></div>
        <p className="lead text-muted mt-4" style={{ fontSize: '0.7rem' }}>
            Powered by Solara Labs, LLC
        </p>
      </div>
    </div>
  );
};

export default ComingSoonPage;
