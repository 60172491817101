import React, { useState, useEffect } from 'react';
import { COLORS } from '../../utils/constants';
import { CreateUpdateCampaignRequest } from '../../utils/service';
import { ChevronLeft, ChevronRight, MinusCircle } from 'lucide-react';

interface NewProgramProps {
  onCreateProgram: (programData: CreateUpdateCampaignRequest) => void;
  onCancel: () => void;
}

const NewProgramComponent: React.FC<NewProgramProps> = ({ onCreateProgram, onCancel }) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    campaign_name: '',
    campaign_description: undefined as string | undefined,
    campaign_tags: undefined as string | undefined,
    commission_type: 'percentage' as 'percentage' | 'fixed' | 'tiered',
    commission_value: undefined as number | undefined,
    tiered_commissions: {
      type: 'sales' as 'sales' | 'revenue',
      tiers: [{ min: 0, max: undefined as number | undefined, commission_type: 'percentage' as 'percentage' | 'fixed', value: undefined as number | undefined }]
    },
    referral_bonus: { threshold: undefined as number | undefined, amount: undefined as number | undefined, threshold_type: 'sales' as 'sales' | 'revenue' },
    payout_schedule: 'monthly',
    minimum_payout_threshold: undefined as number | undefined,
    payout_delay_days: 15,
    attribution_duration_days: 7,
  });

  const [errors, setErrors] = useState<{[key: string]: string}>({});

  useEffect(() => {
    const form = document.querySelector('form');
    if (form) {
      form.style.minHeight = '600px';
    }
  }, [step]);

  useEffect(() => {
    if (formData.payout_schedule === 'manual') {
      setFormData(prev => ({ ...prev, minimum_payout_threshold: undefined }));
    }
  }, [formData.payout_schedule]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value === '' ? undefined : value
    }));
    setErrors(prev => ({ ...prev, [name]: '' }));
  };

  const handleNumberInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value === '' ? undefined : Number(value)
    }));
    setErrors(prev => ({ ...prev, [name]: '' }));
  };

  const handleSelectNumberInputChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value === '' ? undefined : Number(value)
    }));
    setErrors(prev => ({ ...prev, [name]: '' }));
  };

  const handleTieredCommissionChange = (index: number, field: string, value: string) => {
    const updatedTiers = [...formData.tiered_commissions.tiers];
    const numValue = value === '' ? undefined : Number(value);

    updatedTiers[index] = { 
      ...updatedTiers[index], 
      [field]: numValue
    };

    setErrors(prev => ({ ...prev, [`tier_${index}_${field}`]: '' }));

    // Ensure the first tier always starts at 0
    if (index === 0) {
      updatedTiers[0].min = 0;
    }

    setFormData(prev => ({
      ...prev,
      tiered_commissions: {
        ...prev.tiered_commissions,
        tiers: updatedTiers
      }
    }));

    // If the max value of the last tier is changed from undefined/empty to a number, add a new tier
    if (field === 'max' && index === updatedTiers.length - 1 && numValue !== undefined) {
      setTimeout(() => addTier(), 0);
    }
  };

  const handleTieredTypeChange = (value: 'sales' | 'revenue') => {
    setFormData(prev => ({
      ...prev,
      tiered_commissions: {
        type: value,
        tiers: [{ min: 0, max: undefined, commission_type: 'percentage', value: undefined }]
      }
    }));
  };

  const addTier = () => {
    const tiers = [...formData.tiered_commissions.tiers];
    const lastTier = tiers[tiers.length - 1];
    const newMin = lastTier.max !== undefined ? lastTier.max + 1 : 0;
    tiers.push({ min: newMin, max: undefined, commission_type: 'percentage', value: undefined });
    setFormData(prev => ({
      ...prev,
      tiered_commissions: {
        ...prev.tiered_commissions,
        tiers
      }
    }));
  };

  const removeTier = (index: number) => {
    if (formData.tiered_commissions.tiers.length > 1) {
      const updatedTiers = formData.tiered_commissions.tiers.filter((_, i) => i !== index);
      setFormData(prev => ({
        ...prev,
        tiered_commissions: {
          ...prev.tiered_commissions,
          tiers: updatedTiers
        }
      }));
    }
  };

  const handleReferralBonusChange = (field: string, value: string) => {
    setFormData(prev => ({
      ...prev,
      referral_bonus: {
        ...prev.referral_bonus,
        [field]: field === 'threshold' || field === 'amount' ? (value === '' ? undefined : Number(value)) : value
      }
    }));
  };

  const validateStep = (currentStep: number) => {
    const newErrors: {[key: string]: string} = {};
    
    if (currentStep === 1) {
      if (!formData.campaign_name) newErrors.campaign_name = 'Program name is required';
    } else if (currentStep === 2) {
      if (formData.commission_type !== 'tiered' && formData.commission_value === undefined) {
        newErrors.commission_value = 'Commission value is required';
      }
      if (formData.commission_type === 'tiered') {
        if (formData.tiered_commissions.tiers.length === 0) {
          newErrors.tiered_commissions = 'At least one tier is required';
        } else {
          const tiers = formData.tiered_commissions.tiers;
          for (let i = 0; i < tiers.length; i++) {
            const tier = tiers[i];
            if (tier.value === undefined) {
              newErrors[`tier_${i}_value`] = 'Commission value is required';
            }
            if (i === 0 && tier.min !== 0) {
              newErrors[`tier_${i}_min`] = 'First tier minimum must be 0';
            }
            if (i < tiers.length - 1) {
              if (tier.max === undefined) {
                newErrors[`tier_${i}_max`] = 'Maximum value is required for all tiers except the last';
              } else if (tier.max <= tier.min) {
                newErrors[`tier_${i}_max`] = 'Maximum must be greater than minimum';
              }
              if (tier.max !== undefined && tiers[i + 1].min !== tier.max + 1) {
                newErrors[`tier_${i + 1}_min`] = 'Minimum must be previous tier maximum plus one';
              }
            }
          }
        }
      }
    } else if (currentStep === 3) {
      if (formData.payout_schedule === 'monthly' && formData.minimum_payout_threshold === undefined) newErrors.minimum_payout_threshold = 'Minimum payout threshold is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateStep(3)) return;
    let createCampaignRequest = formData as CreateUpdateCampaignRequest;
    if (createCampaignRequest.referral_bonus 
      && (createCampaignRequest.referral_bonus.amount === 0 || createCampaignRequest.referral_bonus.amount === undefined) 
      && (createCampaignRequest.referral_bonus.threshold === 0 || createCampaignRequest.referral_bonus.threshold === undefined)) {
      createCampaignRequest.referral_bonus = undefined;
    }
    if (createCampaignRequest.commission_value) {
      createCampaignRequest.tiered_commissions = undefined
    }
    onCreateProgram(createCampaignRequest);
  };

  const getCommissionTypeHint = () => {
    switch (formData.commission_type) {
      case 'percentage':
        return `Affiliates earn ${formData.commission_value !== undefined ? formData.commission_value : '_'}% of each sale they refer.`;
      case 'fixed':
        return `Affiliates earn $${formData.commission_value !== undefined ? formData.commission_value : '_'} for each sale they refer.`;
      case 'tiered':
        return 'Affiliate earnings vary based on performance tiers.';
      default:
        return '';
    }
  };

  const getTieredCommissionHint = () => {
    const tiers = formData.tiered_commissions.tiers;
    return tiers.map((tier, index) => {
      const range = index === tiers.length - 1 ? `${tier.min}+` : `${tier.min} - ${tier.max}`;
      const unit = formData.tiered_commissions.type === 'sales' ? 'sales' : 'revenue';
      return `${range} ${unit}: ${tier.value}${tier.commission_type === 'percentage' ? '%' : '$'} commission`;
    }).join(', ');
  };

  const getReferralBonusHint = () => {
    if (formData.referral_bonus.threshold === undefined || formData.referral_bonus.amount === undefined) {
      return 'Optional: Set a bonus for affiliates upon hitting a sales count or amount threshold.';
    }
    const thresholdType = formData.referral_bonus.threshold_type === 'sales' ? 'sales' : 'revenue';
    const thresholdUnit = formData.referral_bonus.threshold_type === 'sales' ? '' : '$';
    return `When an affiliate reaches ${thresholdUnit}${formData.referral_bonus.threshold} in ${thresholdType}, they receive a one time $${formData.referral_bonus.amount} bonus.`;
  };

  const getPayoutHint = () => {
    const schedule = formData.payout_schedule === 'manual' ? 'Manually' : 'Every month on 1st';
    const threshold = formData.payout_schedule === 'manual' ? '$0' : 
      (formData.minimum_payout_threshold !== undefined ? `$${formData.minimum_payout_threshold}` : '$0');
    const delay = formData.payout_delay_days ? `${formData.payout_delay_days} days old` : 'any age';
    return `${schedule} when an affiliate has more than ${threshold} of commissions that are ${delay}, they are paid${formData.payout_schedule === 'manual' ? ' upon your approval' : ' automatically'}.`;
  };

  const getAttributionDurationHint = () => {
    return `Sales are attributed to an affiliate for ${formData.attribution_duration_days} days after a user adds their referral code.`;
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <h5 className="mb-4">Basic Program Details</h5>
            <div className="mb-4">
              <label htmlFor="campaign_name" className="form-label">Program Name*</label>
              <input
                type="text"
                className={`form-control ${errors.campaign_name ? 'is-invalid' : ''}`}
                id="campaign_name"
                name="campaign_name"
                value={formData.campaign_name}
                onChange={handleInputChange}
                required
              />
              {errors.campaign_name && <div className="invalid-feedback">{errors.campaign_name}</div>}
              <small className="form-text text-muted">A unique name for your Program. Only you can see this.</small>
            </div>
            <div className="mb-4">
              <label htmlFor="campaign_description" className="form-label">Program Description</label>
              <textarea
                className="form-control"
                id="campaign_description"
                name="campaign_description"
                value={formData.campaign_description || ''}
                onChange={handleInputChange}
                rows={3}
              />
              <small className="form-text text-muted">Briefly describe your Program's purpose, goals, and any special instructions for affiliates.</small>
            </div>
            <div className="mb-4">
              <label htmlFor="campaign_tags" className="form-label">Tags</label>
              <input
                type="text"
                className="form-control"
                id="campaign_tags"
                name="campaign_tags"
                value={formData.campaign_tags || ''}
                onChange={handleInputChange}
              />
              <small className="form-text text-muted">Comma-separated tags for categorization (e.g., "Summer, Sale, Fashion").</small>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <h5 className="mb-4">Commission Structure</h5>
            <div className="mb-4">
              <label className="form-label">Commission Type*</label>
              <div className="btn-group d-flex" role="group">
                {[
                  { value: 'percentage', label: 'Percentage' },
                  { value: 'fixed', label: 'Fixed' },
                  { value: 'tiered', label: 'Tiered' }
                ].map((type) => (
                  <button
                    key={type.value}
                    type="button"
                    className={`btn btn-sm ${formData.commission_type === type.value ? 'btn-primary' : 'btn-outline-primary'}`}
                    onClick={() => {
                      setFormData(prev => ({ 
                        ...prev, 
                        commission_type: type.value as 'percentage' | 'fixed' | 'tiered',
                        commission_value: type.value === 'tiered' ? undefined : prev.commission_value
                      }))
                    }}
                    style={{ 
                      backgroundColor: formData.commission_type === type.value ? COLORS.primary : 'transparent',
                      borderColor: COLORS.primary,
                      color: formData.commission_type === type.value ? 'white' : COLORS.primary
                    }}
                  >
                    {type.label}
                  </button>
                ))}
              </div>
            </div>
            {formData.commission_type !== 'tiered' && (
              <div className="mb-4 ms-3" style={{ borderLeft: `2px solid ${COLORS.primary}`, paddingLeft: '15px'}}>
                <label htmlFor="commission_value" className="form-label">
                  {formData.commission_type === 'percentage' ? 'Commission Percentage*' : 'Fixed Rate Amount*'}
                </label>
                <div className="input-group">
                  {formData.commission_type === 'fixed' && <span className="input-group-text">$</span>}
                  <input
                    type="number"
                    className={`form-control ${errors.commission_value ? 'is-invalid' : ''}`}
                    id="commission_value"
                    name="commission_value"
                    value={formData.commission_value === undefined ? '' : formData.commission_value}
                    onChange={handleNumberInputChange}
                    step="0.01"
                    min="0"
                    required
                  />
                  {formData.commission_type === 'percentage' && <span className="input-group-text">%</span>}
                </div>
                {errors.commission_value && <div className="invalid-feedback">{errors.commission_value}</div>}
                <small className="form-text text-muted">{getCommissionTypeHint()}</small>
              </div>
            )}
            {formData.commission_type === 'tiered' && (
              <div className="mb-4 ms-3" style={{ borderLeft: `2px solid ${COLORS.primary}`, paddingLeft: '15px' }}>
                <label className="form-label">Tiered Commissions*</label>
                <div className="mb-3">
                  <label className="form-label">Tier Type</label>
                  <div className="btn-group d-flex" role="group">
                    {[
                      { value: 'sales', label: 'Sales Count' },
                      { value: 'revenue', label: 'Sales Amount' }
                    ].map((type) => (
                      <button
                        key={type.value}
                        type="button"
                        className={`btn btn-sm ${formData.tiered_commissions.type === type.value ? 'btn-primary' : 'btn-outline-primary'}`}
                        onClick={() => handleTieredTypeChange(type.value as 'sales' | 'revenue')}
                        style={{ 
                          backgroundColor: formData.tiered_commissions.type === type.value ? COLORS.primary : 'transparent',
                          borderColor: COLORS.primary,
                          color: formData.tiered_commissions.type === type.value ? 'white' : COLORS.primary
                        }}
                      >
                        {type.label}
                      </button>
                    ))}
                  </div>
                </div>
                {formData.tiered_commissions.tiers.map((tier, index) => (
                  <div key={index} className="row mb-3 align-items-end">
                    <div className="col-md-3">
                      <label className="form-label">Min</label>
                      <div className="input-group input-group-sm">
                        {formData.tiered_commissions.type === 'revenue' && <span className="input-group-text">$</span>}
                        <input
                          type="number"
                          className={`form-control ${errors[`tier_${index}_min`] ? 'is-invalid' : ''}`}
                          value={tier.min === undefined ? '' : tier.min}
                          onChange={(e) => handleTieredCommissionChange(index, 'min', e.target.value)}
                          readOnly={index === 0}
                        />
                        {formData.tiered_commissions.type === 'sales' && <span className="input-group-text">#</span>}
                      </div>
                      {errors[`tier_${index}_min`] && <div className="invalid-feedback">{errors[`tier_${index}_min`]}</div>}
                    </div>
                    <div className="col-md-3">
                      <label className="form-label">Max</label>
                      <div className="input-group input-group-sm">
                        {formData.tiered_commissions.type === 'revenue' && <span className="input-group-text">$</span>}
                        <input
                          type="number"
                          className={`form-control ${errors[`tier_${index}_max`] ? 'is-invalid' : ''}`}
                          value={tier.max === undefined ? '' : tier.max}
                          onChange={(e) => handleTieredCommissionChange(index, 'max', e.target.value)}
                          placeholder={index === formData.tiered_commissions.tiers.length - 1 ? '∞' : ''}
                        />
                        {formData.tiered_commissions.type === 'sales' && <span className="input-group-text">#</span>}
                      </div>
                      {errors[`tier_${index}_max`] && <div className="invalid-feedback">{errors[`tier_${index}_max`]}</div>}
                    </div>
                    <div className="col-md-3">
                      <label className="form-label">Comm. Type</label>
                      <select
                        className="form-select form-select-sm"
                        value={tier.commission_type}
                        onChange={(e) => handleTieredCommissionChange(index, 'commission_type', e.target.value as 'percentage' | 'fixed')}
                      >
                        <option value="percentage">Percentage</option>
                        <option value="fixed">Fixed</option>
                      </select>
                    </div>
                    <div className="col-md-2">
                      <label className="form-label">Comm. Value</label>
                      <div className="input-group input-group-sm">
                        {tier.commission_type === 'fixed' && <span className="input-group-text">$</span>}
                        <input
                          type="number"
                          className={`form-control ${errors[`tier_${index}_value`] ? 'is-invalid' : ''}`}
                          value={tier.value === undefined ? '' : tier.value}
                          onChange={(e) => handleTieredCommissionChange(index, 'value', e.target.value)}
                          step="0.01"
                        />
                        {tier.commission_type === 'percentage' && <span className="input-group-text">%</span>}
                      </div>
                      {errors[`tier_${index}_value`] && <div className="invalid-feedback">{errors[`tier_${index}_value`]}</div>}
                    </div>
                    {index !== 0 && (
                      <div className="col-md-1">
                        <button 
                          type="button" 
                          className="btn btn-sm btn-outline-danger" 
                          onClick={() => removeTier(index)}
                        >
                          <MinusCircle size={16} />
                        </button>
                      </div>
                    )}
                  </div>
                ))}
                {errors.tiered_commissions && <div className="invalid-feedback d-block mt-2">{errors.tiered_commissions}</div>}
                <small className="form-text text-muted mt-2">
                  {getTieredCommissionHint()}
                </small>
              </div>
            )}
            <div className="mb-4">
              <label className="form-label">Referral Bonus</label>
              <div className="row">
                <div className="col-md-4">
                  <div className="input-group input-group-sm">
                    {formData.referral_bonus.threshold_type === 'revenue' && <span className="input-group-text">$</span>}
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Threshold"
                      value={formData.referral_bonus.threshold === undefined ? '' : formData.referral_bonus.threshold}
                      onChange={(e) => handleReferralBonusChange('threshold', e.target.value)}
                    />
                    {formData.referral_bonus.threshold_type === 'sales' && <span className="input-group-text">#</span>}
                  </div>
                </div>
                <div className="col-md-4">
                  <select
                    className="form-select form-select-sm"
                    value={formData.referral_bonus.threshold_type}
                    onChange={(e) => handleReferralBonusChange('threshold_type', e.target.value as 'sales' | 'revenue')}
                  >
                    <option value="sales">Sales Count</option>
                    <option value="revenue">Sales Amount</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <div className="input-group input-group-sm">
                    <span className="input-group-text">$</span>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Bonus Amount"
                      value={formData.referral_bonus.amount === undefined ? '' : formData.referral_bonus.amount}
                      onChange={(e) => handleReferralBonusChange('amount', e.target.value)}
                      step="0.01"
                    />
                  </div>
                </div>
              </div>
              <small className="form-text text-muted">{getReferralBonusHint()}</small>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <h5 className="mb-4">Affiliate Settings & Payouts</h5>
            <div className="row">
              <div className="col-md-4">
                <label htmlFor="payout_schedule" className="form-label">Payout Schedule*</label>
                <select
                  className="form-select form-select-sm"
                  id="payout_schedule"
                  name="payout_schedule"
                  value={formData.payout_schedule}
                  onChange={handleInputChange}
                  required
                >
                  <option value="manual">Manual</option>
                  <option value="monthly">Monthly</option>
                </select>
              </div>
              {formData.payout_schedule === 'monthly' && (
                <div className="col-md-4">
                    <label htmlFor="minimum_payout_threshold" className="form-label">Minimum Payout Threshold*</label>
                    <div className="input-group input-group-sm">
                      <span className="input-group-text">$</span>
                      <input
                        type="number"
                        className={`form-control ${errors.minimum_payout_threshold ? 'is-invalid' : ''}`}
                        id="minimum_payout_threshold"
                        name="minimum_payout_threshold"
                        value={formData.minimum_payout_threshold === undefined ? '' : formData.minimum_payout_threshold}
                        onChange={handleNumberInputChange}
                        min="0"
                        step="0.01"
                        required
                      />
                    </div>
                    {errors.minimum_payout_threshold && <div className="invalid-feedback">{errors.minimum_payout_threshold}</div>}
                  </div>
               )}
              <div className="col-md-4">
                <label htmlFor="payout_delay_days" className="form-label">Payout Delay</label>
                <select
                  className="form-select form-select-sm"
                  id="payout_delay_days"
                  name="payout_delay_days"
                  value={formData.payout_delay_days}
                  onChange={handleSelectNumberInputChange}
                >
                  <option value="15">15 days</option>
                  <option value="30">30 days</option>
                </select>
              </div>
            </div>
            <small className="form-text text-muted d-block mb-4">{getPayoutHint()}</small>
            <div className="mb-4">
              <label htmlFor="attribution_duration_days" className="form-label">Attribution Duration*</label>
              <select
                className={`form-select form-select-sm ${errors.attribution_duration_days ? 'is-invalid' : ''}`}
                id="attribution_duration_days"
                name="attribution_duration_days"
                value={formData.attribution_duration_days}
                onChange={handleSelectNumberInputChange}
                required
              >
                <option value="7">7 days</option>
                <option value="15">15 days</option>
                <option value="30">30 days</option>
              </select>
              {errors.attribution_duration_days && <div className="invalid-feedback">{errors.attribution_duration_days}</div>}
              <small className="form-text text-muted">{getAttributionDurationHint()}</small>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <form onSubmit={handleSubmit} className="py-4" style={{ minHeight: '600px' }}>
      <div className="progress mb-4">
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: `${(step / 3) * 100}%`, backgroundColor: COLORS.primary }}
          aria-valuenow={(step / 3) * 100}
          aria-valuemin={0}
          aria-valuemax={100}
        ></div>
      </div>

      {renderStep()}

      <div className="d-flex justify-content-between mt-4">
        {step > 1 && (
          <button
            type="button"
            className="btn btn-sm btn-outline-secondary"
            onClick={() => setStep(step - 1)}
          >
            <ChevronLeft size={16} /> Previous
          </button>
        )}
        {step < 3 ? (
          <button
            type="button"
            className="btn btn-sm btn-primary ms-auto"
            onClick={() => {
              if (validateStep(step)) {
                setStep(step + 1);
              }
            }}
            style={{ backgroundColor: COLORS.primary, borderColor: COLORS.primary }}
          >
            Next <ChevronRight size={16} />
          </button>
        ) : (
          <button 
            type="submit" 
            className="btn btn-sm btn-primary ms-auto"
            style={{ backgroundColor: COLORS.primary, borderColor: COLORS.primary }}
          >
            Create Program
          </button>
        )}
      </div>
    </form>
  );
};

export default NewProgramComponent;