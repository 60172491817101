import React, { useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Card, Form } from 'react-bootstrap';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, FunnelChart, Funnel, LabelList, AreaChart, Area } from 'recharts';
import { DollarSign, Users, TrendingUp, BarChart2 } from 'lucide-react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import ApiService, { ConversionSumByDateObj, ConversionSumByTypeDateObj, ConversionFunnel, Campaign, App } from '../../utils/service';
import { COLORS } from '../../utils/constants';
import { capitalizeFirstLetter, formatCurrencyForApp } from '../../utils/strings';

const apiService = new ApiService();

const GRAPH_COLORS = {
    primary: "#4a154b",    // Original color
    lightest: "#9c4d9f",   // Much lighter, more saturated purple
    light: "#6d2170",      // Lighter purple
    medium: "#581c5c",     // Medium purple, slightly lighter than primary
    dark: "#3b1141",       // Darker purple
    darkest: "#2c0d31"     // Much darker purple
  };
  
interface AnalyticsViewProps {
  app: App;
  campaigns: Campaign[];
  token: string | undefined;
}

interface ProcessedRevenueData {
  conversion_date: string;
  [key: string]: string | number;
}

const AnalyticsView: React.FC<AnalyticsViewProps> = ({ app, campaigns, token }) => {
  const maxEndDate = new Date();
  maxEndDate.setDate(maxEndDate.getDate() - 1);
  
  const appId = app.app_id;

  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(null);
  const [startDate, setStartDate] = useState(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));
  const [endDate, setEndDate] = useState(maxEndDate);
  const [retentionDays, setRetentionDays] = useState(7);
  const [kpiData, setKpiData] = useState({
    totalRevenue: 0,
    mrr: 0,
    arr: 0,
    newSubscribers: 0,
    arpu: 0,
  });
  const [revenueData, setRevenueData] = useState<ConversionSumByDateObj[]>([]);
  const [revenueBreakdownData, setRevenueBreakdownData] = useState<ConversionSumByTypeDateObj[]>([]);
  const [conversionFunnelData, setConversionFunnelData] = useState<ConversionFunnel | null>(null);
  const [retentionData, setRetentionData] = useState<ConversionSumByDateObj[]>([]);
  const [churnData, setChurnData] = useState<ConversionSumByDateObj[]>([]);

  const maxRevenue = Math.max(...revenueData.map(item => item.conversion_sum));
  const yAxisDomain = [0, Math.ceil(maxRevenue * 1.1)]; // Extends the Y-axis by ~10% above the max value


  useEffect(() => {
    const defaultCampaign = campaigns.find(campaign => campaign.is_default) || campaigns[0];
    setSelectedCampaign(defaultCampaign);
  }, [campaigns]);

  useEffect(() => {
    if (selectedCampaign) {
      fetchData();
    }
  }, [selectedCampaign, startDate, endDate, retentionDays]);

  const fetchData = async () => {
    if (!selectedCampaign) return;

    const formattedStartDate = startDate.toISOString().split('T')[0];
    const formattedEndDate = endDate.toISOString().split('T')[0];

    try {
      const [
        totalRevenue,
        mrr,
        arr,
        newSubscribers,
        newUsers,
        revenueOverTime,
        revenueBreakdown,
        conversionFunnel,
        retention,
        churn
      ] = await Promise.all([
        apiService.getCampaignCounts(appId, selectedCampaign.campaign_id, 'revenue', token || ""),
        apiService.getCampaignCounts(appId, selectedCampaign.campaign_id, 'mrr', token || ""),
        apiService.getCampaignCounts(appId, selectedCampaign.campaign_id, 'arr', token || ""),
        apiService.getCampaignCounts(appId, selectedCampaign.campaign_id, 'subs', token || ""),
        apiService.getCampaignCounts(appId, selectedCampaign.campaign_id, 'users', token || ""),
        apiService.getCampaignGraphs(appId, selectedCampaign.campaign_id, 'revenue', formattedStartDate, formattedEndDate, token || ""),
        apiService.getCampaignGraphWithTypes(appId, selectedCampaign.campaign_id, 'revenue_source', formattedStartDate, formattedEndDate, token || ""),
        apiService.getCampaignConversionFunnel(appId, selectedCampaign.campaign_id, formattedStartDate, formattedEndDate, token || ""),
        apiService.getCampaignGraphs(appId, selectedCampaign.campaign_id, 'retention', formattedStartDate, formattedEndDate, token || "", retentionDays),
        apiService.getCampaignGraphs(appId, selectedCampaign.campaign_id, 'churn', formattedStartDate, formattedEndDate, token || "")
      ]);

      setKpiData({
        totalRevenue: totalRevenue.num,
        mrr: mrr.num,
        arr: arr.num,
        newSubscribers: newSubscribers.num,
        arpu: totalRevenue.num / newUsers.num || 0,
      });

      const convertedRevenueOverTime = revenueOverTime.map(d => {
        d.conversion_sum = Number((d.conversion_sum * (app.currency_exchange_rate || 1)).toFixed(2));
        return d;
      });
      setRevenueData(convertedRevenueOverTime);
      const convertedRevenueBreakdown = revenueBreakdown.map(d => {
        d.conversion_sum = Number((d.conversion_sum * (app.currency_exchange_rate || 1)).toFixed(2));
        return d;
      });
      setRevenueBreakdownData(convertedRevenueBreakdown);
      setConversionFunnelData(conversionFunnel);
      setRetentionData(retention);
      setChurnData(churn);
    } catch (error) {
      console.error('Error fetching analytics data:', error);
    }
  };

  const getRevenueTypeLabel = (type: string | null): string => {
    if (type === null || type === "null") {
        return "One Time Purchase"
    }
    switch (type) {
      default: return capitalizeFirstLetter(type);
    }
  };

  const getRevenueTypeColor = (type: string): string => {
    switch (type) {
      case "Monthly": return GRAPH_COLORS.light;
      case "Quarterly": return GRAPH_COLORS.medium;
      case "Yearly": return GRAPH_COLORS.dark;
      default: return GRAPH_COLORS.lightest;
    }
  };

  const processedRevenueData = useMemo(() => {
    const dataMap = new Map<string, ProcessedRevenueData>();
    
    revenueBreakdownData.forEach((item) => {
      if (!dataMap.has(item.conversion_date)) {
        dataMap.set(item.conversion_date, { conversion_date: item.conversion_date });
      }
      const entry = dataMap.get(item.conversion_date)!;
      const label = getRevenueTypeLabel(item.conversion_type);
      entry[label] = item.conversion_sum;
    });

    return Array.from(dataMap.values());
  }, [revenueBreakdownData]);

  const revenueTypes = useMemo(() => {
    const types = new Set<string>();
    revenueBreakdownData.forEach((item) => {
      const label = getRevenueTypeLabel(item.conversion_type);
      types.add(label);
    });
    return Array.from(types);
  }, [revenueBreakdownData]);

  const conversionStageData = useMemo(() => {
    if (!conversionFunnelData) return [];
    return [
      { name: 'Sign Ups', value: conversionFunnelData.user_count || 0 },
      { name: 'Trials', value: conversionFunnelData.trial_count || 0 },
      { name: 'New Subscribers', value: conversionFunnelData.subscriber_count || 0 },
    ];
  }, [conversionFunnelData]);

  const processedRetentionData = useMemo(() => {
    if (retentionData.length === 0) return [];

    const filledData = [];
    let lastKnownRate = 0;
    let currentDateObj = new Date(retentionData[0].conversion_date);
    const endDateObj = new Date(endDate);

    while (currentDateObj <= endDateObj) {
      const dateString = currentDateObj.toISOString().split('T')[0];
      const dataPoint = retentionData.find(d => d.conversion_date === dateString);

      if (dataPoint) {
        lastKnownRate = dataPoint.conversion_count;
      }

      filledData.push({
        conversion_date: dateString,
        retention_rate: lastKnownRate
      });

      currentDateObj.setDate(currentDateObj.getDate() + 1);
    }

    return filledData;
  }, [retentionData, endDate]);

  const KpiCard: React.FC<{ title: string; value: number | string | null; icon: React.ElementType }> = ({ title, value, icon: Icon }) => (
    <Card className="mb-4">
      <Card.Body>
        <div className="d-flex align-items-center">
          <Icon size={24} className="me-2" color={COLORS.primary} />
          <div>
            <Card.Text className="mb-0" style={{ fontSize: '0.8rem' }}>{title}</Card.Text>
            <h5 className="mb-0">
              {value}
            </h5>
          </div>
        </div>
      </Card.Body>
    </Card>
  );

  return (
    <Container fluid className="mt-4">
      <Row className="mb-4">
        <Col xs={8}>
          <div className="d-flex align-items-center">
            <BarChart2 size={24} className="me-2" color={COLORS.primary} />
            <h4 style={{ color: COLORS.text, fontWeight: 'bold', margin: 0 }}>Analytics</h4>
          </div>
        </Col>
        <Col xs={4}>
          <div className="d-flex justify-content-end align-items-center">
            <Form.Select 
              value={selectedCampaign?.campaign_id || ''}
              onChange={(e) => setSelectedCampaign(campaigns.find(c => c.campaign_id === e.target.value) || null)}
              style={{ width: 'auto' }}
            >
              {campaigns.map((campaign) => (
                <option key={campaign.campaign_id} value={campaign.campaign_id}>
                  {campaign.campaign_name}
                </option>
              ))}
            </Form.Select>
          </div>
        </Col>
      </Row>
      
      <Row className="mb-4">
        <Col>
          <KpiCard title="Total Revenue" value={formatCurrencyForApp(app, kpiData.totalRevenue)} icon={DollarSign} />
        </Col>
        <Col>
          <KpiCard title="MRR" value={formatCurrencyForApp(app, kpiData.mrr)} icon={TrendingUp} />
        </Col>
        <Col>
          <KpiCard title="ARR" value={formatCurrencyForApp(app, kpiData.arr)} icon={TrendingUp} />
        </Col>
        <Col>
          <KpiCard title="New Subscribers" value={kpiData.newSubscribers} icon={Users} />
        </Col>
        <Col>
          <KpiCard title="ARPU" value={formatCurrencyForApp(app, kpiData.arpu)} icon={DollarSign} />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col xs={6} className="d-flex">
              <h4>Insights</h4>
        </Col>
        <Col xs={6} className="d-flex justify-content-end">
          <div className="d-flex justify-content-end align-items-center">
            <DatePicker
              selected={startDate}
              onChange={(date) => date && setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              maxDate={maxEndDate}
              className="form-control form-control-sm me-2"
            />
            <span className="mx-2">-</span>
            <DatePicker
              selected={endDate}
              onChange={(date) => date && setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              maxDate={maxEndDate}
              className="form-control form-control-sm"
            />
          </div>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h6 className="mb-0">Cumulative Revenue Growth Over Time</h6>
              </div>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={revenueData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="conversion_date" />
                  <YAxis domain={yAxisDomain} />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="conversion_sum" name="Revenue" stroke={COLORS.primary} />
                </LineChart>
              </ResponsiveContainer>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md={6}>
          <Card>
            <Card.Body>
                <h6 className="mb-4">Revenue Breakdown</h6>
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={processedRevenueData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="conversion_date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {revenueTypes.map((type) => (
                        <Bar key={type} dataKey={type} name={type} fill={getRevenueTypeColor(type)} stackId="a" />
                    ))}
                    </BarChart>
                </ResponsiveContainer>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <Card.Body>
              <h6 className="mb-4">Conversion Stages</h6>
              <ResponsiveContainer width="100%" height={300}>
                <AreaChart data={conversionStageData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Area type="monotone" dataKey="value" name="Number of Users" fill={COLORS.primary} stroke={COLORS.primary} />
                </AreaChart>
              </ResponsiveContainer>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md={6}>
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h6 className="mb-0">Retention Rate</h6>
                <Form.Select 
                  value={retentionDays} 
                  onChange={(e) => setRetentionDays(Number(e.target.value))}
                  style={{ width: 'auto' }}
                >
                  <option value={1}>1 Day</option>
                  <option value={7}>7 Days</option>
                  <option value={30}>30 Days</option>
                </Form.Select>
              </div>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={processedRetentionData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="conversion_date" />
                  <YAxis domain={[0, 100]} tickFormatter={(value) => `${value}%`} />
                  <Tooltip formatter={(value) => `${value}%`} />
                  <Legend />
                  <Line type="stepAfter" dataKey="retention_rate" name="Retention Rate" stroke={COLORS.primary} />
                </LineChart>
              </ResponsiveContainer>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <Card.Body>
              <h6 className="mb-4">Churn Rate</h6>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={churnData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="conversion_date" />
                  <YAxis domain={[0, 100]} tickFormatter={(value) => `${value}%`} />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="conversion_count" name="Churn Rate" stroke={COLORS.text} />
                </LineChart>
              </ResponsiveContainer>
            </Card.Body>
          </Card>
        </Col>
      </Row>
  </Container>
);
};

export default AnalyticsView;