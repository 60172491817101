import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useAppData } from '../contexts/AppDataContext';
import { FileText, HeartIcon, HelpCircle, Home, Settings, Wallet } from 'lucide-react';
import AffiliateDashboardLayout from '../components/DashboardLayout';
import AffiliateAssetManagement from '../components/AssetManagement';
import AffiliateSettingManagement from '../components/SettingManagement';
import AffiliateHomeComponent from '../components/HomeComponent';
import { COLORS } from '../../utils/constants';
import AffiliatePayoutView from '../components/PaymentView';
import AffiliateSocialManagement from '../components/SocialManagement';

interface AffiliateDashboardPageProps {
  selectedMenuItem: string;
  selectedSubMenuItem?: string;
}

const AffiliateDashboardPage: React.FC<AffiliateDashboardPageProps> = ({ selectedMenuItem, selectedSubMenuItem }) => {
  const navigate = useNavigate();
  const { user, session, signOut } = useAuth();
  const { 
    app, affiliateAppData, token, isLoading,
    userCount, referralCount, commission, unpaidCommission,
    updateAffiliateAppData,
  } = useAppData();

  const menuGroups = [
    {
      title: "Overview",
      items: [
        { id: 'home', label: 'Home', icon: <Home size={18} color={app?.brand_text_color || COLORS.text} /> },
      ]
    },
    {
      title: "Management",
      items: [
        { id: 'assets', label: 'Assets', icon: <FileText size={18} color={app?.brand_text_color || COLORS.text} /> },
        { id: 'payouts', label: 'Payouts', icon: <Wallet size={18} color={app?.brand_text_color || COLORS.text} /> },
      ]
    },
    {
      title: "Configuration",
      items: [
        { id: 'socials', label: 'Socials', icon: <HeartIcon size={18} color={app?.brand_text_color || COLORS.text} /> },
        { id: 'settings', label: 'Settings', icon: <Settings size={18} color={app?.brand_text_color || COLORS.text} /> },
      ]
    },
    {
      title: "Support",
      items: [
        { id: 'help', label: 'Help', icon: <HelpCircle size={18} color={app?.brand_text_color || COLORS.text} /> },
      ]
    }
  ];

  const handleLogout = async () => {
    await signOut();
    navigate('/');
  };

  const renderContent = () => {
    if (isLoading || !app) {
      return <div className="d-flex justify-content-center align-items-center h-100">Loading...</div>;
    }

    if (selectedMenuItem === 'home') {
      return (
        <AffiliateHomeComponent 
          app={app} affiliateAppData={affiliateAppData!} token={token} 
          commission={commission} unpaidCommission={unpaidCommission} userCount={userCount} referralCount={referralCount} 
        />
      )
    } else if (selectedMenuItem === 'assets' && affiliateAppData?.status === "approved") {
      return <AffiliateAssetManagement app={app} affiliateAppData={affiliateAppData!} token={token} />;
    } else if (selectedMenuItem === 'payouts') {
      return <AffiliatePayoutView app={app} token={token} unpaidCommission={unpaidCommission} affiliateAppData={affiliateAppData!} />;
    } else if (selectedMenuItem === "socials") {
      return <AffiliateSocialManagement 
        app={app}
        token={token}
      />;
    } else if (selectedMenuItem === "settings") {
      return <AffiliateSettingManagement app={app} affiliateData={affiliateAppData!} updateAffiliateAppData={updateAffiliateAppData} />
    }

    if (affiliateAppData?.status !== "approved") {
      return (
        <div className="d-flex justify-content-center align-items-center min-vh-100">
          <div className="text-center mt-5">
            <p>Access not Granted</p>
          </div>
        </div>
      );  
    }

    return (
      <div>
        <p>Coming Soon</p>
      </div>
    );
  };

  if (!app) {
    return <div className="d-flex justify-content-center align-items-center h-100">Loading...</div>;
  }
  
  return (
    <AffiliateDashboardLayout
      app={app}
      userEmail={session?.user.email || ""}
      menuGroups={menuGroups}
      selectedMenuItem={selectedMenuItem}
      onLogout={handleLogout}
      disableSidebar={!app}
    >
      {renderContent()}
    </AffiliateDashboardLayout>
  );
}

export default AffiliateDashboardPage;
