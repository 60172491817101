import { TREMENDOUS_BASE_URL } from './constants';

export const createTremendousOAuth2URL = (): string => {
  const params = new URLSearchParams({
    client_id: process.env.REACT_APP_TREMENDOUS_CLIENT_ID || "",
    redirect_uri: process.env.REACT_APP_TREMENDOUS_REDIRECT_URL || "",
    scope: process.env.REACT_APP_TREMENDOUS_SCOPES || "",
    response_type: 'code',
  });

  return `${TREMENDOUS_BASE_URL}/oauth/authorize?${params.toString()}`;
};
