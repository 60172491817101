import React, { useState, FormEvent, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import axios, { AxiosError } from 'axios';
import { BASE_URL } from '../../utils/constants';

interface InternalTestingProps {
  appId: string;
}

const InternalTesting: React.FC<InternalTestingProps> = ({ appId }) => {
  // State for Create User form
  const [externalUserId, setExternalUserId] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const [createUserResult, setCreateUserResult] = useState<boolean | null>(null);
  const [createUserError, setCreateUserError] = useState<string | null>(null);

  // State for Revenue Event form
  const [eventExternalUserId, setEventExternalUserId] = useState('');
  const [productId, setProductId] = useState('com.subscription.monthly');
  const [eventType, setEventType] = useState('trial');
  const [revenueEventResult, setRevenueEventResult] = useState<boolean | null>(null);
  const [revenueEventError, setRevenueEventError] = useState<string | null>(null);
  const [infoText, setInfoText] = useState('');

  useEffect(() => {
    updateInfoText();
  }, [eventExternalUserId, productId, eventType]);

  const updateInfoText = () => {
    let price: string;
    let period: string;
    switch (productId) {
      case 'com.subscription.monthly':
        price = '$9.99';
        period = 'month';
        break;
      case 'com.subscription.weekly':
        price = '$2.99';
        period = 'week';
        break;
      case 'com.subscription.yearly':
        price = '$99.99';
        period = 'year';
        break;
      default:
        price = 'unknown price';
        period = 'unknown period';
    }

    switch (eventType) {
      case 'trial':
        setInfoText(`This will mock ${eventExternalUserId} starting a free trial.`);
        break;
      case 'purchase':
        setInfoText(`This will mock ${eventExternalUserId} starting a subscription of ${price} per ${period}.`);
        break;
      case 'renew':
        setInfoText(`This will mock ${eventExternalUserId} renewing their subscription of ${price} per ${period}.`);
        break;
      case 'cancel':
        setInfoText(`This will mock ${eventExternalUserId} cancelling their subscription.`);
        break;
      case 'refund':
        setInfoText(`This will mock ${eventExternalUserId} receiving a refund of ${price} for their ${period}ly subscription.`);
        break;
    }
  };

  const handleCreateUser = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const conversionUser: {
        external_user_id: string;
        name?: string;
        email?: string;
        phone?: string;
      } = {
        external_user_id: externalUserId,
      };
  
      // Only add properties if they are not empty strings
      if (name.trim() !== '') conversionUser.name = name.trim();
      if (email.trim() !== '') conversionUser.email = email.trim();
      if (phone.trim() !== '') conversionUser.phone = phone.trim();
  
      const requestBody = {
        conversion_user: conversionUser,
        code,
        platform: '',
      };
      const response = await axios.post(`${BASE_URL}/internal/testing/pubsub?appId=${appId}&eventType=newuser`, requestBody);
      setCreateUserResult(response.status === 200);
      setCreateUserError(null);
    } catch (error) {
      setCreateUserResult(false);
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          setCreateUserError(JSON.stringify(axiosError.response.data, null, 2));
        } else if (axiosError.request) {
          setCreateUserError('No response received from server');
        } else {
          setCreateUserError(axiosError.message);
        }
      } else {
        setCreateUserError('An unknown error occurred');
      }
    }
  };

  const handleRevenueEvent = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const currentTimestamp = Date.now();
    const transactionId = Math.random().toString(36).substring(2, 15);

    let periodType: string, currentEventType: string, price: number;
    switch (eventType) {
      case 'trial':
        periodType = 'TRIAL';
        currentEventType = 'INITIAL_PURCHASE';
        price = 0;
        break;
      case 'purchase':
        periodType = 'NORMAL';
        currentEventType = 'INITIAL_PURCHASE';
        price = productId === 'com.subscription.monthly' ? 9.99 : productId === 'com.subscription.weekly' ? 2.99 : 99.99;
        break;
      case 'renew':
        periodType = 'NORMAL';
        currentEventType = 'RENEWAL';
        price = productId === 'com.subscription.monthly' ? 9.99 : productId === 'com.subscription.weekly' ? 2.99 : 99.99;
        break;
      case 'cancel':
        periodType = 'NORMAL';
        currentEventType = 'CANCELLATION';
        price = 0;
        break;
      case 'refund':
        periodType = 'NORMAL';
        currentEventType = 'CANCELLATION';
        price = productId === 'com.subscription.monthly' ? -9.99 : productId === 'com.subscription.weekly' ? -2.99 : -99.99;
        break;
      default:
        throw new Error('Invalid event type');
    }

    const expirationDate = new Date(currentTimestamp);
    switch (productId) {
      case 'com.subscription.weekly':
        expirationDate.setDate(expirationDate.getDate() + 7);
        break;
      case 'com.subscription.monthly':
        expirationDate.setMonth(expirationDate.getMonth() + 1);
        break;
      case 'com.subscription.yearly':
        expirationDate.setFullYear(expirationDate.getFullYear() + 1);
        break;
    }

    const payload = {
      event: {
        event_timestamp_ms: currentTimestamp,
        product_id: productId,
        period_type: periodType,
        purchased_at_ms: currentTimestamp,
        expiration_at_ms: expirationDate.getTime(),
        environment: 'PRODUCTION',
        entitlement_id: null,
        entitlement_ids: ['pro'],
        presented_offering_id: null,
        transaction_id: transactionId,
        original_transaction_id: transactionId,
        is_family_share: false,
        country_code: 'US',
        app_user_id: eventExternalUserId,
        aliases: ['$RCAnonymousID:8069238d6049ce87cc529853916d624c'],
        original_app_user_id: '$RCAnonymousID:87c6049c58069238dce29853916d624c',
        currency: 'USD',
        price: price,
        price_in_purchased_currency: price,
        subscriber_attributes: {
          $email: {
            updated_at_ms: currentTimestamp,
            value: 'firstlast@gmail.com'
          }
        },
        store: 'APP_STORE',
        takehome_percentage: 0.7,
        type: currentEventType,
        id: '12345678-1234-1234-1234-123456789012',
        app_id: appId
      },
      api_version: '1.0'
    };

    try {
      const response = await axios.post(`${BASE_URL}/internal/testing/pubsub?appId=${appId}&eventType=revenuecat`, payload);
      setRevenueEventResult(response.status === 200);
      setRevenueEventError(null);
    } catch (error) {
      setRevenueEventResult(false);
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          setRevenueEventError(JSON.stringify(axiosError.response.data, null, 2));
        } else if (axiosError.request) {
          setRevenueEventError('No response received from server');
        } else {
          setRevenueEventError(axiosError.message);
        }
      } else {
        setRevenueEventError('An unknown error occurred');
      }
    }
  };

  return (
    <Container>
      <h2>Internal Testing Tool 🤫</h2>
      <Row className="mt-5">
        <Col md={6}>
          <h4>Add Referral User</h4>
          {createUserError && (
            <Alert variant="danger" className="mt-3 mb-3">
              {createUserError}
            </Alert>
          )}
          <Form onSubmit={handleCreateUser}>
            <Form.Group className="mb-3">
              <Form.Label>Referral Code</Form.Label>
              <Form.Control type="text" value={code} onChange={(e) => setCode(e.target.value)} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>External User ID</Form.Label>
              <Form.Control type="text" value={externalUserId} onChange={(e) => setExternalUserId(e.target.value)} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Name (Optional)</Form.Label>
              <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email (Optional)</Form.Label>
              <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Phone (Optional)</Form.Label>
              <Form.Control type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} />
            </Form.Group>
            <Button variant="primary" type="submit">Add Mock Referral</Button>
          </Form>
          {createUserResult !== null && !createUserError && (
            <Alert variant={createUserResult ? 'success' : 'danger'} className="mt-3">
              {createUserResult ? 'User created successfully' : 'Failed to create user'}
            </Alert>
          )}
        </Col>
        <Col md={6}>
          <h4>Set Subscription</h4>
          {revenueEventError && (
            <Alert variant="danger" className="mt-3 mb-3">
              {revenueEventError}
            </Alert>
          )}
          <Form onSubmit={handleRevenueEvent}>
            <Form.Group className="mb-3">
              <Form.Label>External User ID</Form.Label>
              <Form.Control type="text" value={eventExternalUserId} onChange={(e) => setEventExternalUserId(e.target.value)} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Product ID</Form.Label>
              <Form.Select value={productId} onChange={(e) => setProductId(e.target.value)}>
                <option value="com.subscription.monthly">Monthly</option>
                <option value="com.subscription.weekly">Weekly</option>
                <option value="com.subscription.yearly">Yearly</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Event Type</Form.Label>
              <Form.Select value={eventType} onChange={(e) => setEventType(e.target.value)}>
                <option value="trial">Trial</option>
                <option value="purchase">Purchase</option>
                <option value="renew">Renew</option>
                <option value="cancel">Cancel</option>
                <option value="refund">Refund</option>
              </Form.Select>
            </Form.Group>
            <p><i>{infoText}</i></p>
            <Button variant="primary" type="submit">Mock Subscription</Button>
          </Form>
          {revenueEventResult !== null && !revenueEventError && (
            <Alert variant={revenueEventResult ? 'success' : 'danger'} className="mt-3">
              {revenueEventResult ? 'Revenue event submitted successfully' : 'Failed to submit revenue event'}
            </Alert>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default InternalTesting;