import React, { useState, useEffect } from 'react';
import { Users, DollarSign, AlertCircle, Check, Wallet } from 'lucide-react';
import { COLORS } from '../../utils/constants';
import ApiService, { App, PayRequest, PendingPayout, ProcessedPayout, PayAffiliateData } from '../../utils/service';
import { Link } from 'react-router-dom';
import { Modal, Button, Nav } from 'react-bootstrap';
import { formatCurrencyForApp } from '../../utils/strings';

interface PayoutManagementProps {
  app: App;
  token: string | undefined;
}

const apiService = new ApiService();

const PayoutManagement: React.FC<PayoutManagementProps> = ({ app, token }) => {
  const [pendingPayouts, setPendingPayouts] = useState<PendingPayout[]>([]);
  const [processedPayouts, setProcessedPayouts] = useState<ProcessedPayout[]>([]);
  const [selectedPayouts, setSelectedPayouts] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState('pending');
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    loadPayouts();
  }, [app, token, activeTab]);

  const loadPayouts = async () => {
    setLoading(true);
    try {
      if (activeTab === 'pending') {
        const payouts = await apiService.getPendingPayouts(app.app_id, token || "");
        setPendingPayouts(payouts);
      } else {
        const payouts = await apiService.getProcessedPayouts(app.app_id, token || "");
        setProcessedPayouts(payouts);
      }
    } catch (error) {
      console.error(`Failed to fetch ${activeTab} payouts:`, error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectPayout = (affiliateUserId: string) => {
    if (selectedPayouts.includes(affiliateUserId)) {
      setSelectedPayouts(selectedPayouts.filter(selectedPayout => selectedPayout !== affiliateUserId));
      return
    }
    setSelectedPayouts(pendingPayouts
      .filter(p => !p.is_processing && p.ready_to_pay_amount > 0)
      .map(p => p.affiliate_user_id)
    );
  };

  const handleSelectAllPayouts = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedPayouts(pendingPayouts.filter(p => !p.is_processing && p.ready_to_pay_amount > 0).map(p => p.affiliate_user_id));
    } else {
      setSelectedPayouts([]);
    }
  };

  const handlePaySelected = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleMarkManualPayment = async () => {
    setIsProcessing(true);
    try {
      const payRequest: PayRequest = {
        payment_method: 'manual',
        affiliate_data: selectedPayoutDetails.map(payout => ({
          affiliate_user_id: payout.affiliate_user_id,
          affiliate_commission_ids: payout.affiliate_commission_ids
        }))
      };

      const response = await apiService.processPayment(app.app_id, payRequest, token || "");
      
      if (response.status) {
        // Payment successful
        setShowModal(false);
        setSelectedPayouts([]); // Reset selected payouts
        await loadPayouts(); // Refresh the payout data
      } else {
        // Handle error
        console.error("Manual payment processing failed");
        // You might want to show an error message to the user here
      }
    } catch (error) {
      console.error("Error processing manual payment:", error);
      // You might want to show an error message to the user here
    } finally {
      setIsProcessing(false);
    }
  };

  const handlePayViaTremendous = async () => {
    setIsProcessing(true);
    try {
      const payRequest: PayRequest = {
        payment_method: 'tremendous',
        affiliate_data: selectedPayoutDetails.map(payout => ({
          affiliate_user_id: payout.affiliate_user_id,
          affiliate_commission_ids: payout.affiliate_commission_ids
        }))
      };

      const response = await apiService.processPayment(app.app_id, payRequest, token || "");
      
      if (response.status) {
        // Payment successful
        setShowModal(false);
        setSelectedPayouts([]); // Reset selected payouts
        await loadPayouts(); // Refresh the payout data
      } else {
        // Handle error
        console.error("Tremendous payment processing failed");
        // You might want to show an error message to the user here
      }
    } catch (error) {
      console.error("Error processing Tremendous payment:", error);
      // You might want to show an error message to the user here
    } finally {
      setIsProcessing(false);
    }
  };

  const selectedPayoutDetails = pendingPayouts.filter(p => selectedPayouts.includes(p.affiliate_user_id));
  const totalReadyToPay = selectedPayoutDetails.reduce((sum, p) => sum + p.ready_to_pay_amount, 0);

  const isTremendousEnabled = !!(app.tremendous_campaign_id && app.tremendous_campaign_name);

  const renderPendingPayoutsTable = () => (
    <table className="table table-hover align-middle mb-0">
      <thead className="bg-light">
        <tr>
          <th className="py-3 px-4">
            <input
              type="checkbox"
              checked={selectedPayouts.length === pendingPayouts.filter(p => !p.is_processing).length}
              onChange={handleSelectAllPayouts}
              className="form-check-input"
            />
          </th>
          <th className="py-3 px-4"><Users size={16} className="me-1" /> Affiliate Name</th>
          <th className="py-3 px-4"><DollarSign size={16} className="me-1" /> Revenue Generated</th>
          <th className="py-3 px-4"><AlertCircle size={16} className="me-1" /> Unpaid</th>
          <th className="py-3 px-4"><Check size={16} className="me-1" /> Ready for Payout</th>
          <th className="py-3 px-4">Status</th>
        </tr>
      </thead>
      <tbody>
        {pendingPayouts.map((payout) => (
          <tr key={payout.affiliate_user_id}>
            <td className="py-3 px-4">
              <input
                type="checkbox"
                checked={selectedPayouts.includes(payout.affiliate_user_id)}
                onChange={() => handleSelectPayout(payout.affiliate_user_id)}
                className="form-check-input"
                disabled={payout.is_processing || payout.ready_to_pay_amount === 0}
              />
            </td>
            <td className="py-3 px-4">
              <Link 
                to={`/dashboard/affiliates/${payout.affiliate_user_id}`}
                className="text-decoration-none"
                style={{ color: COLORS.primary, cursor: 'pointer' }}
              >
                {`${payout.affiliate_first_name} ${payout.affiliate_last_name}`}
              </Link>
            </td>
            <td className="py-3 px-4">{formatCurrencyForApp(app, payout.revenue)}</td>
            <td className="py-3 px-4">{formatCurrencyForApp(app, payout.unpaid_amount)}</td>
            <td className="py-3 px-4">{formatCurrencyForApp(app, payout.ready_to_pay_amount)}</td>
            <td className="py-3 px-4">
              {payout.is_processing ? (
                <span className="badge bg-warning">Processing Payment</span>
              ) : payout.error_message ? (
                <span className="badge bg-danger">{payout.error_message}</span>
              ) : (
                <span className="badge bg-success">{payout.ready_to_pay_amount > 0 ? 'Ready to Pay' : 'No Pending Payments'}</span>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const renderProcessedPayoutsTable = () => (
    <table className="table table-hover align-middle mb-0">
      <thead className="bg-light">
        <tr>
          <th className="py-3 px-4"><Users size={16} className="me-1" /> Affiliate Name</th>
          <th className="py-3 px-4"><DollarSign size={16} className="me-1" /> Payout Amount</th>
          <th className="py-3 px-4">Payment Provider</th>
          <th className="py-3 px-4">Payment Date</th>
        </tr>
      </thead>
      <tbody>
        {processedPayouts.map((payout) => (
          <tr key={payout.affiliate_user_id}>
            <td className="py-3 px-4">
              <Link 
                to={`/dashboard/affiliates/${payout.affiliate_user_id}`}
                className="text-decoration-none"
                style={{ color: COLORS.primary, cursor: 'pointer' }}
              >
                {`${payout.affiliate_first_name} ${payout.affiliate_last_name}`}
              </Link>
            </td>
            <td className="py-3 px-4">{formatCurrencyForApp(app, payout.payout)}</td>
            <td className="py-3 px-4">{payout.payment_provider}</td>
            <td className="py-3 px-4">{new Date(payout.payment_date).toLocaleDateString()}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div className="container-fluid mt-4">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10 col-xl-9">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <div className="d-flex align-items-center">
              <Wallet size={24} className="me-2" color={COLORS.primary} />
              <h4 style={{ color: COLORS.text, fontWeight: 'bold' }}>Payouts</h4>
            </div>
            {activeTab === 'pending' && (
              <button 
                className="btn btn-primary"
                onClick={handlePaySelected}
                disabled={selectedPayouts.length === 0 || totalReadyToPay === 0}
              >
                Pay Selected ({formatCurrencyForApp(app, totalReadyToPay)})
              </button>
            )}
          </div>
          
          <Nav variant="tabs" className="mb-3">
            <Nav.Item>
              <Nav.Link 
                active={activeTab === 'pending'}
                onClick={() => setActiveTab('pending')}
              >
                Pending Payouts
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link 
                active={activeTab === 'processed'}
                onClick={() => setActiveTab('processed')}
              >
                Processed Payouts
              </Nav.Link>
            </Nav.Item>
          </Nav>

          {loading ? (
            <div className="text-center my-5">
              <div className="spinner-border" role="status" style={{ width: '3rem', height: '3rem', color: COLORS.primary }}>
                <span className="visually-hidden">Loading...</span>
              </div>
              <p className="mt-3" style={{ color: COLORS.textLight }}>Loading {activeTab} payouts...</p>
            </div>
          ) : (
            <div className="card shadow-sm" style={{ borderColor: COLORS.border, borderRadius: '10px' }}>
              <div className="card-body p-0">
                <div className="table-responsive" style={{ overflowX: 'auto' }}>
                  {activeTab === 'pending' ? renderPendingPayoutsTable() : renderProcessedPayoutsTable()}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            {selectedPayoutDetails.map((payout) => (
              <div key={payout.affiliate_user_id} className="d-flex justify-content-between mb-2">
                <span>{`${payout.affiliate_first_name} ${payout.affiliate_last_name}`}</span>
                <span>{formatCurrencyForApp(app, payout.ready_to_pay_amount)}</span>
              </div>
            ))}
          </div>
          <hr />
          <div className="d-flex justify-content-between font-weight-bold">
            <span>Total Payment</span>
            <span>{formatCurrencyForApp(app, totalReadyToPay)}</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button 
            variant="secondary" 
            onClick={handleMarkManualPayment}
            disabled={isProcessing}
          >
            {isProcessing ? 'Processing...' : 'Record Manual Payment'}
          </Button>
          <Button 
            variant="primary" 
            onClick={handlePayViaTremendous}
            disabled={!isTremendousEnabled || isProcessing}
          >
            {isProcessing ? 'Processing...' : 'Pay via Tremendous'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PayoutManagement;