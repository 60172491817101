import React, { useEffect, useState } from 'react';
import ApiService, { App, CreateUpdateAppRequest, TremendousCampaign } from '../../utils/service';
import { COLORS, PLATFORM_NAME } from '../../utils/constants';
import { Check, Link, RefreshCw, X } from 'lucide-react';
import { createTremendousOAuth2URL } from '../../utils/tremendous';
import { useLocation } from 'react-router-dom';
import TremendousCampaignModal from './TremendousCampaignModal';

interface IntegrationsProps {
  app: App;
  token: string | undefined;
  onUpdateApp: (updateRequest: CreateUpdateAppRequest, appId?: string) => Promise<void>;
  refreshApps: () => Promise<void>;
}

const apiService = new ApiService();

const Integrations: React.FC<IntegrationsProps> = ({ app, token, onUpdateApp, refreshApps }) => {
  const [copiedText, setCopiedText] = useState<string | null>(null);
  const [isStep1Open, setIsStep1Open] = useState(false);
  const [isStep2Open, setIsStep2Open] = useState(false);
  const [isStep3Open, setIsStep3Open] = useState(false);

  const [isConnectingTremendous, setIsConnectingTremendous] = useState(false);
  const [campaigns, setCampaigns] = useState<TremendousCampaign[]>([]);
  const [selectedCampaign, setSelectedCampaign] = useState<TremendousCampaign | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);

  const [isVerifyingWebhook, setIsVerifyingWebhook] = useState(false);
  const [webhookVerificationAttempted, setWebhookVerificationAttempted] = useState(false);
  const [isProcessingSDKLooksGood, setIsProcessingSDKLooksGood] = useState(false);

  const location = useLocation();
  const effectRan = React.useRef(false);

  useEffect(() => {
    if (effectRan.current === false) {
      const urlParams = new URLSearchParams(location.search);
      const code = urlParams.get('code');
  
      if (code && !isConnectingTremendous) {
        setIsConnectingTremendous(true);
        apiService.connectTremendousFetchCampaigns(app.app_id, code, token || "")
          .then((fetchedCampaigns) => {
            setCampaigns(fetchedCampaigns);
            setIsConnectingTremendous(false);
            setShowModal(true);
          })
          .catch((err) => {
            console.error('Error in OAuth flow:', err);
            setError('Failed to connect Tremendous account. Please try again.');
            setIsConnectingTremendous(false);
          });
      }
      
      effectRan.current = true;
    }
  }, [location, app.app_id, token, isConnectingTremendous]);

  useEffect(() => {
    if (webhookVerificationAttempted) {
      const timer = setTimeout(() => {
        setWebhookVerificationAttempted(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [webhookVerificationAttempted]);

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopiedText(text);
    setTimeout(() => setCopiedText(null), 2000);
  };

  const handleConnectTremendous = () => {
    const oauth2URL = createTremendousOAuth2URL();
    window.location.href = oauth2URL;
  };

  const handleCampaignSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = event.target.value;
    const campaign = campaigns.find(c => c.id === selectedId);
    setSelectedCampaign(campaign || null);
  };

  const handleSaveCampaign = async () => {
    if (selectedCampaign) {
      try {
        await onUpdateApp({
          tremendous_campaign_id: selectedCampaign.id,
          tremendous_campaign_name: selectedCampaign.name,
          app_name: app.app_name, 
          currency: app.currency,
        }, app.app_id);
        setShowModal(false);
      } catch (error) {
        console.error('Error saving campaign:', error);
        setError('Failed to save campaign. Please try again.');
      }
    }
  };

  const handleRefreshKey = async () => {
    const confirmRefresh = window.confirm("Are you sure you want to refresh the API key? This action cannot be undone and will invalidate the current key.");
    
    if (confirmRefresh) {
      try {
        await onUpdateApp({ 
          app_name: app.app_name, 
          currency: app.currency,
          update_api_key: true 
        }, app.app_id);
      } catch (error) {
        console.error("Error refreshing API key:", error);
        alert("Failed to refresh API key. Please try again.");
      }
    }
  };

  const WebhookSetting = ({ label, value, placeholder }: { label: string; value: string, placeholder?: string, }) => (
    <div className="mb-3">
      <label className="form-label">{label}</label>
      <div className="input-group">
        <input type="text" className="form-control" value={placeholder || value} readOnly />
        {
          !placeholder && value.length > 0 && (
            <button 
              className="btn btn-outline-secondary"
              onClick={() => copyToClipboard(value)}
            >
              {copiedText === value ? 'Copied!' : 'Copy'}
            </button>
          )
        }
      </div>
    </div>
  );

  const CodeBlock = ({ code }: { code: string }) => (
    <div className="position-relative bg-light p-3 rounded mb-3">
      <pre className="mb-0"><code>{code}</code></pre>
      <button 
        className="btn btn-sm btn-outline-secondary position-absolute top-0 end-0 m-2"
        onClick={() => copyToClipboard(code)}
      >
        {copiedText === code ? 'Copied!' : 'Copy'}
      </button>
    </div>
  );

  const handleVerifyWebhook = async () => {
    setIsVerifyingWebhook(true);
    try {
      await refreshApps();
    } catch (error) {
      console.error("Error verifying webhook:", error);
    } finally {
      setIsVerifyingWebhook(false);
      setWebhookVerificationAttempted(true);
    }
  };

  const handleLooskgood = async () => {
    setIsProcessingSDKLooksGood(true);
    try {
      // update step
      await onUpdateApp({
        app_name: app.app_name, 
        currency: app.currency,
        sdk_step_completed: true,
      }, app.app_id);
    } catch (error) {
      console.error("Error handling looksgood:", error);
    } finally {
      setIsProcessingSDKLooksGood(false);
    }
  };

  return (
    <div className="container mt-4">
      <div className="d-flex align-items-center mb-4">
        <Link size={24} className="me-2" color={COLORS.primary} />
        <h4 style={{ color: COLORS.text, fontWeight: 'bold', }}>Integrations</h4>
      </div>

      <div className="card mb-4">
        <div className="card-header">
          <h5 className="card-title">API Key</h5>
        </div>
        <div className="card-body">
          <div className="input-group mb-3">
            <input 
              type="text" 
              className="form-control"
              value={app.api_key || 'No API key available'} 
              readOnly
            />
            <button 
              className="btn btn-outline-secondary"
              onClick={() => copyToClipboard(app.api_key || '')}
            >
              {copiedText === app.api_key ? 'Copied!' : 'Copy'}
            </button>
            <button 
              className="btn btn-outline-danger"
              onClick={handleRefreshKey}
            >
              Refresh Key
            </button>
          </div>
          <small className="text-muted">Use this API key to authenticate requests to our SDK/API</small>
        </div>
      </div>

      <div className="accordion" id="integrationSteps">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button 
              className={`accordion-button ${!isStep1Open ? 'collapsed' : ''}`}
              type="button"
              onClick={() => setIsStep1Open(!isStep1Open)}
            >
              <span className="me-2">Step 1: Connect In-App Purchase Provider</span>
              {
                webhookVerificationAttempted && (
                  <span className="d-flex align-items-center ms-auto">
                    {app.is_revenuecat_connected ? (
                      <>
                        <Check className="text-success me-1" size={16} />
                        <small className="text-muted">Webhook Test Successful</small>
                      </>
                    ) : (
                      <>
                        <X className="text-danger me-1" size={16} />
                        <small className="text-muted">Webhook Test Unsuccessful</small>
                      </>
                    )}
                  </span>
                )
              }
              {
                !webhookVerificationAttempted && app.is_revenuecat_connected && (
                  <span className="d-flex align-items-center ms-auto">
                    <Check className="text-success me-1" size={16} />
                    <small className="text-muted">Webhook Connected</small>
                  </span>
                )
              }
            </button>
          </h2>
          <div className={`accordion-collapse collapse ${isStep1Open ? 'show' : ''}`}>
            <div className="accordion-body">
              <h6 className="mb-3">RevenueCat Webhook Configuration</h6>
              <p className="mb-3">
                Follow these steps to set up and verify your RevenueCat webhook:
              </p>
              <ol className="mb-3">
                <li className="mb-2">
                  Navigate to the webhooks section in your RevenueCat dashboard.
                  <a 
                    href="https://www.revenuecat.com/docs/webhooks" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="ms-2"
                  >
                    Learn more about RevenueCat webhooks
                  </a>
                </li>
                <li className="mb-2">Configure your webhook using the settings provided below.</li>
                <li className="mb-2">
                  Test your webhook connection by sending a test event on Revenuecat Dashboard.
                  <a 
                    href="https://www.revenuecat.com/docs/integrations/webhooks#testing" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="ms-2"
                  >
                    How to test your webhook
                  </a>
                </li>
                <li className="mb-2">
                  <button 
                    className="btn btn-sm btn-primary d-inline-flex align-items-center"
                    onClick={handleVerifyWebhook}
                    disabled={isVerifyingWebhook}
                  >
                    {isVerifyingWebhook ? (
                      <>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        Verifying...
                      </>
                    ) : (
                      <>
                        <RefreshCw size={14} className="me-2" />
                        Verify Webhook Connection
                      </>
                    )}
                  </button>
                </li>
              </ol>

              <div className="bg-light p-3 rounded mb-3">
                <WebhookSetting label="Webhook Name" value={`${app.app_name} webhook for ${PLATFORM_NAME}`} />
                <WebhookSetting 
                  label="Webhook URL" 
                  value={`https://sdk-gateway-b85kv8d1.ue.gateway.dev/revenuecat?appId=${app.app_id}`} 
                />
                <WebhookSetting 
                  label="Authorization Header Value" 
                  value=''
                  placeholder='Leave this field blank'
                />
                <WebhookSetting label="Environment" value='' placeholder="Production" />
                <WebhookSetting label="Event Filter" value='' placeholder="All Apps | All Events" />
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header">
            <button 
              className={`accordion-button ${!isStep2Open ? 'collapsed' : ''}`}
              type="button"
              onClick={() => setIsStep2Open(!isStep2Open)}
            >
              <span className="me-2">Step 2: Integrate your App with our SDK</span>
              {
                app.sdk_step_completed && (
                  <span className="d-flex align-items-center ms-auto">
                    <Check className="text-success me-1" size={16} />
                    <small className="text-muted">SDK Setup Completed</small>
                  </span>
                )
              }
            </button>
          </h2>
          <div className={`accordion-collapse collapse ${isStep2Open ? 'show' : ''}`}>
            <div className="accordion-body">
              <h6 className="mb-3">React Native SDK Integration</h6>
              <ol className="list-decimal pl-5">
                <li className="mb-4">
                  Install the SDK:
                  <CodeBlock code="npm install https://storage.googleapis.com/new-kinda-ads-assets/SDKs/new-kinda-ads-react-native-sdk-0.0.5.tgz" />
                </li>
                <li className="mb-4">
                  Import and initialize SDK in your Project:
                  <CodeBlock code="import { init, validateCode, registerUser } from 'new-kinda-ads-react-native-sdk';\m" />
                  <CodeBlock code="init(<YOUR_API_KEY>)" />
                </li>
                <li className="mb-2">
                  Follow for SDK docs, examples and more:
                  <a 
                    href="https://github.com/" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="ms-2"
                  >
                    Github Repo
                  </a>
                </li>
                <li className="mb-2">
                  <button 
                    className="btn btn-sm btn-primary d-inline-flex align-items-center"
                    onClick={handleLooskgood}
                    disabled={isProcessingSDKLooksGood || app.sdk_step_completed}
                  >
                    {
                      app.sdk_step_completed ? 'Completed' : 'Mark as Complete'
                    }
                    </button>
                </li>
              </ol>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header">
            <button 
              className={`accordion-button ${!isStep3Open ? 'collapsed' : ''}`}
              type="button"
              onClick={() => setIsStep3Open(!isStep3Open)}
            >
              <span className="me-2">Step 3: Connect Payout Provider</span>
              {app.tremendous_campaign_id && app.tremendous_campaign_name && (
                <span className="d-flex align-items-center ms-auto">
                  <Check className="text-success me-1" size={16} />
                  <small className="text-muted">"{app.tremendous_campaign_name}" Campaign Connected on Tremendous</small>
                </span>
              )}
            </button>
          </h2>
          <div className={`accordion-collapse collapse ${isStep3Open ? 'show' : ''}`}>
            <div className="accordion-body">
              {isConnectingTremendous ? (
                <div className="text-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  <p className="mt-2">Connecting to Tremendous...</p>
                </div>
              ) : (
                <>
                  <p className="mb-2">
                    Integrate with Tremendous to enable automated payouts for your affiliates and referrals. 
                    This integration allows for a wide range of payout options and seamless reconciliation with your affiliate tracking.
                  </p>
                  <button 
                    className="btn btn-primary mb-3"
                    onClick={handleConnectTremendous}
                  >
                    {app.tremendous_campaign_id && app.tremendous_campaign_name
                      ? 'Reconnect Tremendous Account'
                      : 'Connect Tremendous Account'}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <TremendousCampaignModal
        show={showModal}
        onHide={() => setShowModal(false)}
        campaigns={campaigns}
        selectedCampaign={selectedCampaign}
        onCampaignSelect={handleCampaignSelect}
        onSave={handleSaveCampaign}
        error={error}
      />
    </div>
  );
};

export default Integrations;
