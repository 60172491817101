import React, { CSSProperties } from 'react';

interface BubbleSelectProps {
  options: string[];
  selectedOptions: string[];
  onChange: (selected: string[]) => void;
  multiple?: boolean;
  styles: Record<string, CSSProperties>;
}

const BubbleSelect: React.FC<BubbleSelectProps> = ({ options, selectedOptions, onChange, multiple = true, styles }) => {
  const handleClick = (option: string) => {
    if (multiple) {
      const updated = selectedOptions.includes(option)
        ? selectedOptions.filter(item => item !== option)
        : [...selectedOptions, option];
      onChange(updated);
    } else {
      onChange([option]);
    }
  };

  return (
    <div style={styles.bubbleContainer}>
      {options.map(option => (
        <span
          key={option}
          style={{
            ...styles.bubble,
            ...(selectedOptions.includes(option) ? styles.bubbleSelected : styles.bubbleUnselected)
          }}
          onClick={() => handleClick(option)}
        >
          {option}
        </span>
      ))}
    </div>
  );
};

export default BubbleSelect;
