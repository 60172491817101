import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { DollarSign, Users, Percent, UserPlus, LucideIcon, FileText, MessageSquareIcon, Wallet, Code } from 'lucide-react';
import ApiService, { AffiliateAppData, ConversionSumByDateObj, SubdomainAppData } from '../../utils/service';
import { COLORS } from '../../utils/constants';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom';
import AffiliateProgramBanner from './ProgramBanner';
import { formatCurrency } from '../../utils/strings';

interface AffiliateHomeComponentProps {
  app: SubdomainAppData;
  affiliateAppData: AffiliateAppData,
  token: string | undefined;
  commission: number;
  unpaidCommission: number;
  userCount: number;
  referralCount: number;
}

interface MetricCardProps {
  title: string;
  value: string | number;
  icon: LucideIcon;
}

const apiService = new ApiService();

const AffiliateHomeComponent: React.FC<AffiliateHomeComponentProps> = ({
  app,
  affiliateAppData,
  token,
  commission,
  unpaidCommission,
  userCount,
  referralCount
}) => {
  const maxEndDate = new Date();
  maxEndDate.setDate(maxEndDate.getDate() - 1);

  const [affiliateGraphs, setAffiliateGraphs] = useState<ConversionSumByDateObj[]>([]);
  const [startDate, setStartDate] = useState(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));
  const [endDate, setEndDate] = useState(maxEndDate);
  const [isGraphLoading, setIsGraphLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      fetchGraphData();
    }
  }, [startDate, endDate, token]);

  const fetchGraphData = async () => {
    setIsGraphLoading(true);
    try {
      const formattedStartDate = startDate.toISOString().split('T')[0];
      const formattedEndDate = endDate.toISOString().split('T')[0];
      const data = await apiService.getAffiliateAppGraph(
        app.app_id,
        formattedStartDate,
        formattedEndDate,
        token || "",
      );
      // convert to target currency
      const convertedData = data.map(d => {
        d.conversion_sum = Number((d.conversion_sum * (affiliateAppData.currency_exchange_rate || 1)).toFixed(2));
        return d;
      })
      setAffiliateGraphs(convertedData);
    } catch (error) {
      console.error('Error fetching graph data:', error);
    } finally {
      setIsGraphLoading(false);
    }
  };

  const handleEmailClick = (event: React.MouseEvent) => {
    event.preventDefault();
    
    const email = app.affiliate_contact_email;
    const subject = `Re: ${app.app_name} Affiliate Program`;
    
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}`;
    
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      // Mobile device
      window.location.href = mailtoLink;
    } else {
      // Desktop
      window.open(mailtoLink, '_blank');
    }
  };

  const MetricCard: React.FC<MetricCardProps> = ({ title, value, icon: Icon }) => (
    <Card className="mb-4">
      <Card.Body>
        <div className="d-flex align-items-center">
          <Icon size={48} className="me-3" color={app.brand_color || COLORS.primary} />
          <div>
            <Card.Text>{title}</Card.Text>
            <h2 className="mb-0">{value}</h2>
          </div>
        </div>
      </Card.Body>
    </Card>
  );

  const conversionRate = userCount > 0 ? ((referralCount / userCount) * 100).toFixed(2) : '0.00';

  return (
    <Container fluid className="mt-4">
      {affiliateAppData.campaign_data && (
        <AffiliateProgramBanner app={app} campaignData={affiliateAppData.campaign_data} affiliateAppData={affiliateAppData!} />
      )}
      <Row>
        <Col md={3}>
          <MetricCard 
            title="Total Earnings" 
            value={formatCurrency(affiliateAppData, commission)}
            icon={DollarSign} 
          />
        </Col>
        <Col md={3}>
          <MetricCard 
            title="Pending Earnings" 
            value={formatCurrency(affiliateAppData, unpaidCommission)}
            icon={DollarSign} 
          />
        </Col>
        <Col md={3}>
          <MetricCard 
            title="Conversion Rate" 
            value={`${conversionRate}%`}
            icon={Percent} 
          />
        </Col>
        <Col md={3}>
          <MetricCard 
            title="Total Referrals" 
            value={referralCount.toLocaleString()}
            icon={UserPlus} 
          />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md={12}>
          <Card>
            <Card.Body>
              <Row className="mb-3">
                <Col md={8}>
                  <h6 className="">Revenue</h6>
                </Col>
                <Col md={4} className="d-flex align-items-center">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => date && setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={endDate}
                    className="form-control"
                  />
                  <span className="mx-2">-</span>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => date && setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    maxDate={new Date()}
                    className="form-control"
                  />
                </Col>
              </Row>
              {isGraphLoading ? (
                <div className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart data={affiliateGraphs}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="conversion_date" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="conversion_sum" name="Revenue" fill={app.brand_color || COLORS.primary} />
                  </BarChart>
                </ResponsiveContainer>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md={4}>
          <Card className="mb-4 h-100">
            <Card.Body className="d-flex flex-column align-items-center justify-content-center">
              <Code size={30} className="mb-4" color={app.brand_color || COLORS.primary} />
              <Button 
                onClick={() => navigate('/dashboard/assets')}
                style={{ 
                  borderColor: app.brand_color || COLORS.primary, 
                  backgroundColor: app.brand_color || COLORS.primary,
                  width: '70%'
                }} 
                variant="primary"
              >
                View Referral Code
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mb-4 h-100">
            <Card.Body className="d-flex flex-column align-items-center justify-content-center">
              <Wallet size={30} className="mb-4" color={app.brand_color || COLORS.primary} />
              <Button 
                onClick={() => navigate('/dashboard/payouts')}
                style={{ 
                  borderColor: app.brand_color || COLORS.primary, 
                  backgroundColor: app.brand_color || COLORS.primary,
                  width: '70%'
                }} 
                variant="primary"
              >
                Check Pending Payouts
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mb-4 h-100">
            <Card.Body className="d-flex flex-column align-items-center justify-content-center">
              <MessageSquareIcon size={30} className="mb-4" color={app.brand_color || COLORS.primary} />
              <Button 
                onClick={handleEmailClick}
                disabled={!app.affiliate_contact_email}
                style={{ 
                  borderColor: app.brand_color || COLORS.primary, 
                  backgroundColor: app.brand_color || COLORS.primary,
                  width: '70%'
                }} 
                variant="primary"
              >
                Contact {app.app_name} Team
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AffiliateHomeComponent;