import React, { useState, useRef, useEffect } from 'react';
import { COLORS, currencies } from '../../utils/constants';
import { App, CreateUpdateAppRequest } from '../../utils/service';
import { Settings } from 'lucide-react';

interface NewAppProps {
  onSubmit: (app: CreateUpdateAppRequest, appId?: string) => Promise<void>;
  appInput?: App;
}

const NewEditApp: React.FC<NewAppProps> = ({ onSubmit, appInput }) => {
  const [appData, setAppData] = useState<CreateUpdateAppRequest>({
    app_name: '',
    brand_color: COLORS.primary,
    currency: 'USD',
  });
  const [appLogo, setAppLogo] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (appInput) {
      setAppData({
        app_name: appInput.app_name,
        subdomain: appInput.subdomain,
        brand_color: appInput.brand_color || COLORS.primary,
        currency: appInput.currency,
        affiliate_contact_email: appInput.affiliate_contact_email,
      });
      if (appInput.brand_url) {
        setImagePreview(appInput.brand_url);
      }
    }
  }, [appInput]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    if (type === 'file') {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (file && file.size <= 2 * 1024 * 1024) { // 2MB limit
        setAppLogo(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result as string);
        };
        reader.readAsDataURL(file);
      } else {
        alert('Please select an image file up to 2MB in size.');
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      }
    } else {
      setAppData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null); // Clear any previous errors
    const data: CreateUpdateAppRequest = { ...appData };
    
    try {
      if (appLogo) {
        const reader = new FileReader();
        reader.onloadend = async () => {
          const base64String = reader.result as string;
          // Remove the Data URL scheme prefix
          const cleanedBase64 = base64String.replace(/^data:image\/\w+;base64,/, '');
          data.brand_image_bytestring = cleanedBase64;
          await onSubmit(data, appInput?.app_id);
        };
        reader.readAsDataURL(appLogo);
      } else {
        await onSubmit(data, appInput?.app_id);
      }
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
    }
  };

  return (
    <div className="container mt-4">
      <div className="d-flex align-items-center mb-4">
        <Settings size={24} className="me-2" color={COLORS.primary} />
        <h4 style={{ color: COLORS.text, fontWeight: 'bold', }}>App Settings</h4>
      </div>
      <form onSubmit={handleSubmit} className="max-w-md mx-auto" >
        <div className="mb-3">
          <label htmlFor="app_name" className="form-label">App Name*</label>
          <input 
            type="text" 
            className="form-control"
            id="app_name" 
            name="app_name" 
            value={appData.app_name} 
            onChange={handleInputChange} 
            required 
          />
        </div>
        <div className="mb-3">
          <label htmlFor="subdomain" className="form-label">Alias*</label>
          <div className="input-group">
            <input 
              type="text" 
              className="form-control"
              id="subdomain" 
              name="subdomain" 
              value={appData.subdomain} 
              onChange={handleInputChange} 
              required 
              placeholder="your-alias"
            />
            <span className="input-group-text">.shinara.io</span>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="app_logo" className="form-label">App Logo</label>
          {imagePreview && (
            <div className="mb-2">
              <img src={imagePreview} alt="App Logo Preview" className="img-thumbnail" style={{maxWidth: '100px', maxHeight: '100px'}} />
            </div>
          )}
          <input 
            type="file" 
            className="form-control"
            id="app_logo" 
            name="app_logo" 
            onChange={handleInputChange} 
            accept="image/*"
            ref={fileInputRef}
          />
          <small className="form-text text-muted">Select an image file up to 2MB in size.</small>
        </div>
        <div className="mb-3">
          <label htmlFor="brand_color" className="form-label">Brand Color</label>
          <input 
            type="color" 
            className="form-control form-control-color"
            id="brand_color" 
            name="brand_color" 
            value={appData.brand_color} 
            onChange={handleInputChange} 
          />
        </div>
        <div className="mb-3">
          <label htmlFor="brand_text_color" className="form-label">Brand Text Color</label>
          <input 
            type="color" 
            className="form-control form-control-color"
            id="brand_text_color" 
            name="brand_text_color" 
            value={appData.brand_text_color} 
            onChange={handleInputChange} 
          />
        </div>
        <div className="mb-3">
          <label htmlFor="currency" className="form-label">Currency</label>
          <select
            className="form-select"
            id="currency"
            name="currency"
            value={appData.currency}
            onChange={handleInputChange}
            required
          >
            {currencies.map(currency => (
              <option key={currency.code} value={currency.code}>{currency.name}</option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="affiliate_contact_email" className="form-label">Affiliate Contact Email Address</label>
          <input 
            className="form-control"
            type="email" 
            id="affiliate_contact_email" 
            name="affiliate_contact_email" 
            value={appData.affiliate_contact_email} 
            onChange={handleInputChange} 
          />
        </div>
        <div className="text-center mt-4">
          <button type="submit" className="btn btn-primary">{appInput ? 'Update' : 'Save'}</button>
        </div>
        {error && (
          <div className="alert alert-danger mt-3" role="alert">
            {error}
          </div>
        )}
      </form>
    </div>
  );
};

export default NewEditApp;