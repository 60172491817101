import React, { useState, useEffect } from 'react';
import { User, Mail, Users, Calendar, Tag, Info, Download, DollarSign, TrendingUp, Users as UsersIcon, CreditCard, ChevronRight, ChevronLeft, Check, UserPlus, Copy, Share2 } from 'lucide-react';
import { COLORS } from '../../utils/constants';
import ApiService, { Affiliate, AffiliateResponse, App, Campaign, PaginationData, UpdateAffiliateStatusRequest } from '../../utils/service';
import { useNavigate, Link } from 'react-router-dom';
import { getCampaignName } from '../../utils/campaign';
import { formatCurrencyForApp } from '../../utils/strings';

interface AffiliateManagementProps {
  campaigns: Campaign[];
  app: App;
  token: string | undefined;
}

interface AffiliateWithCounts extends Affiliate {
  newUsers: number | null;
  totalRevenue: number | null;
  newARR: number | null;
  referrals: number | null;
  totalCommissions: number | null;
  countsLoading: boolean;
}

const apiService = new ApiService();

const AffiliateManagement: React.FC<AffiliateManagementProps> = ({ campaigns, app, token }) => {
  const [affiliates, setAffiliates] = useState<AffiliateWithCounts[]>([]);
  const [selectedCampaignId, setSelectedCampaignId] = useState<string | undefined>(undefined);
  const [selectedAffiliates, setSelectedAffiliates] = useState<string[]>([]);
  const [pagination, setPagination] = useState<PaginationData>({
    current_page: 1,
    page_size: 50,
    total_count: 0,
    total_pages: 0,
    has_next_page: false,
    has_prev_page: false,
  });
  const [loading, setLoading] = useState(true);
  const appId = app.app_id

  const [showInviteModal, setShowInviteModal] = useState(false);
  const [copyButtonText, setCopyButtonText] = useState('Copy');
  const inviteLink = `https://${app.subdomain}.shinara.io`;


  const navigate = useNavigate();

  useEffect(() => {
    loadAffiliates(pagination.current_page, pagination.page_size, selectedCampaignId);
  }, [selectedCampaignId, appId]);

  const loadAffiliates = async (page: number, pageSize: number, campaignId: string | undefined) => {
    setLoading(true);
    try {
      const response = await apiService.getAffiliates(appId, page, pageSize, token || "", campaignId);
      const affiliatesWithInitialCounts = response.affiliates.map(affiliate => ({
        ...affiliate,
        newUsers: null,
        totalRevenue: null,
        newARR: null,
        referrals: null,
        totalCommissions: null,
        countsLoading: true,
      }));
      setAffiliates(affiliatesWithInitialCounts);
      setPagination(response.pagination);
      
      // Fetch counts for each affiliate
      affiliatesWithInitialCounts.forEach(affiliate => {
        fetchAffiliateCounts(affiliate.affiliate_user_id);
      });
    } catch (error) {
      console.error('Failed to fetch affiliates:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAffiliateCounts = async (affiliateUserId: string) => {
    const countTypes = ['users', 'revenue', 'arr', 'referrals', 'commission'];
    const counts = await Promise.all(
      countTypes.map(async (countType) => {
        try {
          const response = await apiService.getAffiliateCounts(appId, affiliateUserId, countType, token || "");
          return { [countType]: response.num };
        } catch (error) {
          console.error(`Failed to fetch ${countType} count:`, error);
          return { [countType]: null };
        }
      })
    );

    setAffiliates(prevAffiliates => 
      prevAffiliates.map(affiliate => 
        affiliate.affiliate_user_id === affiliateUserId
          ? {
              ...affiliate,
              newUsers: counts[0].users,
              totalRevenue: counts[1].revenue,
              newARR: counts[2].arr,
              referrals: counts[3].referrals,
              totalCommissions: counts[4].commission,
              countsLoading: false,
            }
          : affiliate
      )
    );
  };

  const handlePageChange = (newPage: number) => {
    loadAffiliates(newPage, pagination.page_size, selectedCampaignId);
  };

  const handleCampaignChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCampaignId(event.target.value === 'all' ? undefined : event.target.value);
    setPagination(prevState => ({ ...prevState, current_page: 1 }));
  };

  const formatDate = (dateString: string): string => {
    return new Date(dateString).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
  };

  const renderAffiliateInfo = (affiliate: Affiliate) => {
    const infoPills = [];
    if (affiliate.country) infoPills.push({ label: affiliate.country, color: 'primary' });
    if (affiliate.birthdate) infoPills.push({ label: affiliate.birthdate, color: 'primary' });
    if (affiliate.gender) infoPills.push({ label: affiliate.gender, color: 'success' });
    if (affiliate.device_type) infoPills.push({ label: affiliate.device_type, color: 'info' });
    if (affiliate.languages) infoPills.push({ label: affiliate.languages, color: 'warning' });

    return (
      <div className="d-flex flex-wrap gap-1">
        {infoPills.map((pill, index) => (
          <span key={index} className={`badge bg-${pill.color} rounded-pill`}>{pill.label}</span>
        ))}
      </div>
    );
  };

  const handleUpdateStatuses = async (newStatus: string) => {
    const updateRequest: UpdateAffiliateStatusRequest = {
      affiliate_ids: selectedAffiliates,
      status: newStatus,
      parent_campaign_id: selectedCampaignId
    };

    try {
      const response = await apiService.updateAffiliateStatuses(appId, updateRequest, token || "");
      if (response.status) {
        // Refresh the affiliates list
        loadAffiliates(pagination.current_page, pagination.page_size, selectedCampaignId);
        // Clear selections
        setSelectedAffiliates([]);
      } else {
        console.error('Failed to update affiliate statuses');
      }
    } catch (error) {
      console.error('Error updating affiliate statuses:', error);
    }
  };

  const getStatusBadge = (status: string) => {
    const statusColors = {
      approved: 'success',
      rejected: 'danger',
      pending: 'warning'
    };
    const statusTitle = {
      approved: 'Enrolled',
      rejected: 'Removed',
      pending: 'Pending'
    };
    const color = statusColors[status as keyof typeof statusColors] || 'secondary';
    return <span className={`badge bg-${color}`}>{statusTitle[status as keyof typeof statusTitle] || 'Pending'}</span>;
  };

  const handleSelectAffiliate = (affiliateUserId: string) => {
    setSelectedAffiliates(prev => 
      prev.includes(affiliateUserId)
        ? prev.filter(id => id !== affiliateUserId)
        : [...prev, affiliateUserId]
    );
  };

  const handleSelectAllAffiliates = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedAffiliates(affiliates.map(a => a.affiliate_user_id));
    } else {
      setSelectedAffiliates([]);
    }
  };

  const renderCountCell = (value: number | null, prefix: string = '') => {
    if (value === null) {
      return <div className="spinner-border spinner-border-sm" role="status" />;
    }
    return `${prefix}${value.toFixed(2)}`;
  };

  const handleInviteClick = () => {
    setShowInviteModal(true);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(inviteLink).then(() => {
      setCopyButtonText('Copied!');
      setTimeout(() => setCopyButtonText('Copy'), 1000); // Reset after 1 seconds
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  };

  const handleCloseModal = () => {
    setShowInviteModal(false);
  };

  const handleShareLink = () => {
    if (navigator.share) {
      navigator.share({
        title: `Join ${app.app_name}'s Affilite Program`,
        url: inviteLink,
      }).catch((error) => console.log('Error sharing', error));
    } else {
      console.log('Web Share API not supported');
      // Fallback behavior if Web Share API is not supported
    }
  };

  return (
    <div className="container-fluid mt-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div className="d-flex align-items-center">
          <Users size={24} className="me-2" color={COLORS.primary} />
          <h4 style={{ color: COLORS.text, fontWeight: 'bold', }}>Affiliates</h4>
        </div>
        <div className="d-flex align-items-center">
          {selectedAffiliates.length > 0 && (
            <div className="btn-group me-3"> {/* Added me-3 for margin */}
              <button className="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                Actions ({selectedAffiliates.length})
              </button>
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" href="#" onClick={() => handleUpdateStatuses('approved')}>Accept</a></li>
                <li><a className="dropdown-item" href="#" onClick={() => handleUpdateStatuses('rejected')}>Remove</a></li>
              </ul>
            </div>
          )}
          <select 
            className="form-select me-2" 
            value={selectedCampaignId || 'all'} 
            onChange={handleCampaignChange}
          >
            <option value="all">All Campaigns</option>
            {campaigns.map((campaign) => (
              <option key={campaign.campaign_id} value={campaign.campaign_id}>
                {campaign.campaign_name}
              </option>
            ))}
          </select>
          <button
            className="btn btn-primary me-3"
            onClick={handleInviteClick}
          >
            Invite
          </button>
        </div>
      </div>
      {loading ? (
        <div className="text-center my-5">
          <div className="spinner-border" role="status" style={{ width: '3rem', height: '3rem', color: COLORS.primary }}>
            <span className="visually-hidden">Loading...</span>
          </div>
          <p className="mt-3" style={{ color: COLORS.textLight }}>Loading affiliates...</p>
        </div>
      ) : (
        <div className="card shadow-sm" style={{ borderColor: COLORS.border, borderRadius: '10px' }}>
          <div className="card-body p-0">
            <div className="table-responsive" style={{ overflowX: 'auto' }}>
              <table className="table table-hover align-middle mb-0">
                <thead className="bg-light">
                  <tr>
                    <th className="py-3">
                      <input
                        type="checkbox"
                        checked={selectedAffiliates.length === affiliates.length}
                        onChange={handleSelectAllAffiliates}
                        className="form-check-input"
                      />
                    </th>
                    <th className="py-3"><User size={16} className="me-1" /> Affiliate Name</th>
                    <th className="py-3"><Mail size={16} className="me-1" /> Email</th>
                    <th className="py-3"><Users size={16} className="me-1" /> Program</th>
                    <th className="py-3"><Calendar size={16} className="me-1" /> Join Date</th>
                    <th className="py-3"><Tag size={16} className="me-1" /> Promo Code</th>
                    <th className="py-3"><Info size={16} className="me-1" /> Affiliate Info</th>
                    <th className="py-3"><Download size={16} className="me-1" /> New Users</th>
                    <th className="py-3"><DollarSign size={16} className="me-1" /> Total Revenue</th>
                    <th className="py-3"><TrendingUp size={16} className="me-1" /> New ARR</th>
                    <th className="py-3"><UsersIcon size={16} className="me-1" /> # of Referrals</th>
                    <th className="py-3"><CreditCard size={16} className="me-1" /> Total Commissions</th>
                    <th className="py-3">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {affiliates.map((affiliate) => (
                    <tr key={affiliate.affiliate_user_id}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedAffiliates.includes(affiliate.affiliate_user_id)}
                          onChange={() => handleSelectAffiliate(affiliate.affiliate_user_id)}
                          className="form-check-input"
                        />
                      </td>
                      <td>
                      <Link 
                        to={`/dashboard/affiliates/${affiliate.affiliate_user_id}`}
                        className="text-decoration-none"
                        style={{ color: COLORS.primary, cursor: 'pointer' }}
                      >
                        {`${affiliate.first_name} ${affiliate.last_name}`}
                      </Link>
                    </td>
                      <td>{affiliate.email || 'N/A'}</td>
                      <td>{getCampaignName(campaigns, affiliate.parent_campaign_id)}</td>
                      <td>{formatDate(affiliate.created_at)}</td>
                      <td>{affiliate.code || 'N/A'}</td>
                      <td>{renderAffiliateInfo(affiliate)}</td>
                      <td>{affiliate.newUsers}</td>
                      <td>{formatCurrencyForApp(app, affiliate.totalRevenue || 0)}</td>
                      <td>{formatCurrencyForApp(app, affiliate.newARR || 0)}</td>
                      <td>{affiliate.referrals}</td>
                      <td>{formatCurrencyForApp(app, affiliate.totalCommissions || 0)}</td>
                      <td>{getStatusBadge(affiliate.status || 'pending')}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      <nav aria-label="Page navigation" className="mt-4">
        <ul className="pagination justify-content-center">
          <li className={`page-item ${!pagination.has_prev_page ? 'disabled' : ''}`}>
            <button className="page-link d-flex align-items-center" onClick={() => handlePageChange(pagination.current_page - 1)} disabled={!pagination.has_prev_page}>
              <ChevronLeft size={16} className="me-1" />
              Previous
            </button>
          </li>
          <li className="page-item active">
            <span className="page-link">{pagination.current_page}</span>
          </li>
          <li className={`page-item ${!pagination.has_next_page ? 'disabled' : ''}`}>
            <button className="page-link d-flex align-items-center" onClick={() => handlePageChange(pagination.current_page + 1)} disabled={!pagination.has_next_page}>
              Next
              <ChevronRight size={16} className="ms-1" />
            </button>
          </li>
        </ul>
      </nav>

      {/* Invite Affiliate Modal */}
      <div className={`modal fade ${showInviteModal ? 'show' : ''}`} tabIndex={-1} role="dialog" style={{ display: showInviteModal ? 'block' : 'none' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Invite Affiliates</h5>
              <button type="button" className="btn-close" onClick={handleCloseModal} aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p>Share this link to invite affiliates:</p>
              <div className="input-group mb-3">
                <input type="text" className="form-control" value={inviteLink} readOnly />
                <button 
                  className={`btn ${copyButtonText === 'Copied!' ? 'btn-success' : 'btn-outline-secondary'}`} 
                  type="button" 
                  onClick={handleCopyLink}
                >
                  {copyButtonText === 'Copied!' ? (
                    <Check size={16} className="me-2" />
                  ) : (
                    <Copy size={16} className="me-2" />
                  )}
                  {copyButtonText}
                </button>
              </div>
              <p className="text-muted small mt-2">
                <Info size={14} className="me-1" />
                By default, invited affiliates are auto enrolled in the default program. You can move them to other programs later.
              </p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>Close</button>
              <button type="button" className="btn btn-primary" onClick={handleShareLink}>
                <Share2 size={16} className="me-2" />
                Share
              </button>
            </div>
          </div>
        </div>
      </div>
      {showInviteModal && <div className="modal-backdrop fade show"></div>}
    </div>
  );
};

export default AffiliateManagement;