import React, { useState } from 'react';
import { ChevronDown, LogOut, Menu, Home, BarChart2, Users, DollarSign, Share2, Link, BookOpen, Settings, HelpCircle } from 'lucide-react';
import { COLORS } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';

interface MenuItem {
  id: string;
  label: string;
  icon: React.ReactNode;
}

interface MenuGroup {
  title: string;
  items: MenuItem[];
}

interface DashboardLayoutProps {
  platformName: string;
  userEmail: string;
  menuGroups: MenuGroup[];
  selectedMenuItem: string;
  onLogout: () => void;
  children: React.ReactNode;
  disableSidebar?: boolean;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({
  platformName,
  userEmail,
  menuGroups,
  selectedMenuItem,
  onLogout,
  children,
  disableSidebar
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const navigate = useNavigate();

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const getInitial = (email: string) => email.charAt(0).toUpperCase();

  const getSelectedMenuItemTitle = () => {
    for (const group of menuGroups) {
      const selectedItem = group.items.find(item => item.id === selectedMenuItem);
      if (selectedItem) return selectedItem.label;
    }
    return '';
  };

  return (
    <div className="d-flex vh-100" style={{ backgroundColor: COLORS.background }}>
      {/* Sidebar */}
      <div 
        className={`d-flex flex-column flex-shrink-0 text-white ${isSidebarOpen ? 'd-md-block' : 'd-none d-md-block'}`} 
        style={{ width: '280px', backgroundColor: COLORS.primary }}
      >
        <div className="d-flex flex-column h-100">
          {/* Portal Name */}
          <a href="/" className="d-flex align-items-center text-white text-decoration-none px-3 py-4">
            <span className="fs-5 fw-semibold">{platformName}</span>
          </a>
          
          {/* Menu Groups */}
          <nav className="flex-grow-1 overflow-auto">
            {menuGroups.map((group, index) => (
              <div key={index} className="mb-3">
                <p className="fs-7 ms-3 mt-3 mb-2" style={{ color: COLORS.textLight2 }}>{group.title}</p>
                <ul className="nav flex-column mb-auto px-2">
                  {group.items.map((item) => (
                    <li className="nav-item my-1" key={item.id}>
                      <button
                        className={`nav-link text-white w-100 text-start rounded px-3 py-2 d-flex align-items-center ${selectedMenuItem === item.id ? 'fw-semibold' : ''}`}
                        style={{ 
                          backgroundColor: selectedMenuItem === item.id && !disableSidebar ? COLORS.primaryHover : 'transparent',
                          transition: 'background-color 0.2s',
                        }}
                        onClick={() => navigate(`/dashboard/${item.id}`) }
                        disabled={disableSidebar}
                      >
                        {item.icon}
                        <span className="ms-2">{item.label}</span>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </nav>

          {/* User Info and Logout Dropdown */}
          <div className="mt-auto border-top" style={{ borderColor: `${COLORS.primaryHover} !important` }}>
            <div className="dropdown px-3 py-3">
              <a href="#" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" onClick={toggleDropdown}>
                <div 
                  className="rounded-circle me-2 d-flex justify-content-center align-items-center"
                  style={{
                    width: '36px',
                    height: '36px',
                    backgroundColor: COLORS.primaryHover,
                    color: '#ffffff',
                    fontSize: '16px',
                    fontWeight: 'bold'
                  }}
                >
                  {getInitial(userEmail)}
                </div>
                <div className="d-flex flex-column me-2">
                  <strong className="fs-6">{userEmail.split('@')[0]}</strong>
                  <small style={{ color: 'rgba(255,255,255,0.7)' }}>{userEmail}</small>
                </div>
                <ChevronDown size={20} className="ms-auto" />
              </a>
              <ul className={`dropdown-menu dropdown-menu-dark text-small shadow${isDropdownOpen ? ' show' : ''}`} 
                  style={{ backgroundColor: COLORS.primaryHover, position: 'absolute', bottom: '100%', left: '20px' }}>
                <li><a className="dropdown-item" href="#" onClick={onLogout}>Sign out</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-grow-1 d-flex flex-column overflow-hidden">
        {/* Header */}
        {/* <header className="p-3 border-bottom" style={{ backgroundColor: COLORS.background }}>
          <div className="container-fluid d-flex justify-content-between align-items-center">
            <button 
              className="btn btn-link d-md-none p-0 text-decoration-none"
              type="button" 
              onClick={toggleSidebar}
            >
              <Menu size={24} color={COLORS.primary} />
            </button>
            <h1 className="h4 mb-0" style={{ color: COLORS.primary }}>{disableSidebar ? 'Get Started' : getSelectedMenuItemTitle()}</h1>
          </div>
        </header> */}

        {/* Page Content */}
        <main className="flex-grow-1 p-4 overflow-auto">
          {children}
        </main>
      </div>
    </div>
  );
};

export default DashboardLayout;