import React, { useState, useEffect } from 'react';
import { ChevronDown, Trash2, Mail, UserPlus, Users, DollarSign, MailIcon } from 'lucide-react';
import { COLORS } from '../../utils/constants';
import { App, CreateUpdateAppRequest } from '../../utils/service';

interface EmailDetailsProps {
  app: App;
  token: string | undefined;
  onUpdateApp: (updateRequest: CreateUpdateAppRequest, appId?: string) => Promise<void>;
  refreshApps: () => Promise<void>;
}

const EmailDetails: React.FC<EmailDetailsProps> = ({ app, token, onUpdateApp, refreshApps }) => {
  const [emailTypes, setEmailTypes] = useState([
    { id: 'new-affiliate-signup-brand', name: 'New Affiliate Signed Up (YOU)', description: 'We will send you an email when a new affiliate signs up', enabled: false, icon: UserPlus },
    { id: 'new-affiliate-signup-affiliate', name: 'New Affiliate Signed Up (AFFILIATE)', description: 'Send a welcome email when affiliates sign up', enabled: false, icon: Users },
    { id: 'first-referral-affiliate', name: 'Affiliate Gets First Referral (AFFILIATE)', description: 'Send an email when an affiliate receives its first referral', enabled: false, icon: Mail },
    { id: 'new-referral-affiliate', name: 'Affiliate Gets New Referral (AFFILIATE)', description: 'Send an email for every new referral', enabled: false, icon: Mail },
  ]);

  const [saveStatus, setSaveStatus] = useState<'initial' | 'changed' | 'saved'>('initial');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (app && app.email_preference) {
      setEmailTypes(prevTypes => 
        prevTypes.map(type => ({
          ...type,
          enabled: (app.email_preference || []).includes(type.id)
        }))
      );
    }
  }, [app]);

  const toggleEmailType = (id: string) => {
    setEmailTypes(prevTypes => 
      prevTypes.map(type => 
        type.id === id ? { ...type, enabled: !type.enabled } : type
      )
    );
    setSaveStatus('changed');
  };

  const handleSave = async () => {
    setIsLoading(true);
    const enabledEmailTypes = emailTypes
      .filter(type => type.enabled)
      .map(type => type.id);

    const updateRequest: CreateUpdateAppRequest = {
        app_name: app.app_name,
        currency: app.currency,
        email_preference: enabledEmailTypes
    };

    try {
      await onUpdateApp(updateRequest, app.app_id);
      await refreshApps();
      setSaveStatus('saved');
    } catch (error) {
      console.error('Failed to update email preferences:', error);
      // Handle error (e.g., show an error message to the user)
    } finally {
      setIsLoading(false);
    }
  };

  const renderSaveButton = () => {
    if (isLoading) {
      return (
        <button className="btn mt-4" disabled style={{ backgroundColor: COLORS.primary, color: 'white' }}>
          <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
          Saving...
        </button>
      );
    }

    switch (saveStatus) {
      case 'changed':
        return (
          <button onClick={handleSave} className="btn mt-4" style={{ backgroundColor: COLORS.primary, color: 'white' }}>
            Save
          </button>
        );
      case 'saved':
        return (
          <button className="btn mt-4" disabled style={{ backgroundColor: COLORS.primary, color: 'white', opacity: 0.5 }}>
            Saved
          </button>
        );
      default:
        return null;
    }
  };

  return (
    <div className="container-fluid p-4">
      <div className="row justify-content-center">
        <div className="col-lg-11">
          <div className="d-flex align-items-center mb-4">
            <MailIcon size={24} className="me-2" style={{ color: COLORS.primary }} />
            <h4 className="mb-0 fw-bold" style={{ color: COLORS.text }}>Integrations</h4>
          </div>
          <p className="text-muted mb-4">You can disable/enable email notifications for you and your affiliates</p>
          {emailTypes.map(type => (
            <div key={type.id} className="d-flex align-items-center mb-4">
              <div className="bg-light rounded-circle p-2 me-3">
                <type.icon size={24} style={{ color: COLORS.primary }} />
              </div>
              <div className="flex-grow-1">
                <h6 className="mb-0 fw-bold">{type.name}</h6>
                <small className="text-muted">{type.description}</small>
              </div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id={`flexSwitchCheck${type.id}`}
                  checked={type.enabled}
                  onChange={() => toggleEmailType(type.id)}
                  style={{ backgroundColor: type.enabled ? COLORS.primary : '', borderColor: COLORS.primary }}
                />
              </div>
            </div>
          ))}
          {renderSaveButton()}
        </div>
      </div>
    </div>
  );
};

export default EmailDetails;