export const getSubdomain = (): string | undefined => {
    const hostArr = window.location.hostname.split('.');
    if (hostArr.length === 0) {
        return undefined
    }
    const subdomainOpt = hostArr[0];
    if (subdomainOpt === 'localhost' || subdomainOpt === 'www' || subdomainOpt === 'new-kinda-ads' || subdomainOpt === 'shinara') { 
        return undefined
    }

    return subdomainOpt;
};
