import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { BASE_URL } from './constants';

// Interfaces
export interface CreateUpdateAppRequest {
  app_name: string;
  subdomain?: string;
  brand_image_bytestring?: string;
  brand_color?: string;
  brand_text_color?: string;
  update_api_key?: boolean;
  currency: string;
  tremendous_campaign_id?: string;
  tremendous_campaign_name?: string;
  sdk_step_completed?: boolean,
  affiliate_contact_email?: string;
  email_preference?: string[];
}

export interface CreateUpdateAppResponse {
  app_id: string;
}


export interface CreateUpdateCampaignRequest {
  campaign_name: string;
  campaign_description: string | undefined;
  campaign_tags?: string;
  commission_type: 'percentage' | 'fixed' | 'tiered';
  commission_value?: number;
  tiered_commissions?: {
    type: 'sales' | 'revenue';
    tiers: Array<{
      min: number;
      max: number | undefined;
      commission_type: 'percentage' | 'fixed';
      value: number;
    }>;
  };
  referral_bonus?: {
    threshold: number;
    amount: number;
    threshold_type: 'sales' | 'revenue';
  };
  payout_schedule: string;
  minimum_payout_threshold?: number | undefined;
  payout_delay_days: number;
  attribution_duration_days: number;
}

export interface CreateUpdateCampaignResponse {
  campaign_id: string;
}

export interface GenericBoolResponse {
  status: boolean;
}

export interface GenericNumberResponse {
  num: number;
}

export interface GenericIdResponse {
  id: string;
}

export interface ConversionSumByDateObj {
  conversion_date: string;
  conversion_sum: number;
  conversion_count: number;
}

export interface ConversionSumByTypeDateObj {
  conversion_date: string;
  conversion_type: string;
  conversion_sum: number;
}

export interface ConversionFunnel {
  user_count: number;
  trial_count: number;
  subscriber_count: number;
}

export interface UpdateAffiliateStatusRequest {
  affiliate_ids: string[];
  parent_campaign_id?: string;
  status: string;
}

export interface AddAffiliateRequest {
  name: string;
  email: string;
  parent_campaign_id: string,
  socials?: string[];
  code: string;
  platform: string;
}

export interface TopAffiliate {
  affiliate_user_id: string;
  affiliate_first_name: string;
  affiliate_last_name: string;
  user_count: number;
  referral_count: number;
  revenue: number;
}

export interface Affiliate {
  affiliate_user_id: string;
  first_name: string;
  last_name: string;
  email?: string;
  phone?: string;
  parent_campaign_id: string;
  code?: string,
  country?: string,
  birthdate?: string;
  gender?: string;
  device_type?: string;
  languages?: string;
  status?: string;
  currency?: string;
  created_at: string;
}

export interface AffiliateResponse {
  affiliates: Affiliate[];
  pagination: PaginationData;
}

export interface UpdateAffiliateCodeRequest {
  code: string;
  platform: string;
  prev_code?: string;
}

export interface ConversionUser {
  external_user_id: string;
  name?: string;
  email?: string;
  phone?: string;
  affiliate_user_id: string;
  affiliate_first_name?: string;
  affiliate_last_name?: string;
  created_at: string;
  subscription_value?: number;
  subscription_duration_type?: string;
  subscription_product?: string;
  subscription_status?: string;
  revenue: number;
}


export interface PaginationData {
  current_page: number;
  page_size: number;
  total_count: number;
  total_pages: number;
  has_next_page: boolean;
  has_prev_page: boolean;
}

export interface ReferralResponse {
  conversion_users: ConversionUser[];
  pagination: PaginationData;
}

export interface App {
  app_id: string;
  app_name: string;
  brand_url?: string;
  brand_color?: string;
  brand_text_color?: string;
  subdomain?: string;
  api_key?: string;
  revenu_cat_api_key?: string;
  currency: string;
  tremendous_campaign_id?: string;
  tremendous_campaign_name?: string;
  is_revenuecat_connected?: boolean;
  sdk_step_completed?: boolean,
  affiliate_contact_email?: string;
  currency_exchange_rate?: number;
  email_preference?: string[];
}

export interface SubdomainAppData {
  app_id: string;
  app_name: string;
  brand_url?: string;
  brand_color?: string;
  brand_text_color?: string;
  subdomain?: string;
  default_campaign_id?: string;
  affiliate_contact_email?: string;
}

export interface Campaign {
  campaign_id: string;
  app_id: string;
  campaign_name: string;
  campaign_description?: string;
  campaign_tags?: string;
  commission_type: string;
  commission_value: number;
  tiered_commissions?: {
    type: 'sales' | 'revenue';
    tiers: Array<{
      min: number;
      max: number | undefined;
      commission_type: 'percentage' | 'fixed';
      value: number;
    }>;
  };
  referral_bonus?: {
    threshold: number;
    amount: number;
    threshold_type: 'sales' | 'revenue';
  };
  payout_schedule: string;
  minimum_payout_threshold?: number | undefined;
  payout_delay_days: number;
  attribution_duration_days: number;
  parent_campaign_id: string;
  version: number;
  is_default: boolean;
  is_inactive: boolean;
}

export interface Asset {
  app_resource_id: string;
  app_id: string;
  user_id: string;
  file_name: string;
  file_url: string;
  file_description: string;
  file_content_type: string;
  created_at?: string;
}

export interface AssetResponse {
  assets: Asset[];
  pagination: PaginationData;
}

export interface TremendousCampaign {
  id: string;
  name: string;
}

export interface PendingPayout {
  affiliate_user_id: string;
  affiliate_first_name: string;
  affiliate_last_name: string;
  revenue: number;
  unpaid_amount: number;
  ready_to_pay_amount: number;
  affiliate_commission_ids: string[];
  is_processing: boolean;
  error_message: string;
}

export interface ProcessedPayout {
  affiliate_user_id: string;
  affiliate_first_name: string;
  affiliate_last_name: string;
  payout: number;
  payment_provider: string;
  payment_date: string;
}

export interface PayRequest {
  payment_method: string;
  affiliate_data: PayAffiliateData[];
}

export interface PayAffiliateData {
  affiliate_user_id: string;
  affiliate_commission_ids: string[];
}

export interface AffiliateAppData {
  affiliate_user_id: string;
  app_id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone?: string;
  parent_campaign_id?: string;
  code?: string;
  country?: string;
  birthdate?: string;
  gender?: string;
  device_type?: string;
  languages?: string;
  status?: string;
  created_at?: string;
  currency?: string;
  campaign_data?: Campaign;
  currency_exchange_rate?: number;
}

export interface AffiliatePayout {
  affiliate_payout_id: string;
  affiliate_user_id: string;
  app_id: string;
  amount: number;
  currency: string;
  payment_provider: string;
  provider_payment_id: string;
  created_at: string;
}

export interface AddAffiliateSocialsRequest {
  socials: AddAffiliateSocial[];
}

export interface AddAffiliateSocial {
  social_type: string;
  handler: string;
}

export interface AffiliateSocial {
  affiliate_social_id: string;
  affiliate_user_id: string;
  app_id: string;
  social_type: string;
  handler: string;
}

interface CustomHeaders {
  [key: string]: string;
}

class ApiService {
  private api: AxiosInstance;

  constructor() {
    this.api = axios.create({
      baseURL: BASE_URL,
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }
  
  private async request<T>(config: AxiosRequestConfig, token?: string): Promise<T> {
    const headers: CustomHeaders = { ...(config.headers as CustomHeaders) };
    
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
  
    const response: AxiosResponse<T> = await this.api({
      ...config,
      headers
    });
    
    return response.data;
  }

  // Apps
  async createApp(app: CreateUpdateAppRequest, token: string): Promise<CreateUpdateAppResponse> {
    return this.request<CreateUpdateAppResponse>({ method: 'post', url: '/apps', data: app }, token);
  }

  async getApps(token: string): Promise<App[]> {
    return this.request<App[]>({ method: 'get', url: '/apps' }, token);
  }

  async updateApp(appId: string, app: CreateUpdateAppRequest, token: string): Promise<CreateUpdateAppResponse> {
    return this.request<CreateUpdateAppResponse>({ method: 'put', url: `/apps/${appId}`, data: app }, token);
  }

  // Campaigns
  async createCampaign(appId: string, campaign: CreateUpdateCampaignRequest, token: string): Promise<CreateUpdateCampaignResponse> {
    return this.request<CreateUpdateCampaignResponse>({ method: 'post', url: `/apps/${appId}/campaign`, data: campaign }, token);
  }

  async getCampaigns(appId: string, token: string): Promise<Campaign[]> {
    return this.request<Campaign[]>({ method: 'get', url: `/apps/${appId}/campaign` }, token);
  }


  async getCampaign(appId: string, campaignId: string, token: string): Promise<Campaign> {
    return this.request<Campaign>({ method: 'get', url: `/apps/${appId}/campaign/${campaignId}` }, token);
  }

  async updateCampaign(appId: string, campaignId: string, campaign: CreateUpdateCampaignRequest, token: string): Promise<CreateUpdateCampaignResponse> {
    return this.request<CreateUpdateCampaignResponse>({ method: 'put', url: `/apps/${appId}/campaign/${campaignId}`, data: campaign }, token);
  }

  async updateCampaignDefault(appId: string, campaignId: string, token: string): Promise<GenericBoolResponse> {
    return this.request<GenericBoolResponse>({ method: 'put', url: `/apps/${appId}/campaign/${campaignId}/default` }, token);
  }

  async deleteCampaign(appId: string, campaignId: string, token: string): Promise<GenericBoolResponse> {
    return this.request<GenericBoolResponse>({ method: 'delete', url: `/apps/${appId}/campaign/${campaignId}` }, token);
  }

  // Affiliates
  async getAffiliates(appId: string, page: number, page_size: number, token: string, campaignId?: string): Promise<AffiliateResponse> {
    let url = `/apps/${appId}/affiliate?page=${page}&page_size=${page_size}`
    if (campaignId) {
      url = url + `&campaign_id=${campaignId}`
    }
    return this.request<AffiliateResponse>({ method: 'get', url }, token);
  }

  async getTopAffiliates(appId: string, token: string): Promise<TopAffiliate[]> {
    let url = `/apps/${appId}/affiliate/top`
    return this.request<TopAffiliate[]>({ method: 'get', url }, token);
  }

  async getAffiliateDetails(appId: string, affiliateUserId: string, token: string): Promise<Affiliate> {
    let url = `/apps/${appId}/affiliate/${affiliateUserId}`
    return this.request<Affiliate>({ method: 'get', url }, token);
  }

  async updateAffiliateStatuses(appId: string, updateRequest: UpdateAffiliateStatusRequest, token: string): Promise<GenericBoolResponse> {
    return this.request<GenericBoolResponse>({ method: 'post', url: `/apps/${appId}/affiliate/status`, data: updateRequest }, token);
  }

  async getAffiliateCounts(appId: string, affiliateUserId: string, countType: string, token: string): Promise<GenericNumberResponse> {
    return this.request<GenericNumberResponse>({ method: 'get', url: `/apps/${appId}/affiliate/${affiliateUserId}/counts?countType=${countType}` }, token);
  }

  async getReferrals(appId: string, page: number, page_size: number, token: string, campaignId?: string, affiliateId?: string): Promise<ReferralResponse> {
    let url = `/apps/${appId}/referrals?page=${page}&page_size=${page_size}`
    if (campaignId) {
      url = url + `&campaign_id=${campaignId}`
    }
    if (affiliateId) {
      url = url + `&affiliate_id=${affiliateId}`
    }
    return this.request<ReferralResponse>({ method: 'get', url }, token);
  }

  async getAppCounts(appId: string, countType: string, token: string): Promise<GenericNumberResponse> {
    return this.request<GenericNumberResponse>({ method: 'get', url: `/apps/${appId}/counts?countType=${countType}` }, token);
  }

  async getAppGraphs(appId: string, startDate: string, endDate: string, token: string): Promise<ConversionSumByDateObj[]> {
    let url = `/apps/${appId}/graphs?startDate=${startDate}&endDate=${endDate}`
    return this.request<ConversionSumByDateObj[]>({ method: 'get', url }, token);
  }

  async getCampaignCounts(appId: string, campaignId: string, countType: string, token: string): Promise<GenericNumberResponse> {
    return this.request<GenericNumberResponse>({ method: 'get', url: `/apps/${appId}/campaign/${campaignId}/counts?countType=${countType}` }, token);
  }

  async getCampaignGraphs(appId: string, campaignId: string, graphType: string, startDate: string, endDate: string, token: string, retentionDays?: number): Promise<ConversionSumByDateObj[]> {
    let url = `/apps/${appId}/campaign/${campaignId}/graphs?graphType=${graphType}&startDate=${startDate}&endDate=${endDate}`
    if (retentionDays) {
      url = url + `&retentionDays=${retentionDays}`
    }
    return this.request<ConversionSumByDateObj[]>({ method: 'get', url }, token);
  }

  async getCampaignGraphWithTypes(appId: string, campaignId: string, graphType: string, startDate: string, endDate: string, token: string): Promise<ConversionSumByTypeDateObj[]> {
    let url = `/apps/${appId}/campaign/${campaignId}/graphs?graphType=${graphType}&startDate=${startDate}&endDate=${endDate}`
    return this.request<ConversionSumByTypeDateObj[]>({ method: 'get', url }, token);
  }

  async getCampaignConversionFunnel(appId: string, campaignId: string, startDate: string, endDate: string, token: string): Promise<ConversionFunnel> {
    let url = `/apps/${appId}/campaign/${campaignId}/graphs?graphType=conversion_funnel&startDate=${startDate}&endDate=${endDate}`
    return this.request<ConversionFunnel>({ method: 'get', url }, token);
  }

  async uploadAsset(appId: string, file: File, description: string, token: string): Promise<GenericIdResponse> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('description', description);
  
    return this.request<GenericIdResponse>({
      method: 'post',
      url: `/apps/${appId}/assets`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }, token);
  }
  
  async getAssets(appId: string, page: number, pageSize: number, token: string): Promise<AssetResponse> {
    return this.request<AssetResponse>({
      method: 'get',
      url: `/apps/${appId}/assets?page=${page}&page_size=${pageSize}`
    }, token);
  }
  
  async downloadAsset(appId: string, assetId: string, token: string): Promise<Blob> {
    return this.request<Blob>({
      method: 'get',
      url: `/apps/${appId}/assets/${assetId}/download`,
      responseType: 'blob'
    }, token);
  }
  
  async deleteAsset(appId: string, assetId: string, token: string): Promise<GenericBoolResponse> {
    return this.request<GenericBoolResponse>({
      method: 'delete',
      url: `/apps/${appId}/assets/${assetId}`
    }, token);
  }

  async getPendingPayouts(appId: string, token: string): Promise<PendingPayout[]> {
    return this.request<PendingPayout[]>({
      method: 'get',
      url: `/apps/${appId}/payouts/pending`
    }, token);
  }

  async getProcessedPayouts(appId: string, token: string): Promise<ProcessedPayout[]> {
    return this.request<ProcessedPayout[]>({
      method: 'get',
      url: `/apps/${appId}/payouts/processed`
    }, token);
  }

  async connectTremendousFetchCampaigns(appId: string, code: string, token: string): Promise<TremendousCampaign[]> {
    return this.request<TremendousCampaign[]>({ method: 'get', url: `/apps/${appId}/tremendous/connect?code=${code}` }, token);
  }

  async processPayment(appId: string, payRequest: PayRequest, token: string): Promise<GenericBoolResponse> {
    return this.request<GenericBoolResponse>({ method: 'post', url: `/apps/${appId}/pay`, data: payRequest }, token);
  }

  // Affiliate endpoints
  async getAppForSubdomain(subdomain: string): Promise<SubdomainAppData> {
    return this.request<SubdomainAppData>({ method: 'get', url: `/affiliates/subdomain/${subdomain}` });
  }
  
  async addAffiliate(affiliateAppData: any, token: string): Promise<GenericBoolResponse> {
    return this.request<GenericBoolResponse>({ method: 'post', url: '/affiliates/onboard', data: affiliateAppData }, token);
  }

  async getAffiliateAppData(appId: string, token: string): Promise<AffiliateAppData> {
    return this.request<AffiliateAppData>({
      method: 'get',
      url: `/affiliates/apps/${appId}`,
    }, token);
  }

  async updateAffiliateAppData(appId: string, affiliateAppData: AffiliateAppData, token: string): Promise<GenericBoolResponse> {
    return this.request<GenericBoolResponse>({ method: 'put', url: `/affiliates/apps/${appId}`, data: affiliateAppData }, token);
  }


  async getAffiliateAppCounts(appId: string, countType: string, token: string): Promise<GenericNumberResponse> {
    return this.request<GenericNumberResponse>({
      method: 'get',
      url: `/affiliates/apps/${appId}/counts?countType=${countType}`,
    }, token);
  }

  async getAffiliateAppGraph(appId: string, startDate: string, endDate: string, token: string): Promise<ConversionSumByDateObj[]> {
    let url = `/affiliates/apps/${appId}/graphs?startDate=${startDate}&endDate=${endDate}`
    return this.request<ConversionSumByDateObj[]>({ method: 'get', url }, token);
  }

  async updateCode(appId: string, updateRequest: UpdateAffiliateCodeRequest, token: string): Promise<GenericBoolResponse> {
    return this.request<GenericBoolResponse>({ method: 'put', url: `/affiliates/apps/${appId}/code`, data: updateRequest }, token);
  }

  async getAffiliateCompletedPayouts(appId: string, token: string): Promise<AffiliatePayout[]> {
    let url = `/affiliates/apps/${appId}/payouts`
    return this.request<AffiliatePayout[]>({ method: 'get', url }, token);
  }

  async addAffiliateSocials(appId: string, addSocialsRequest: any, token: string): Promise<GenericBoolResponse> {
    return this.request<GenericBoolResponse>({ method: 'post', url: `/affiliates/apps/${appId}/socials`, data: addSocialsRequest }, token);
  }

  async getAffiliateSocials(appId: string, token: string): Promise<AffiliateSocial[]> {
    return this.request<AffiliateSocial[]>({
      method: 'get',
      url: `/affiliates/apps/${appId}/socials`,
    }, token);
  }

  async deleteAffiliateSocial(appId: string, socialId: string, token: string): Promise<GenericBoolResponse> {
    return this.request<GenericBoolResponse>({ method: 'delete', url: `/affiliates/apps/${appId}/socials/${socialId}` }, token);
  }
}

export default ApiService;
