export const COLORS = {
    primary: '#4a154b',
    primaryHover: '#611f69',
    background: '#f8f9fa',
    text: '#1d1c1d',
    textLight: '#616061',
    textLight2: 'rgba(255,255,255,0.6)',
    border: '#ddd',
};

export const PLATFORM_NAME = "Shinara.io"

// export const BASE_URL = "http://localhost:8080"
export const BASE_URL = "https://new-kinda-ads.ue.r.appspot.com"

// export const TREMENDOUS_BASE_URL = 'https://api.tremendous.com'; // for production
export const TREMENDOUS_BASE_URL = 'https://testflight.tremendous.com';

export const BASE_WEBURL = "https://new-kinda-ads-firebase.web.app"

export const currencies = [
    { code: 'USD', name: 'US Dollar' },
    { code: 'EUR', name: 'Euro' },
    { code: 'INR', name: 'Indian Rupee' },
    { code: 'GBP', name: 'British Pound' },
    { code: 'JPY', name: 'Japanese Yen' },
    { code: 'CAD', name: 'Canadian Dollar' },
    { code: 'AUD', name: 'Australian Dollar' },
    { code: 'CNY', name: 'Chinese Yuan' },
    { code: 'BRL', name: 'Brazilian Real' },
    { code: 'MXN', name: 'Mexican Peso' },
];

export const socialMediaOptions = ['TikTok', 'Instagram', 'YouTube', 'X', 'Snapchat'];
