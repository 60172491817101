import React, { useState, CSSProperties } from 'react';
import { Container, Form, Button, Card, Alert } from 'react-bootstrap';
import { COLORS } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const STYLES: Record<string, CSSProperties> = {
  loginContainer: {
    minHeight: '100vh',
    backgroundColor: COLORS.background,
  },
  loginCard: {
    width: '100%',
    maxWidth: '400px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    border: 'none',
    borderRadius: '8px',
  },
  loginButton: {
    backgroundColor: COLORS.primary,
    borderColor: COLORS.primary,
  },
  formControl: {
    borderColor: COLORS.primaryHover,
  },
};

const css = `
  .login-button:hover, .login-button:focus, .login-button:active {
    background-color: ${COLORS.primaryHover} !important;
    border-color: ${COLORS.primaryHover} !important;
  }
  
  .form-control:focus {
    border-color: ${COLORS.primary} !important;
    box-shadow: 0 0 0 0.2rem rgba(74, 21, 75, 0.25) !important;
  }
`;

function LoginPage() {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [showCodeInput, setShowCodeInput] = useState(false);
  const navigate = useNavigate();
  const { session, signIn, verifyOtp } = useAuth();

  React.useEffect(() => {
    if (session) {
        const intendedDestination = localStorage.getItem('intendedDestination') || '/dashboard/home';
        // Clear the stored destination
        localStorage.removeItem('intendedDestination'); 
        // Navigate to the intended destination
        navigate(intendedDestination);
    }
  }, [session, navigate]);

  const handleSendCode = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    const { error } = await signIn(email);

    if (error) {
      setMessage(error.message);
    } else {
      setMessage('Check your email for the login code!');
      setShowCodeInput(true);
    }

    setLoading(false);
  };

  const handleVerifyCode = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    const { error, data } = await verifyOtp(email, code);

    if (error) {
      setMessage(error.message);
    } else if (data?.user) {
      setMessage('Login successful!');
      navigate('/dashboard/home');
    }

    setLoading(false);
  };

  return (
    <>
      <style>{css}</style>
      <Container fluid className="d-flex align-items-center justify-content-center" style={STYLES.loginContainer}>
        <Card style={STYLES.loginCard}>
          <Card.Body className="p-4">
            <h2 className="text-center mb-4">Log in to Your Account</h2>
            {!showCodeInput ? (
              <Form onSubmit={handleSendCode}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="name@work-email.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    style={STYLES.formControl}
                  />
                </Form.Group>
                <div className="d-grid gap-2">
                  <Button className="login-button" style={STYLES.loginButton} type="submit" disabled={loading}>
                    {loading ? 'Sending...' : 'Send Login Code'}
                  </Button>
                </div>
              </Form>
            ) : (
              <Form onSubmit={handleVerifyCode}>
                <Form.Group className="mb-3" controlId="formBasicCode">
                  <Form.Label>Enter the code sent to your email</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    required
                    style={STYLES.formControl}
                  />
                </Form.Group>
                <div className="d-grid gap-2">
                  <Button className="login-button" style={STYLES.loginButton} type="submit" disabled={loading}>
                    {loading ? 'Verifying...' : 'Verify Code'}
                  </Button>
                </div>
              </Form>
            )}
            {message && <Alert variant={message.includes('successful') ? 'success' : 'info'} className="mt-3">{message}</Alert>}
          </Card.Body>
          <Card.Footer className="text-center text-muted">
            <small>{showCodeInput ? "Didn't receive the code? " : "We'll email you a code for password-free sign in."}</small>
            {showCodeInput && (
              <Button variant="link" onClick={() => setShowCodeInput(false)}>
                Resend Code
              </Button>
            )}
          </Card.Footer>
        </Card>
      </Container>
    </>
  );
}

export default LoginPage;