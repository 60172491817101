import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { TremendousCampaign } from '../../utils/service';

interface TremendousCampaignModalProps {
  show: boolean;
  onHide: () => void;
  campaigns: TremendousCampaign[];
  selectedCampaign: TremendousCampaign | null;
  onCampaignSelect: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onSave: () => void;
  error: string | null;
}

const TremendousCampaignModal: React.FC<TremendousCampaignModalProps> = ({
  show,
  onHide,
  campaigns,
  selectedCampaign,
  onCampaignSelect,
  onSave,
  error
}) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Select Tremendous Campaign</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <div className="alert alert-danger">{error}</div>}
        <div className="mb-3">
          <label htmlFor="campaignSelect" className="form-label">Select a Campaign:</label>
          <select 
            id="campaignSelect" 
            className="form-select" 
            onChange={onCampaignSelect}
            value={selectedCampaign?.id || ''}
          >
            <option value="">Choose a campaign...</option>
            {campaigns.map((campaign) => (
              <option key={campaign.id} value={campaign.id}>
                {campaign.name}
              </option>
            ))}
          </select>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={onSave} disabled={!selectedCampaign}>
          Save Selected Campaign
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TremendousCampaignModal;
