import React from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardLayout from '../components/DashboardLayout';
import NewEditApp from '../components/NewEditApp';
import { useAuth } from '../../contexts/AuthContext';
import { useAppData } from '../contexts/AppDataContext';
import Integrations from '../components/Integrations';
import AffiliateManagement from '../components/AffiliateManagement';
import CampaignManagement from '../components/CampaignManagement';
import HomeComponent from '../components/HomeComponent';
import ReferralView from '../components/ReferralView';
import { PLATFORM_NAME } from '../../utils/constants';
import AffiliateDetails from '../components/AffiliateDetails';
import AssetManagement from '../components/AssetManagement';
import { BarChart2, FileText, HelpCircle, Home, Link, MailIcon, Settings, Target, UserPlus, Users, Wallet } from 'lucide-react';
import AnalyticsView from '../components/AnalyticsView';
import PayoutManagement from '../components/PayoutManagement';
import EmailDetails from '../components/EmailDetails';
import InternalTesting from '../components/InternalTesting';

interface DashboardPageProps {
  selectedMenuItem: string;
  selectedSubMenuItem?: string;
}

const DashboardPage: React.FC<DashboardPageProps> = ({ selectedMenuItem, selectedSubMenuItem }) => {
  const navigate = useNavigate();
  const { user, session, signOut } = useAuth();
  const { 
    app, appRevenue, appAffiliates, appReferrals, appARR, 
    campaigns, isLoading, createUpdateApp, createCampaign, 
    refreshCampaigns, refreshApps,
    token 
  } = useAppData();

  const menuGroups = [
    {
      title: "Overview",
      items: [
        { id: 'home', label: 'Home', icon: <Home size={18} /> },
        { id: 'analytics', label: 'Analytics', icon: <BarChart2 size={18} /> },
      ]
    },
    {
      title: "Management",
      items: [
        { id: 'programs', label: 'Programs', icon: <Target size={18} /> },
        { id: 'affiliates', label: 'Affiliates', icon: <Users size={18} /> },
        { id: 'payouts', label: 'Payouts', icon: <Wallet size={18} /> },
        { id: 'referrals', label: 'Referrals', icon: <UserPlus size={18} /> },
        { id: 'assets', label: 'Assets', icon: <FileText size={18} /> },
      ]
    },
    {
      title: "Configuration",
      items: [
        { id: 'integrations', label: 'Integrations', icon: <Link size={18} /> },
        { id: 'email', label: 'Email', icon: <MailIcon size={18} /> },
        { id: 'settings', label: 'App Settings', icon: <Settings size={18} /> },
      ]
    },
    {
      title: "Support",
      items: [
        { id: 'help', label: 'Help', icon: <HelpCircle size={18} /> },
      ]
    }
  ];

  const handleLogout = async () => {
    await signOut();
    navigate('/login');
  };

  const renderContent = () => {
    if (isLoading) {
      return <div className="d-flex justify-content-center align-items-center h-100">Loading...</div>;
    }

    if (!app) {
      return <NewEditApp onSubmit={createUpdateApp} />;
    } else if (selectedMenuItem === 'home') {
      return <HomeComponent app={app} appRevenue={appRevenue} appAffiliates={appAffiliates} appReferrals={appReferrals} appARR={appARR} campaigns={campaigns} token={token} />;
    }  if (selectedMenuItem === 'analytics') {
      return <AnalyticsView app={app} token={token} campaigns={campaigns} />;
    } else if (selectedMenuItem === 'programs') {
      return <CampaignManagement campaigns={campaigns} onNewProgram={createCampaign} refreshCampaigns={refreshCampaigns} app={app} token={token} />;
    } else if (selectedMenuItem === 'affiliates') {
      if (!selectedSubMenuItem) {
        return <AffiliateManagement app={app} campaigns={campaigns} token={token} />;
      } else if (selectedSubMenuItem === 'details') {
        return <AffiliateDetails app={app} campaigns={campaigns} token={token} />;
      }
    } else if (selectedMenuItem === 'payouts') {
      return <PayoutManagement app={app} token={token} />;
    } else if (selectedMenuItem === 'referrals') {
      return <ReferralView campaigns={campaigns} app={app} token={token} />;
    } else if (selectedMenuItem === 'integrations') {
      return <Integrations onUpdateApp={createUpdateApp} refreshApps={refreshApps} app={app} token={token} />;
    } else if (selectedMenuItem === 'assets') {
      return <AssetManagement appId={app.app_id} token={token} />;
    } else if (selectedMenuItem === 'email') {
      return <EmailDetails onUpdateApp={createUpdateApp} refreshApps={refreshApps} app={app} token={token} />;
    } else if (selectedMenuItem === 'settings') {
      return <NewEditApp onSubmit={createUpdateApp} appInput={app} />;
    } else if (selectedMenuItem === 'postman') {
      return <InternalTesting appId={app.app_id} />;
    }

    return (
      <div>
        <p>Coming Soon</p>
      </div>
    );
  };
  
  return (
    <DashboardLayout
      platformName={PLATFORM_NAME}
      userEmail={session?.user.email || ""}
      menuGroups={menuGroups}
      selectedMenuItem={selectedMenuItem}
      onLogout={handleLogout}
      disableSidebar={!app}
    >
      {renderContent()}
    </DashboardLayout>
  );
}

export default DashboardPage;
