import React, { useState, useEffect } from 'react';
import { Download, Trash2, Upload, FileText, Image } from 'lucide-react';
import ApiService, { Asset } from '../../utils/service';
import { Modal } from 'react-bootstrap';
import { COLORS } from '../../utils/constants';

interface AssetManagementProps {
  appId: string;
  token: string | undefined;
}

const apiService = new ApiService();
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB

const AssetManagement: React.FC<AssetManagementProps> = ({ appId, token }) => {
  const [assets, setAssets] = useState<Asset[]>([]);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileDescription, setFileDescription] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchAssets();
  }, [appId, token, currentPage]);

  const fetchAssets = async () => {
    if (!token) return;
    try {
      const response = await apiService.getAssets(appId, currentPage, 12, token);
      setAssets(response.assets);
      setTotalPages(response.pagination.total_pages);
    } catch (error) {
      console.error("Error fetching assets:", error);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setError("File size exceeds 5 MB limit.");
        return;
      }
      if (file.type !== 'application/pdf' && !file.type.startsWith('image/')) {
        setError("Only PDF and image files are allowed.");
        return;
      }
      setSelectedFile(file);
      setError(null);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile || !token || !fileDescription.trim()) return;
    setIsUploading(true);
    try {
      await apiService.uploadAsset(appId, selectedFile, fileDescription, token);
      setShowUploadModal(false);
      setSelectedFile(null);
      setFileDescription('');
      fetchAssets();
    } catch (error) {
      console.error("Error uploading asset:", error);
      setError("Failed to upload asset. Please try again.");
    } finally {
      setIsUploading(false);
    }
  };

  const handleDelete = (assetId: string) => {
    setShowDeleteAlert(assetId);
  };

  const confirmDelete = async () => {
    if (!showDeleteAlert || !token) return;
    try {
      await apiService.deleteAsset(appId, showDeleteAlert, token);
      setShowDeleteAlert(null);
      fetchAssets();
    } catch (error) {
      console.error("Error deleting asset:", error);
    }
  };

  const handleDownload = async (assetId: string, fileName: string) => {
    if (!token) return;
    try {
      const blob = await apiService.downloadAsset(appId, assetId, token);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading asset:", error);
    }
  };

  const renderAssetCard = (asset: Asset) => (
    <div key={asset.app_resource_id} className="col-md-4 col-lg-3 mb-3">
      <div className="card h-100 shadow-sm" style={{ borderColor: COLORS.border, borderRadius: '10px' }}>
        <div className="card-body d-flex flex-column">
          <div className="d-flex align-items-center mb-2">
            {asset.file_content_type.startsWith('image/') ? (
              <Image size={24} />
            ) : (
              <FileText size={24} />
            )}
            <h6 className="card-title mb-0 ms-2" style={{ color: COLORS.text, fontWeight: 'bold', fontSize: '1rem' }}>{asset.file_name}</h6>
          </div>
          <p className="card-text small text-muted mb-3">{asset.file_description}</p>
          <div className="mt-auto d-flex justify-content-between">
            <button 
              className="btn btn-outline-primary btn-sm"
              onClick={() => handleDownload(asset.app_resource_id, asset.file_name)}
            >
              <Download size={16} />
            </button>
            <button 
              className="btn btn-outline-danger btn-sm"
              onClick={() => handleDelete(asset.app_resource_id)}
            >
              <Trash2 size={16} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div className="d-flex align-items-center">
          <FileText size={24} className="me-2" color={COLORS.primary} />
          <h4 style={{ color: COLORS.text, fontWeight: 'bold', margin: 0 }}>Assets</h4>
        </div>
        <button 
          className="btn btn-primary px-4 py-2" 
          onClick={() => setShowUploadModal(true)}
          style={{ backgroundColor: COLORS.primary, borderColor: COLORS.primary, fontWeight: 'bold' }}
        >
          <Upload size={18} className="me-2" />
          Upload
        </button>
      </div>
      
      {assets.length > 0 ? (
        <div className="row">
          {assets.map(renderAssetCard)}
        </div>
      ) : (
        <div className="text-center mt-5">
          <h6>No assets uploaded</h6>
          <p>Upload assets to share with affiliates</p>
        </div>
      )}

      {totalPages > 1 && (
        <nav className="mt-4">
          <ul className="pagination justify-content-center">
            {[...Array(totalPages)].map((_, index) => (
              <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                <button className="page-link" onClick={() => setCurrentPage(index + 1)}>
                  {index + 1}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      )}

      <Modal show={showUploadModal} onHide={() => setShowUploadModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Asset</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <div className="alert alert-danger">{error}</div>}
          <div className="mb-3">
            <label htmlFor="file" className="form-label">Select File (PDF or Image, max 5 MB)</label>
            <input type="file" className="form-control" id="file" onChange={handleFileChange} accept="application/pdf,image/*" />
          </div>
          <div className="mb-3">
            <label htmlFor="description" className="form-label">Description (required)</label>
            <textarea 
              className="form-control" 
              id="description" 
              rows={3} 
              value={fileDescription}
              onChange={(e) => setFileDescription(e.target.value)}
              required
            ></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => setShowUploadModal(false)}>Cancel</button>
          <button 
            className="btn btn-primary" 
            onClick={handleUpload} 
            disabled={!selectedFile || isUploading || !fileDescription.trim()}
          >
            {isUploading ? 'Uploading...' : 'Upload'}
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteAlert !== null} onHide={() => setShowDeleteAlert(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Asset</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this asset? This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => setShowDeleteAlert(null)}>Cancel</button>
          <button className="btn btn-danger" onClick={confirmDelete}>Delete</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AssetManagement;
