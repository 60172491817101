import React, { useState, useEffect } from 'react';
import { Wallet, DollarSign, AlertCircle } from 'lucide-react';
import ApiService, { AffiliateAppData, AffiliatePayout, SubdomainAppData } from '../../utils/service';
import { COLORS } from '../../utils/constants';
import { capitalizeFirstLetter, formatCurrency } from '../../utils/strings';

interface AffiliatePayoutViewProps {
  app: SubdomainAppData;
  affiliateAppData: AffiliateAppData;
  token: string | undefined;
  unpaidCommission: number;
}

const AffiliatePayoutView: React.FC<AffiliatePayoutViewProps> = ({ app, token, affiliateAppData, unpaidCommission }) => {
  const [payouts, setPayouts] = useState<AffiliatePayout[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPayouts = async () => {
      try {
        const apiService = new ApiService();
        const fetchedPayouts = await apiService.getAffiliateCompletedPayouts(app.app_id, token || "");
        setPayouts(fetchedPayouts);
      } catch (error) {
        console.error('Error fetching payouts:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPayouts();
  }, [app, token]);

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString();
  };

  const getPaymentNote = (payout: AffiliatePayout) => {
    return payout.payment_provider === 'tremendous'
      ? `Payment was sent to your email (Tremendous Txn: ${payout.provider_payment_id})`
      : 'Recorded as Cash Payment';
  };

  return (
    <div className="container mt-5">
      <div className="card mb-4">
        <div className="card-header text-white d-flex justify-content-between align-items-center" style={{ backgroundColor: app.brand_color || COLORS.primary }}>
          <h6 className="mb-0" style={{ color: app.brand_text_color || COLORS.text }}>
            <DollarSign className="me-2" />
            Payout Status
          </h6>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <h6><AlertCircle className="me-2" />Pending Payout</h6>
              <p className="lead">{formatCurrency(affiliateAppData, unpaidCommission)}</p>
            </div>
            <div className="col-md-6">
              <h6><Wallet className="me-2" />Payout Threshold</h6>
              <p className="lead">{formatCurrency(affiliateAppData, affiliateAppData.campaign_data?.minimum_payout_threshold || 0)}</p>
            </div>
          </div>
          <div className="mt-3">
            <div className="progress" style={{ height: '20px' }}>
              <div 
                className="progress-bar" 
                role="progressbar" 
                style={{ 
                  width: `${Math.min((unpaidCommission / (affiliateAppData.campaign_data?.minimum_payout_threshold || 0)) * 100, 100)}%`,
                  backgroundColor: app.brand_color || COLORS.primary 
                }} 
                aria-valuenow={Math.min((unpaidCommission / (affiliateAppData.campaign_data?.minimum_payout_threshold || 0)) * 100, 100)} 
                aria-valuemin={0} 
                aria-valuemax={100}
              ></div>
            </div>
            <p className="text-muted mt-2">
              {unpaidCommission >= (affiliateAppData.campaign_data?.minimum_payout_threshold || 0)
                ? "You've reached the payout threshold! Expect your payout soon." 
                : `${formatCurrency(affiliateAppData, (affiliateAppData.campaign_data?.minimum_payout_threshold || 0) - unpaidCommission)} more to reach the payout threshold.`}
            </p>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header text-white d-flex justify-content-between align-items-center" style={{ backgroundColor: app.brand_color || COLORS.primary }}>
          <h6 className="mb-0" style={{ color: app.brand_text_color || COLORS.text }}>
            <Wallet className="me-2" />
            Payout History
          </h6>
        </div>
        <div className="card-body">
          {loading ? (
            <div className="text-center my-5">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>Payment Date</th>
                    <th>Amount</th>
                    <th>Payment Provider</th>
                    <th>Note</th>
                  </tr>
                </thead>
                <tbody>
                  {payouts.map((payout) => (
                    <tr key={payout.affiliate_payout_id}>
                      <td>{formatDate(payout.created_at)}</td>
                      <td>{formatCurrency(affiliateAppData, payout.amount)}</td>
                      <td>{capitalizeFirstLetter(payout.payment_provider)}</td>
                      <td>{getPaymentNote(payout)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AffiliatePayoutView;