import React, { useState, useEffect } from 'react';
import { DollarSign, Users, ArrowRight, Star, Check, ChevronDown, ChevronUp, Trash2, Target } from 'lucide-react';
import ApiService, { App, Campaign, CreateUpdateCampaignRequest } from '../../utils/service';
import NewProgramComponent from './NewProgramComponent';
import { Modal } from 'react-bootstrap';
import { COLORS } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { formatCurrencyForApp } from '../../utils/strings';

interface CampaignListProps {
  campaigns: Campaign[];
  onNewProgram: (programData: CreateUpdateCampaignRequest) => void;
  refreshCampaigns: () => Promise<void>;
  app: App;
  token: string | undefined;
}

interface CampaignStats {
  revenue: number | undefined;
  affiliates: number | undefined;
}

const apiService = new ApiService();

const CampaignManagement: React.FC<CampaignListProps> = ({ campaigns, onNewProgram, refreshCampaigns, app, token }) => {
  const [showNewProgram, setShowNewProgram] = useState(false);
  const [campaignStats, setCampaignStats] = useState<{ [key: string]: CampaignStats }>({});
  const [expandedCampaign, setExpandedCampaign] = useState<string | null>(null);
  const [showDeleteAlert, setShowDeleteAlert] = useState<string | null>(null);
  const [showActiveOnly, setShowActiveOnly] = useState(true);
  const [deleteError, setDeleteError] = useState<string | null>(null);
  const navigate = useNavigate();

  const appId = app.app_id

  const sortedCampaigns = [...campaigns].sort((a, b) => {
    if (a.is_inactive === b.is_inactive) return 0;
    return a.is_inactive ? 1 : -1;
  });

  const displayedCampaigns = showActiveOnly 
    ? sortedCampaigns.filter(campaign => !campaign.is_inactive)
    : sortedCampaigns;

  useEffect(() => {
    const fetchCampaignStats = async () => {
      const newStats: { [key: string]: CampaignStats } = {};
      for (const campaign of campaigns) {
        newStats[campaign.campaign_id] = { revenue: undefined, affiliates: undefined };
        setCampaignStats(prevStats => ({ ...prevStats, ...newStats }));

        const revenueResponse = await apiService.getCampaignCounts(appId, campaign.campaign_id, 'revenue', token || "");
        newStats[campaign.campaign_id].revenue = revenueResponse.num;
        setCampaignStats(prevStats => ({ ...prevStats, ...newStats }));

        const affiliatesResponse = await apiService.getCampaignCounts(appId, campaign.campaign_id, 'affiliates', token || "");
        newStats[campaign.campaign_id].affiliates = affiliatesResponse.num;
        setCampaignStats(prevStats => ({ ...prevStats, ...newStats }));
      }
    };

    fetchCampaignStats();
  }, [campaigns, appId, token]);

  const handleCreateProgram = (programData: CreateUpdateCampaignRequest) => {
    onNewProgram(programData);
    setShowNewProgram(false);
  };

  const handleSetDefault = (campaignId: string) => {
    apiService.updateCampaignDefault(appId, campaignId, token || "").then(() => {
      refreshCampaigns();
    });
  };

  const toggleCampaignDetails = (campaignId: string) => {
    setExpandedCampaign(expandedCampaign === campaignId ? null : campaignId);
  };

  const handleDelete = async (campaignId: string) => {
    const campaign = campaigns.find(c => c.campaign_id === campaignId);
    if (!campaign) return;

    const affiliatesCount = campaignStats[campaignId]?.affiliates || 0;

    if (affiliatesCount > 0 || campaign.is_default) {
      let errorMessage = "";
      if (campaign.is_default) {
        errorMessage += "This is the default program. Please set another program as default before deleting this one.";
      }
      if (affiliatesCount > 0) {
        errorMessage += "This program has active affiliates. Please move them to another program before deleting. ";
      }
      setDeleteError(errorMessage);
      setShowDeleteAlert(campaignId);
      return;
    }

    setShowDeleteAlert(campaignId);
  };

  const confirmDelete = async (campaignId: string) => {
    try {
      await apiService.deleteCampaign(appId, campaignId, token || "");
      await refreshCampaigns();
      setShowDeleteAlert(null);
      setDeleteError(null);
    } catch (error) {
      console.error("Error deleting campaign:", error);
      setDeleteError("An error occurred while deleting the program. Please try again.");
    }
  };

  const LoadingSpinner = () => (
    <div className="spinner-border spinner-border-sm" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  );

  const renderCampaignDetails = (campaign: Campaign) => {
    return (
      <div className="mt-3 pt-3 border-top">
        <h6 className="mb-2">Program Details</h6>
        <div className="row">
          <div className="col-md-12 mb-2">
            <strong>Description:</strong>
            <div className="p-2 bg-light rounded">
              {campaign.campaign_description || 'N/A'}
            </div>
          </div>
          <div className="col-md-6 mb-2">
            <h6 className="mb-1">Commission Details</h6>
            <div><strong>Type:</strong> {campaign.commission_type.charAt(0).toUpperCase() + campaign.commission_type.slice(1)}</div>
            {campaign.commission_type !== 'tiered' && (
              <div><strong>Value:</strong> {campaign.commission_value}{campaign.commission_type === 'percentage' ? '%' : '$'}</div>
            )}
            {campaign.commission_type === 'tiered' && (
              <div>
                <strong>Tiers:</strong>
                <ul className="list-unstyled ms-3 mb-0">
                  {campaign.tiered_commissions?.tiers.map((tier, index) => (
                    <li key={index}>
                      {tier.min} - {tier.max !== undefined ? tier.max : '∞'} {campaign.tiered_commissions?.type}: 
                      {tier.value}{tier.commission_type === 'percentage' ? '%' : '$'}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="col-md-6 mb-2">
            <h6 className="mb-1">Payout Details</h6>
            <div><strong>Minimum Threshold:</strong> ${campaign.minimum_payout_threshold || 0}</div>
            <div><strong>Schedule:</strong> {campaign.payout_schedule.charAt(0).toUpperCase() + campaign.payout_schedule.slice(1)}</div>
            <div><strong>Delay:</strong> {campaign.payout_delay_days} days</div>
            <div><strong>Attribution Duration:</strong> {campaign.attribution_duration_days} days</div>
          </div>
          {campaign.referral_bonus && (
            <div className="col-md-6 mb-2">
              <strong>Referral Bonus:</strong> ${campaign.referral_bonus.amount} at {campaign.referral_bonus.threshold} {campaign.referral_bonus.threshold_type}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderCampaignCard = (campaign: Campaign) => (
    <div key={campaign.campaign_id} className="col-12 mb-3">
      <div className="card shadow-sm" style={{ borderColor: COLORS.border, borderRadius: '10px' }}>
        <div className="card-body p-3">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h5 className="card-title mb-0" style={{ color: COLORS.text, fontWeight: 'bold', fontSize: '1.25rem' }}>{campaign.campaign_name}</h5>
            <div className="d-flex align-items-center">
              {!campaign.is_inactive && campaign.is_default && (
                <div className="badge bg-primary d-flex align-items-center me-2" style={{ fontSize: '0.875rem', padding: '0.5rem 0.75rem' }}>
                  <Check size={16} className="me-1" />
                  Default
                </div>
              )}
              {!campaign.is_inactive && !campaign.is_default && (
                <button 
                  className="btn btn-outline-primary btn-sm d-flex align-items-center me-2"
                  onClick={() => handleSetDefault(campaign.campaign_id)}
                  style={{ fontSize: '0.875rem' }}
                >
                  <Star size={16} className="me-1" />
                  Set Default
                </button>
              )}
              <button
                className="btn btn-outline-secondary btn-sm d-flex align-items-center"
                onClick={() => toggleCampaignDetails(campaign.campaign_id)}
                style={{ fontSize: '0.875rem' }}
              >
                {expandedCampaign === campaign.campaign_id ? (
                  <>
                    <ChevronUp size={16} className="me-1" />
                    Hide Details
                  </>
                ) : (
                  <>
                    <ChevronDown size={16} className="me-1" />
                    View Details
                  </>
                )}
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 d-flex align-items-center mb-2 mb-md-0">
              <DollarSign size={28} className="me-3" color={COLORS.primary} />
              <div className="flex-grow-1">
                <small className="d-block mb-1" style={{ color: COLORS.textLight, fontSize: '0.875rem' }}>Total Revenue</small>
                <h3 className="mb-0" style={{ color: COLORS.text, fontWeight: 'bold' }}>
                  {campaignStats[campaign.campaign_id]?.revenue !== undefined 
                    ? formatCurrencyForApp(app, campaignStats[campaign.campaign_id].revenue || 0)
                    : <LoadingSpinner />}
                </h3>
              </div>
              <button 
                className="btn btn-outline-primary d-flex align-items-center" 
                style={{ color: COLORS.primary, borderColor: COLORS.primary, backgroundColor: 'white' }}
                onClick={() => navigate(`/dashboard/payouts?campaignId=${campaign.campaign_id}`)}
              >
                View Payouts
                <ArrowRight size={18} className="ms-2" />
              </button>
            </div>
            <div className="col-md-6 d-flex align-items-center">
              <Users size={28} className="me-3" color={COLORS.primary} />
              <div className="flex-grow-1">
                <small className="d-block mb-1" style={{ color: COLORS.textLight, fontSize: '0.875rem' }}>Total Affiliates</small>
                <h3 className="mb-0" style={{ color: COLORS.text, fontWeight: 'bold' }}>
                  {campaignStats[campaign.campaign_id]?.affiliates !== undefined 
                    ? campaignStats[campaign.campaign_id].affiliates
                    : <LoadingSpinner />}
                </h3>
              </div>
              <button 
                className="btn btn-outline-primary d-flex align-items-center" 
                style={{ color: COLORS.primary, borderColor: COLORS.primary, backgroundColor: 'white' }}
                onClick={() => navigate(`/dashboard/affiliates?campaignId=${campaign.campaign_id}`)}
              >
                View Affiliates
                <ArrowRight size={18} className="ms-2" />
              </button>
            </div>
          </div>
          {campaign.campaign_tags && (
            <div className="mt-2">
              <strong>Tags:</strong> {campaign.campaign_tags}
            </div>
          )}
          {expandedCampaign === campaign.campaign_id && renderCampaignDetails(campaign)}
          <div className="d-flex justify-content-end mt-2">
            {campaign.is_inactive ? (
              <div className="badge bg-secondary d-flex align-items-center" style={{ fontSize: '0.75rem', padding: '0.25rem 0.5rem' }}>
                Archived
              </div>
            ) : (
              <button 
                className="btn btn-outline-danger btn-sm d-flex align-items-center" 
                onClick={() => handleDelete(campaign.campaign_id)}
                style={{ fontSize: '0.75rem', padding: '0.25rem 0.5rem' }}
              >
                <Trash2 size={12} className="me-1" />
                Archive
              </button>
            )}
          </div>
        </div>
      </div>
      <Modal show={showDeleteAlert !== null} onHide={() => setShowDeleteAlert(null)}>
        <Modal.Header closeButton>
          <Modal.Title>{deleteError ? "Error" : "Archive Program"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {deleteError ? (
            <div className="alert alert-danger">{deleteError}</div>
          ) : (
            "Are you sure you want to archive this program? This action cannot be undone."
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => {
            setShowDeleteAlert(null);
            setDeleteError(null);
          }}>Cancel</button>
          {!deleteError && showDeleteAlert && (
            <button className="btn btn-danger" onClick={() => confirmDelete(showDeleteAlert)}>Archive</button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="d-flex align-items-center">
          <Target size={24} className="me-2" color={COLORS.primary} />
          <h4 style={{ color: COLORS.text, fontWeight: 'bold', }}>{campaigns.length} Programs</h4>
        </div>
        <div className="d-flex align-items-center">
          <div className="btn-group me-3" role="group" aria-label="Program filter">
            <button 
              type="button" 
              className={`btn ${showActiveOnly ? 'btn-primary' : 'btn-outline-primary'}`}
              onClick={() => setShowActiveOnly(true)}
            >
              Active Only
            </button>
            <button 
              type="button" 
              className={`btn ${!showActiveOnly ? 'btn-primary' : 'btn-outline-primary'}`}
              onClick={() => setShowActiveOnly(false)}
            >
              All Programs
            </button>
          </div>
          <button 
            className="btn btn-primary px-4 py-2" 
            onClick={() => setShowNewProgram(true)}
            style={{ backgroundColor: COLORS.primary, borderColor: COLORS.primary, fontWeight: 'bold' }}
          >
            New Program
          </button>
        </div>
      </div>
      
      <div className="row">
        {displayedCampaigns.map(renderCampaignCard)}
      </div>

      <Modal 
        show={showNewProgram} 
        onHide={() => setShowNewProgram(false)}
        size="xl"
        centered
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton style={{ border: 'none', paddingBottom: 0 }}>
          <Modal.Title style={{ color: COLORS.text, fontSize: '1.5rem', fontWeight: 'bold' }}>Create a New Program</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: '20px 40px 40px' }}>
          <NewProgramComponent
            onCreateProgram={handleCreateProgram}
            onCancel={() => setShowNewProgram(false)}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CampaignManagement;